import React, { useCallback, useContext } from 'react'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography, CssBaseline} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { AppContext } from '../../../../registration/Context';

export default function ProjectInfo() {

    const paperStyle = {
        padding: 15,
        height: '180vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const { formValues, handleChange, handleNext, variant, margin } = useContext(
        AppContext
      )
      const { dealTitle, netOperatingCost, netOperatingIncome, overallROI, prefferedEquity,
        commonEquity, profitSharing, architecturalDesign, businessPlan, financialModel, projectCompletion,
        personalEquity, hotMoney } = formValues

      // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
        Object.keys({ dealTitle, netOperatingCost, netOperatingIncome, overallROI, prefferedEquity,
            commonEquity, profitSharing, architecturalDesign, businessPlan, financialModel, projectCompletion,
            personalEquity, hotMoney }).some(
            (name) =>
            (formValues[name].required && !formValues[name].value) ||
            formValues[name].error
        ),
        [formValues, dealTitle, netOperatingCost, netOperatingIncome, overallROI, prefferedEquity,
            commonEquity, profitSharing, architecturalDesign, businessPlan, financialModel, projectCompletion,
            personalEquity, hotMoney]
    )
    return (
        <React.Fragment>
            <Grid>
                <CssBaseline />
                <Paper elevation={10} style={paperStyle}>
                    <Grid align ="center">
                        <Avatar style= {avatarStyle}><CreateOutlinedIcon /></Avatar>
                        <h4><Typography>Project Information</Typography></h4>
                    </Grid>
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Give Your Deal A Title"
                        name="dealTitle"
                        placeholder="Your Deal title"
                        value={dealTitle.value}
                        onChange={handleChange}
                        error={!!dealTitle.error}
                        helperText={dealTitle.error}
                        required={dealTitle.required}
                        
                    />
                    <TextField 
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Project Net Operating cost"
                        name="netOperatingCost"
                        placeholder="Amount in USD"
                        value={netOperatingCost.value}
                        onChange={handleChange}
                        error={!!netOperatingCost.error}
                        helperText={netOperatingCost.error}
                        required={netOperatingCost.required}
                    />
                    <TextField 
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Project net Operating Income"
                        name="netOperatingIncome"
                        placeholder="Amount in USD"
                        value={netOperatingIncome.value}
                        onChange={handleChange}
                        error={!!netOperatingIncome.error}
                        helperText={netOperatingIncome.error}
                        required={netOperatingIncome.required}
                    />
                    <TextField 
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Project Overall Return On Investment"
                        name="overallROI"
                        placeholder="Amount in USD"
                        value={overallROI.value}
                        onChange={handleChange}
                        error={!!overallROI.error}
                        helperText={overallROI.error}
                        required={overallROI.required}
                    />

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Preferred Equity Investor?"
                        name="prefferedEquity"
                        value={prefferedEquity.value}
                        onChange={handleChange}
                        error={!!prefferedEquity.error}
                        helperText={prefferedEquity.error}
                        required={prefferedEquity.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Common Equity Investor?"
                        name="commonEquity"
                        value={commonEquity.value}
                        onChange={handleChange}
                        error={!!commonEquity.error}
                        helperText={commonEquity.error}
                        required={commonEquity.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>
                    
                    <TextField 
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Profit Sharing Ratio"
                        name="profitSharing"
                        placeholder="Profit Sharing Ratio (Investor-Developer)"
                        value={profitSharing.value}
                        onChange={handleChange}
                        error={!!profitSharing.error}
                        helperText={profitSharing.error}
                        required={profitSharing.required}
                    />

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Architectural Design Ready??"
                        name="architecturalDesign"
                        value={architecturalDesign.value}
                        onChange={handleChange}
                        error={!!architecturalDesign.error}
                        helperText={architecturalDesign.error}
                        required={architecturalDesign.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Business Plan Ready?"
                        name="businessPlan"
                        value={businessPlan.value}
                        onChange={handleChange}
                        error={!!businessPlan.error}
                        helperText={businessPlan.error}
                        required={businessPlan.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Financial Model Ready?"
                        name="financialModel"
                        value={financialModel.value}
                        onChange={handleChange}
                        error={!!financialModel.error}
                        helperText={financialModel.error}
                        required={financialModel.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>

                    <TextField 
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Project Completion Timeline"
                        name="projectCompletion"
                        placeholder="E.g Two Years"
                        value={projectCompletion.value}
                        onChange={handleChange}
                        error={!!projectCompletion.error}
                        helperText={projectCompletion.error}
                        required={projectCompletion.required}
                    />

                    <TextField 
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Any Personal Equity"
                        name="personalEquity"
                        placeholder="Quote in Percentage"
                        value={personalEquity.value}
                        onChange={handleChange}
                        error={!!personalEquity.error}
                        helperText={personalEquity.error}
                        required={personalEquity.required}
                    />


                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Any Hot Money?"
                        name="hotMoney"
                        value={hotMoney.value}
                        onChange={handleChange}
                        error={!!hotMoney.error}
                        helperText={hotMoney.error}
                        required={hotMoney.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>


                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                        disabled={isError()}
                        color="primary"
                        onClick={!isError() ? handleNext : () => null}
                        >
                            Next
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
