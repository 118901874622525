import React, {useEffect, useState} from 'react'
import Header from '../components/Header'
import { Grid, List, ListItem,CircularProgress, ListItemAvatar, Avatar, ListItemButton, ListItemText, Typography, Paper, IconButton } from '@mui/material'
import useStyles from '../components/HeaderStyles'
import {useHistory, Link} from 'react-router-dom'
import db from '../components/firebaseConfig'
import { collection, getDocs,query, where} from 'firebase/firestore/lite';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import {doc, getDoc } from "firebase/firestore/lite";
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

export default function Feeds() {
    const classes = useStyles()

        const [isLoading, setIsLoading] = React.useState(true)
        const currentMember = JSON.parse(localStorage.getItem("currentMember"))
        const [feeds, setFeeds] = React.useState([]);
        const [done, setDone] = React.useState(false)
        const history = useHistory()

        const fetchFeeds= async()=>{
            setDone(true)
        const response = query(collection(db, 'Feeds'), where("FeedStatus", "==", "Open"));
        const data= await getDocs(response);
        data.docs.forEach(item=>{
            setFeeds((prevState)=>{
                return [...prevState, item.data()]
            })
        })

        setDone(false)
        setIsLoading(false)
        }

 useEffect(async ()=>{
            var firstName = ""
    if(currentMember){
        const docRef = doc(db, "Members", currentMember.user.uid);
      await getDoc(docRef).then(async(docs)=>{

          
          if(docs.exists){
              const accountStatus=  await docs.get("AccountStatus");
              firstName = await docs.get("firstName")

              if(!firstName){
                  
                localStorage.removeItem("currentMember");
                history.push("/")
                          
              }else{
                  if(accountStatus == "Pending"){
                      //Account has not been apprved
                      localStorage.removeItem("currentMember");
                        history.push("/")
                  }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                      //Account has not been suspended or terminated
                      localStorage.removeItem("currentMember");
                      history.push("/")
                  }else if(accountStatus== "Active On Payment Confirmation"){
                          //Account has not been suspended or terminated
                          localStorage.removeItem("currentMember");
                          history.push("/")
                  }else{
                      //Lets first check if its active on Trial
                      var creationDate = docs.get("CreatedAt");
                      var date = new Date(creationDate * 1000).getDate();
                      var today = new Date();
                      var diffInDays = (today.getDate()- date)
                      
                      if(diffInDays > 30 && accountStatus=="Active On Trial"){
                        localStorage.removeItem("currentMember");
                        history.push("/")
                      }else{
                        fetchFeeds();
                      }

                    }
                }
                
            
            }else{
                localStorage.removeItem("currentMember");
                        history.push("/")
                }
            }).catch(async(_)=>{
                localStorage.removeItem("currentMember");
                            history.push("/")
            })

                    
            
    }else{
        history.push("/")
    }
      
      
            
          },[])
          

          

          const paperStyle = {
            padding: 20,
            height: '15vh',
            width: 300,
            margin: "20px auto"
        }  

        const goToLink = (feedType, linkId, dealid)=>{
            if(feedType=="Quick Posts" || feedType=="Quick Post"){
                history.push("/members/quick-posts/"+linkId)
            }else if(feedType=="Deal Connects" || feedType=="Deal Connect"){
                history.push("/members/deal-connect/deals/"+dealid)
            }
        }

    return (
        <React.Fragment>
            <Header />

            <Grid item xs={12} className={classes.wrapper}>
                <Typography variant="h4" >Feeds</Typography>
                <Grid className={classes.feeds}>
                    {
                        done ? 
                    <Grid align="center">
                        <ReactLoading type={"spin"} color={"green"} height={200} width={200} />
                    </Grid>:
                        <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', }}>
                        {
                            feeds.length !=0?
                        feeds && feeds.map((feed, index)=>(
                            <ListItem key={index} >
                                <ListItemButton variant="inherit" component="button" onClick={()=>goToLink(feed.FeedType, feed.QuickPostId, feed.DealConnectID)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                        <img
                                                  src={feed.FeedCreatorPics}
                                                  alt="Profile Picture"
                                                  style={{ width: 50, borderRadius: "50%" }}
                                        /> 
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={feed.FeedCreatorName+" "+ feed.FeedTitle} secondary={feed.FeedType} />
                                </ListItemButton>
                            </ListItem>
                        ))
                            
                        
                        :
                        <Grid align="center">
                                <Paper elevation= {10} style={paperStyle}>
                                    <Typography variant="h6">{isLoading ? <i>Loading...<CircularProgress /></i>:<b>No Feeds available yet</b> }</Typography>
                                </Paper>
                         </Grid>
                    }
                    </List>
                    }
                </Grid>
            </Grid>
            


        </React.Fragment>
    )
}
