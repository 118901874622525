import React, { useCallback, useContext } from 'react'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography} from '@mui/material';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import {AppContext} from '../Context.tsx';
import CssBaseline from '@mui/material/CssBaseline'


export default function CompanyProfile(){

    
      

    const paperStyle = {
        padding: 20,
        height: '95vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const {
        formValues,
        handleChange,
        handleBack,
        handleNext,
        variant,
        margin
      } = useContext(AppContext)

      const { companyName, website, positionHeld,  representativeName, corporateEmail } = formValues
      

      // Check if all values are not empty and if there are some errors
    
    const isError = useCallback(
        () =>
        Object.keys({ companyName, website, positionHeld, representativeName, corporateEmail }).some(
            (name) =>
            (formValues[name].required && !formValues[name].value) ||
            formValues[name].error
        ),
        [formValues, companyName, website, positionHeld, representativeName, corporateEmail]
    )
  
    return (
        <React.Fragment>
            <Grid item xs={12}>
            <CssBaseline />

                <Paper elevation={10} style={paperStyle}>
                    <Grid align ="center">
                        <Avatar style= {avatarStyle}><BusinessOutlinedIcon /></Avatar>
                        <h4><Typography>Company's Information</Typography></h4>
                    </Grid>
                    <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Company's Name"
                    name="companyName"
                    placeholder="Your Company's name"
                    value={companyName.value}
                    onChange={handleChange}
                    error={!!companyName.error}
                    helperText={companyName.error}
                    required={companyName.required}
                     
                    />
                    
                    <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Company's Website (Optional)"
                    name="website"
                    placeholder="Your Company's Website"
                    value={website.value}
                    onChange={handleChange}
                    error={!!website.error}
                    helperText={website.error}
                    required={website.required}
                     
                    />
                    <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Your Position at this company"
                    name="positionHeld"
                    placeholder="Position Held"
                    value={positionHeld.value}
                    onChange={handleChange}
                    error={!!positionHeld.error}
                    required={positionHeld.required}
                     
                    />
                    
                    <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Your Secretary/Representative Name"
                    name="representativeName"
                    placeholder="Secretary or Representative Name"
                    value={representativeName.value}
                    onChange={handleChange}
                    error={!!representativeName.error}
                    helperText={representativeName.error}
                    required={representativeName.required}
                     
                    />

                    <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Corporate Email Address"
                    name="corporateEmail"
                    placeholder="Valid Corporate Email Address"
                    value={corporateEmail.value}
                    onChange={handleChange}
                    error={!!corporateEmail.error}
                    helperText={corporateEmail.error}
                    required={corporateEmail.required}
                     
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button onClick={handleBack} sx={{ mr: 1 }}>
                        Previous
                        </Button>
                        <Button
                        variant="contained"
                        disabled={isError()}
                        color="primary"
                        onClick={!isError() ? handleNext : () => null}
                        >
                        Next
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}


