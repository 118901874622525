import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Header from '../components/Header'
import PropTypes from 'prop-types';
import { Typography, Grid, Tabs, Tab, Box, TextField, Button, CircularProgress, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, Paper } from '@mui/material'
import useStyles from '../components/HeaderStyles'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import firebaseApp from '../../config/firebaseConfig'
import {getFirestore, getDoc, query, where, getDocs, addDoc, collection, doc, serverTimestamp, setDoc} from 'firebase/firestore'

const db = getFirestore(firebaseApp);

var firstName = ""
    var lastName = ""
    var profilePics = ""

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  
  

export default function Messages() {
    const [isLoading2, setIsLoading2] = React.useState(true)
    const classes = useStyles()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [openBox, setOpenBox] = React.useState(false)

    const [subjectsss, setSubjectsss] = React.useState("")
    const [messagesss, setMessagesss] = React.useState("")

    const showMessage = (title, inboxes)=>{
        setOpenBox(true)

        setSubjectsss(title)
        setMessagesss(inboxes)

    }

    const closeMessageDialog = () => {
        setOpenBox(false);
      };

    const [isLoading, setIsLoading] = React.useState(false)
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("")


    const currentUser = JSON.parse(localStorage.getItem("currentMember"))
        const [inbox, setInbox] = React.useState([]);
        const [sent, setSent] = React.useState("")
        const history = useHistory()

        const fetchInbox= async()=>{
            
            const response = query(collection(db, "MembersInbox"), where("MessageReceiver", "==", currentUser.user.uid));
            const data= await getDocs(response);
            data.docs.forEach(item=>{
            setInbox((prevState)=>{
                return [...prevState, item.data()]
            })
            })
        }

        const fetchSent= async()=>{
            query(collection(db, "MembersSentMessages"), where("MessageSenderID", "==", currentUser.user.uid));
            const response =collection(db, 'MembersSentMessages');
            const data= await getDocs(response);
            data.docs.forEach(item=>{
            setSent((prevState)=>{
                return [...prevState, item.data()]
            })
            })
        }

        
            useEffect(async ()=>{
                var firstName = ""
        
                    if(currentUser){
                        const docRef = doc(db, "Members", currentUser.user.uid);
          await getDoc(docRef).then(async(docs)=>{
    
              
              if(docs.exists){
                  const accountStatus=  await docs.get("AccountStatus");
                  firstName = await docs.get("firstName")
    
                  if(!firstName){
                      
                    localStorage.removeItem("currentMember");
                    history.push("/")
                              
                  }else{
                      if(accountStatus == "Pending"){
                          //Account has not been apprved
                          localStorage.removeItem("currentMember");
                            history.push("/")
                      }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                          //Account has not been suspended or terminated
                          localStorage.removeItem("currentMember");
                          history.push("/")
                      }else if(accountStatus== "Active On Payment Confirmation"){
                              //Account has not been suspended or terminated
                              localStorage.removeItem("currentMember");
                              history.push("/")
                      }else{
                          //Lets first check if its active on Trial
                          var creationDate = docs.get("CreatedAt");
                          var date = new Date(creationDate * 1000).getDate();
                          var today = new Date();
                          var diffInDays = (today.getDate()- date)
                          
                          if(diffInDays > 30 && accountStatus=="Active On Trial"){
                            localStorage.removeItem("currentMember");
                            history.push("/")
                          }else{
                            fetchInbox()
                            fetchSent();
                            
                          }
    
                        }
                    }
                    
                
                }else{
                    localStorage.removeItem("currentMember");
                            history.push("/")
                    }
                }).catch(async(_)=>{
                    localStorage.removeItem("currentMember");
                                history.push("/")
                })
    
                        
                    }else{
                        history.push("/")
                    }
          
                    setIsLoading2(false)
                
            },[])

    

    const paperStyle = {
        padding: 20,
        height: '15vh',
        width: 300,
        margin: "20px auto"
    }  

    const doSendMessage = async()=>{
        if(!subject){
            toast.error("Message Subject is required", {
                position: toast.POSITION.TOP_RIGHT
            })
        }else if(!message){
            toast.error("Message Content is required", {
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setIsLoading(true);
            
            const docRef = doc(db, "Members", currentUser.user.uid);
            await getDoc(docRef).then(async(docs)=>{
                firstName = await docs.get("firstName");
                    lastName = await docs.get("lastName");
                    profilePics = await docs.get("ProfilePicsUrl");

                    await addDoc(collection(db, "MembersSentMessages"), {
                        "MessageSubject": subject,
                        "MessageContent": message,
                        "MessageSender": currentUser.user.uid,
                        "MessageReceiver": "DevClub Admin",
                        "MessageCreatedAt": serverTimestamp()
                      }).then(async (msg)=>{
                        await setDoc(doc(db, "AdminInbox", msg.id), {
                            "MessageSubject": subject,
                            "MessageContent": message,
                            "MessageSenderID": currentUser.user.uid,
                            "MessageReceiver": "DevClub Admin",
                            "MessageCreatedAt": serverTimestamp()
                          }).then((_)=>{
                              setIsLoading(false);
                              setSubject("");
                              setMessage("");
                              toast.success("Your Message has been successfully sent", {
                                position: toast.POSITION.TOP_RIGHT
                              })
                          })
                      })
            })
        }
    }

    return (
        <React.Fragment>
            <Header />

            <Grid className={classes.wrapper}>
                <Typography variant="h4" style={{marginBottom: "20px"}}>Messages</Typography>
                <Dialog open={openBox} onClose={closeMessageDialog}>
                        <DialogTitle>{subjectsss}</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            {messagesss}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={closeMessageDialog}>Close</Button>
                        
                        </DialogActions>
                    </Dialog>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="Message Tabs">
                        <Tab label="New Message" {...a11yProps(0)} />
                        <Tab label="Inbox" {...a11yProps(1)} />
                        <Tab label="Sent Messages" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <TextField 
                            id="outlined-basic" 
                            label="Message Subject" 
                            variant="outlined" 
                            fullWidth
                            value={subject}
                            onChange = {(e)=>setSubject(e.target.value)}
                            style={{marginBottom: "15px"}}
                            
                        />

                        <TextField
                                    id="outlined-multiline-static"
                                    label="Your Message"
                                    multiline 
                                    fullWidth
                                    value={message}
                                    onChange = {(e)=>setMessage(e.target.value)}
                                    rows={5}
                                    style={{marginBottom: "15px"}}
                                    
                        />
                        {
                            isLoading ? <CircularProgress align="center" />:
                        <Button variant="contained" onClick={()=>doSendMessage()} color="success" fullWidth startIcon={<SendIcon />}>Send Message</Button>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                            <Grid className={classes.feeds}>
                            <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', }}>
                                {
                                    inbox.length !=0?
                                inbox && inbox.map((inbox, index)=>(
                                    <ListItem button key={index} onClick={()=>showMessage(inbox.MessageSubject, inbox.MessageContent)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <MailIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={inbox.MessageSubject} secondary="Sent by Devclub360 Admin" />
                                    </ListItem>
                                ))
                                    
                                
                                :
                                <Grid align="center">
                                        <Paper elevation= {10} style={paperStyle}>
                                            <Typography variant="h6">{isLoading2 ?<CircularProgress />: <b>No Inbox available yet</b>}</Typography>
                                        </Paper>
                                </Grid>
                            }
                            </List>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <Grid className={classes.feeds}>
                            <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', }}>
                                {
                                    sent.length !=0?
                                sent && sent.map((sent, index)=>(
                                    <ListItem button key={index} onClick={()=>showMessage(sent.MessageSubject, sent.MessageContent)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LocalPostOfficeIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={sent.MessageSubject} secondary="received by Devclub360 Admin" />
                                    </ListItem>
                                ))
                                    
                                
                                :
                                <Grid align="center">
                                        <Paper elevation= {10} style={paperStyle}>
                                            <Typography variant="h6">{isLoading2 ?<CircularProgress />: <b>No Sent Messages available yet</b>}</Typography>
                                        </Paper>
                                </Grid>
                            }
                            </List>
                        </Grid>
                    </TabPanel>
                </Box>
               <ToastContainer />
            </Grid>
            
        </React.Fragment>
    )
}
