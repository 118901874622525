import React from 'react'
import {Grid, Paper, Typography} from "@mui/material"
import image from '../assets/images/about.jpg'

export default function AboutUs() {

    const paperStyle = {
        padding: 15,
        height: '180vh',
        width: 490,
        margin: "25px auto"
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Paper elevation={10} style={paperStyle}>
                    <Grid style={{marginBottom: "20px"}} align="center">
                        <img src={image} width="450px" height= "300px" alt="" />
                        <Typography variant="h4" style={{marginTop: "15px"}}>About Devclub360</Typography>
                    </Grid>
                    <Typography variant="inherit" align="left" paragraph={true}>
                    <p>DevClub360 is a digital platform that connects Top Business Leaders in Real estate and across 100 different sectors. </p>⁣⁣
⁣⁣                  <p>DevClub360 is about Relationships, Real Investors, Real Connections, Real Partnerships, Joint Ventures, Collaborations for:⁣⁣
                        ⁣⁣</p>
                        <ul>
                        <li>Real Estate Development ⁣</li>
                        <li>Aviation ⁣⁣</li>
                        <li>Energy⁣⁣ </li>
                        <li>Oil & Gas⁣⁣ </li>
                        <li>International Trades⁣⁣ </li>
                        <li>Mergers & Acquisitions⁣⁣</li>
                        <li>Project Financing⁣⁣</li>
                        <li>Government-backed Funding opportunities</li> ⁣⁣
                        <li>Capital Raise & Investment⁣⁣</li>
                        <li>FMCG & Retail chain⁣⁣</li>
                        <li>Contracts⁣⁣</li>
                        <li>Fintech⁣⁣</li>
                        <li>Agribusiness⁣⁣</li>
                        <li>Mining⁣⁣</li>
                        <li>Manufacturing & Construction⁣⁣</li>
                        <li>Power⁣⁣</li>
                        <li>Maritime/Shipping, and over 100 sectors.</li>⁣⁣⁣

                        </ul>
                        ⁣⁣
                       <p> We’re here to build a premium network for wealth creation and preservation.</p>
                    </Typography>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
