import React, {useState, useContext} from 'react';
import {UserContext} from '.././App'
import {Avatar, Grid, Paper, TextField, Button, Typography} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {Link, useHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import firebaseApp from '../config/firebaseConfig'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";


const auth = getAuth(firebaseApp);

const SignUp = () => {
    const paperStyle = {
        padding: 20,
        height: '80vh',
        width: 400,
        margin: "20px auto"
    }

    

    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [resetpassword, setResetPassword] = useState("")
    const history = useHistory();
    const [done, setDone] = useState(undefined)
    const {state, dispatch} = useContext(UserContext)
    const postData = async ()=>{

        if(!email){
            toast.error("Email Address is required", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)){
            toast.error("Invalid Email Address !", {
                position: toast.POSITION.TOP_RIGHT
              });

        }else if(!password){
            toast.error("Password is required", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(!resetpassword){
            toast.error("Please confirm your password", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(password !== resetpassword){
            toast.error("Paasword do not match", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else{
            setDone(true)

            await createUserWithEmailAndPassword(auth, email, password)
            .then( async(userData) => {
                await sendEmailVerification(auth.currentUser)
    
                  setDone(undefined)
                  toast.success("Account Successfully created", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        
                        localStorage.setItem("current", JSON.stringify(userData))
                        dispatch({type: "SIGNUP", payload: userData})
                            history.push("/members/signup/success")
                
            }).catch((error) => {
                setDone(undefined)
                toast.error(error.code, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        
            });
    }
}
    return (
        <React.Fragment>
            <Grid item xs={12}>
              { done? <Grid align="center">
                    <ReactLoading type={"spin"} color={"green"} height={200} width={200} />
                </Grid>:
                <Paper elevation = {10} style= {paperStyle}>
                    <Grid align ="center">
                    <Avatar style= {avatarStyle}><CreateOutlinedIcon /></Avatar>
                    <h2>Create New Account</h2>
                    </Grid>
                    <TextField 
                        id="email" 
                        value={email} 
                        onChange={(e)=>setEmail(e.target.value)} 
                        label="Email" 
                        type="email" 
                        required 
                        placeholder = "Enter valid email" 
                        variant="standard" 
                        fullWidth 
                    />
                    <TextField 
                        id="password"  
                        label="Password" 
                        value={password} 
                        onChange={(e)=>setPassword(e.target.value)} 
                        required 
                        placeholder = "Enter Password" 
                        type="password" 
                        variant="standard" 
                        fullWidth 
                    />

                    <TextField 
                        id="password2"  
                        label="Reset Password" 
                        value={resetpassword} 
                        onChange={(e)=>setResetPassword(e.target.value)} 
                        required 
                        placeholder = "Confirm Password" 
                        type="password" 
                        variant="standard" 
                        fullWidth 
                    />
                    
                    <Button 
                        style={{paddingTop: "7px", marginTop:"10px"}} 
                        color= "primary" 
                        onClick = {()=>postData()}
                        fullWidth 
                        variant="contained">
                            Create New Account
                    </Button>
                    
                    <Typography style={{paddingTop: "20px"}}>
                        Do you have an account? 
                        <Link to="/members/login"> 
                            Sign in
                        </Link>
                    </Typography>

                    <Typography style={{paddingTop: "30px"}}>
                        By Creating an account, you agree with our &nbsp;
                        <Link to="/terms-and-conditions"> 
                        Terms and Conditions
                        </Link>
                    </Typography>
                </Paper>
                }
            </Grid>
           
            <ToastContainer />
        </React.Fragment>
    )
}

export default SignUp
