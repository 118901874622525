import React, {useEffect} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';//Transaction
import AddCircleIcon from '@mui/icons-material/AddCircle'; //created deals
import BorderColorIcon from '@mui/icons-material/BorderColor'; // Interested deals
import RssFeedIcon from '@mui/icons-material/RssFeed'; // Feeds
import MailOutlineIcon from '@mui/icons-material/MailOutline'; //Messages
import PostAddIcon from '@mui/icons-material/PostAdd'; // Quick post
import CardMembershipIcon from '@mui/icons-material/CardMembership'; // Membership license
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CategoryIcon from '@mui/icons-material/Category'; //Category
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useStyles from './HeaderStyles';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import db from './firebaseConfig';
import {getDocs, collection, query, where,} from 'firebase/firestore/lite'

export default function SideNav() {

    const [feeds, setFeeds] = React.useState([])
    const [connect, setConnect] = React.useState([])
    const [share, setShare] = React.useState([])
    const [inbox, setInbox] = React.useState([])
    const [notification, setNotification] = React.useState([])
    const [transaction, setTransaction] = React.useState([])
    const currentMember = JSON.parse(localStorage.getItem("currentMember"))
    const history = useHistory()

    const fetchFeeds= async()=>{
        const response =collection(db, 'Feeds');
        const data= await getDocs(response);
        data.docs.forEach(item=>{
        setFeeds((prevState)=>{
            return [...prevState, item.data()]
        })
        })
    }

    const fetchConnect= async()=>{
        const response =query(collection(db, 'DealConnects'), where("DealStatus", "==", "Open"));
        const data= await getDocs(response);
        data.docs.forEach(item=>{
        setConnect((prevState)=>{
            return [...prevState, item.data()]
        })
        })
    }

    const fetchShares= async()=>{
        const response =collection(db, 'DealShares');
        const data= await getDocs(response);
        data.docs.forEach(item=>{
        setShare((prevState)=>{
            return [...prevState, item.data()]
        })
        })
    }

    const fetchInbox= async()=>{
            
        const response = query(collection(db, "MembersInbox"), where("MessageReceiver", "==", currentMember.user.uid));
        const data= await getDocs(response);
        data.docs.forEach(item=>{
        setInbox((prevState)=>{
            return [...prevState, item.data()]
        })
        })
    }

    const fetchAnnouncement= async()=>{
        const response =collection(db, 'Announcements');
        const data= await getDocs(response);
        data.docs.forEach(item=>{
        setNotification((prevState)=>{
            return [...prevState, item.data()]
        })
        })
    }
    //MembersTransactions
    const fetchTransaction= async()=>{
        const response =collection(db, 'MembersTransactions');
        const data= await getDocs(response);
        data.docs.forEach(item=>{
        setTransaction((prevState)=>{
            return [...prevState, item.data()]
        })
        })
    }

    useEffect(async () => {
        if(currentMember){
            fetchFeeds()
            fetchConnect()
            fetchShares()
            fetchInbox()
            fetchAnnouncement()
            fetchTransaction()
        }else{
            history.push("/")
        }
    }, [])
const classes = useStyles()
    const listItemData = [
        
        {
            label: "Feeds", 
            link:"/members/feeds",
            icon:  
            <Stack spacing={2} direction="row">
                <Badge badgeContent={feeds.length} color="secondary">
                    <RssFeedIcon />
                </Badge>
            </Stack>  
        },
        {
            label: "Notifications",
            link:"/members/notifications", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={notification.length} color="secondary">
                <NotificationsActiveIcon />
                </Badge>
            </Stack>  
            
            
        },
        {
            label: "Deal Connect", 
            link:"/members/deal-connect", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={connect.length} color="secondary">
                    <AddBusinessIcon /> 
                </Badge>
            </Stack>  
            
            
        },
        {
            label: "Deal Share", 
            link:"/members/deal-share", 
            icon: 

            <Stack spacing={2} direction="row">
                <Badge badgeContent={share.length} color="secondary">
                    <BusinessIcon /> 
                </Badge>
            </Stack>  
            
             
        },
        {
            label: "Transactions", 
            link:"/members/transactions", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={transaction.length} color="secondary">
                    <AccountBalanceWalletIcon /> 
                </Badge>
            </Stack> 
             
        },
        {label: "Created Deals", link:"/members/created-deals", icon: <AddCircleIcon /> },
        {label: "Interested Deals", link:"/members/interested-deals", icon: <BorderColorIcon /> },
        {label: "Membership License", link:"/members/license", icon: <CardMembershipIcon /> },
        {label: "Membership Category", link:"/members/category", icon: <CategoryIcon /> },
        {
            label: "Messages", 
            link:"/members/messages", 
            icon: 
            <Stack spacing={2} direction="row">
                <Badge badgeContent={inbox.length} color="secondary">
                    <MailOutlineIcon /> 
                </Badge>
            </Stack> 
            
             
        },
        {label: "Quick Posts", link:"/members/quick-posts", icon: <PostAddIcon /> },
        {label: "Logout", link:"/members/account/logout", icon: <ExitToAppIcon /> },
        
    ]
    return (
        <React.Fragment>
            <Grid>
                <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        
                </Typography>
            </Grid>
            <List>
                {
                    listItemData.map((item, index)=>(
                        <ListItem component={NavLink} to={item.link} className={classes.navlink} activeClassName={classes.activeNavlink} key= {index}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText>{item.label}</ListItemText>
                        </ListItem>
                    ))
                }
                
            </List>
        </React.Fragment>
    )
}
