import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {Grid, Paper, Avatar, Typography, Divider, TextField, CircularProgress} from '@mui/material'
import {Container, Row, Col, Carousel} from 'react-bootstrap'
import {useHistory, useParams, Link} from 'react-router-dom'
import db from "../../firebaseConfig";
import {doc, getDoc, collection, setDoc, addDoc, serverTimestamp} from 'firebase/firestore/lite'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import Header from "../../Header";
import useStyles from "../../HeaderStyles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function ViewDetails() {

const [isLoading, setIsLoading] = React.useState(true)
const [isSubmitting, setIsSubmitting] = React.useState(false)
const currentMember = JSON.parse(localStorage.getItem("currentMember"))
const history = useHistory()
const [done, setDone] = React.useState(false)
const {id} = useParams()
const [dealTitle, setDealTitle]= React.useState("") 
const [dealCreatorPics, setDealCreatorPics]= React.useState("")
const [AnyHotMoney, setAnyHotMoney]= React.useState("") 
const [AnyPersonalEquity, setAnyPersonalEquity]= React.useState("") 
const [ArchitecturalDesignReady, setArchitecturalDesignReady]= React.useState("")
const [BusinessPlanReady, setBusinessPlanReady]= React.useState("") 
const [CommonEquityInvestor, setCommonEquityInvestor]= React.useState("") 
const [DealCreatedBy, setDealCreatedBy] = React.useState("") 
const [DealCreatorName, setDealCreatorName] = React.useState("")
const [DealCreatorOffers, setDealCreatorOffers] = React.useState([])
const [FinancialModelReady, setFinancialModelReady] = React.useState("")
const [JointPartnersOffer, setJointPartnersOffer] = React.useState("") 
const [NetOperatingCost, setNetOperatingCost] = React.useState("")
const [NetOperatingIncome, setNetOperatingIncome]= React.useState("")
const [NumberOfInvestors, setNumberOfInvestors] = React.useState("") 
const [OverAllROI, setOverAllROI]= React.useState("")
const [PrefferedEquityInvestor, setPrefferedEquityInvestor] = React.useState("")
const [ProfitSharingRatio, setProfitSharingRatio] = React.useState("")
const [ProjectCompletionTimeLine, setProjectCompletionTimeLine] = React.useState("")
const [TypeOfJointVenture, setTypeOfJointVentures] = React.useState("")
//images

const [image1, setImage1] = React.useState("") 
const [image2, setImage2] = React.useState("") 
const [image3, setImage3] = React.useState("")

//offers

//Land

const [landSize, setLandSize] = React.useState("") 
const [landLocation, setLandLocation] = React.useState("") 
const [landPriceWorth, setLandPriceWorth] = React.useState("") 
const [titleOfDocument, setTitleOfDocument] = React.useState("")
const [structureOnLand, setStructureOnLand] = React.useState("") 
const [landFreeFromReclamation, setLandFreeFromReclamation] = React.useState("") 
const [buildingPlan, setBuldingPlan]= React.useState("") 

//Funding

const [amountOfInvestment, setAmountOfInvestment] = React.useState("")

//Investors
const [amountOfInvestmentByInvestor, setAmountOfInvestmentByInvestor] = React.useState("")
const [expectedROI, setExpectedROI] = React.useState("")

//Off-Takers

const [guranteedOffTakers, setGuranteedOffTakers] = React.useState("")
useEffect(async ()=>{

    var firstName = ""
if(currentMember){
const docRef = doc(db, "Members", currentMember.user.uid);
await getDoc(docRef).then(async(docs)=>{

  
  if(docs.exists()){
      const accountStatus=  await docs.get("AccountStatus");
      firstName = await docs.get("firstName")

      if(!firstName){
          
        localStorage.removeItem("currentMember");
        history.push("/")
                  
      }else{
          if(accountStatus == "Pending"){
              //Account has not been apprved
              localStorage.removeItem("currentMember");
                history.push("/")
          }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
              //Account has not been suspended or terminated
              localStorage.removeItem("currentMember");
              history.push("/")
          }else if(accountStatus== "Active On Payment Confirmation"){
                  //Account has not been suspended or terminated
                  localStorage.removeItem("currentMember");
                  history.push("/")
          }else{
              //Lets first check if its active on Trial
              var creationDate = docs.get("CreatedAt");
              var date = new Date(creationDate * 1000).getDate();
              var today = new Date();
              var diffInDays = (today.getDate()- date)
              
              if(diffInDays > 30 && accountStatus=="Active On Trial"){
                localStorage.removeItem("currentMember");
                history.push("/")
              }else{
                //fetch all necessary things for deal application
                setDone(true)
                const dealRef = doc(db, "DealConnects", id);
                await getDoc(dealRef).then(async(deals)=>{
                   if(deals.exists()){
                        setDealTitle(await deals.get("DealTitle"))
                        setDealCreatorPics(await deals.get("DealCreatorPics"))
                        setAnyHotMoney(await deals.get("AnyHotMoney"))
                        setAnyPersonalEquity(await deals.get("AnyPersonalEquity"))
                        setDealCreatedBy(await deals.get("DealCreatedBy"))
                        setDealCreatorName(await deals.get("DealCreatorName"))
                        setArchitecturalDesignReady(await deals.get("ArchitecturalDesignReady"))
                        setBusinessPlanReady(await deals.get("BusinessPlanReady"))
                        setCommonEquityInvestor(await deals.get("CommonEquityInvestor"))
                        setDealCreatorOffers(await deals.get("DealCreatorOffers"))
                        setFinancialModelReady(await deals.get("FinancialModelReady"))
                        setJointPartnersOffer(await deals.get("JointPartnersOffer"))
                        setNetOperatingCost(await deals.get("NetOperatingCost"))
                        setNetOperatingIncome(await deals.get("NetOperatingIncome"))
                        setNumberOfInvestors(await deals.get("NumberOfInvestors"))
                        setOverAllROI(await deals.get("OverAllROI"))
                        setPrefferedEquityInvestor(await deals.get("PrefferedEquityInvestor"))
                        setProfitSharingRatio(await deals.get("ProfitSharingRatio"))
                        setProjectCompletionTimeLine(await deals.get("ProjectCompletionTimeLine"))
                        setTypeOfJointVentures(await deals.get("TypeOfJointVenture"))
                        setImage1(await deals.get("ProjectRelatedImages1"))
                        setImage2(await deals.get("ProjectRelatedImages2"))
                        setImage3(await deals.get("ProjectRelatedImages3"))

                         
                            //Land only

                            setLandSize(await deals.get("LandSize")) 
                            setLandLocation(await deals.get("LandLocation"))
                            setLandPriceWorth(await deals.get("LandPriceWorth"))
                            setTitleOfDocument(await deals.get("TitleOfDocument"))
                            setStructureOnLand(await deals.get("StructureOnLand"))
                            setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                            setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                            
                            
                          
                            //Land and funding
                            setLandSize(await deals.get("LandSize")) 
                            setLandLocation(await deals.get("LandLocation"))
                            setLandPriceWorth(await deals.get("LandPriceWorth"))
                            setTitleOfDocument(await deals.get("TitleOfDocument"))
                            setStructureOnLand(await deals.get("StructureOnLand"))
                            setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                            setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                            setAmountOfInvestment(await deals.get("AmountOfInvestment"))
                
                            
                         
                            //Land and investors
                            setLandSize(await deals.get("LandSize")) 
                            setLandLocation(await deals.get("LandLocation"))
                            setLandPriceWorth(await deals.get("LandPriceWorth"))
                            setTitleOfDocument(await deals.get("TitleOfDocument"))
                            setStructureOnLand(await deals.get("StructureOnLand"))
                            setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                            setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                            setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                            setExpectedROI(await deals.get("InvestmentExpectedROI"))
                
                            
                          
                            //Land and offtakers
                            setLandSize(await deals.get("LandSize")) 
                            setLandLocation(await deals.get("LandLocation"))
                            setLandPriceWorth(await deals.get("LandPriceWorth"))
                            setTitleOfDocument(await deals.get("TitleOfDocument"))
                            setStructureOnLand(await deals.get("StructureOnLand"))
                            setLandFreeFromReclamation(await deals.get("FreeFromReclamation")) 
                            setBuldingPlan(await deals.get("TypeOfStructurePlan"))
                            setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))
                
                            
                          
                            //only funding
                            setAmountOfInvestment(await deals.get("AmountOfInvestment"))
                            
                         
                           //funding and investors
                           setAmountOfInvestment(await deals.get("AmountOfInvestment"))
                           setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                           setExpectedROI(await deals.get("InvestmentExpectedROI"))
                            
                         
                            //funding and off takers
                            setAmountOfInvestment(await deals.get("AmountOfInvestment"))
                            setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))
                            
                         
                            //Only investors
                            setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                            setExpectedROI(await deals.get("InvestmentExpectedROI"))
                            
                          
                            //Investors and offtakers
                            setAmountOfInvestmentByInvestor(await deals.get("AmountOfInvestmentByInvestor"))
                            setExpectedROI(await deals.get("InvestmentExpectedROI"))
                            setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))
                
                          
                          
                           //Only offtakers
                           setGuranteedOffTakers(await deals.get("GuaranteedOffTakers"))
                  }
                        
                   setDone(false)
                   setIsLoading(false)

                }).catch((error)=>{
                    //alert(error.message)
                    setDone(false)
                    localStorage.removeItem("currentMember")
                    history.push("/")
                })
              }

            }
        }
        
    
    }else{
        localStorage.removeItem("currentMember");
                history.push("/")
        }
    }).catch(async(error)=>{
        localStorage.removeItem("currentMember");
                    history.push("/")
        
    })
 
}else{
history.push("/")
}


    
  },[])


    
const classes = useStyles()

const paperStyle = {
  padding: 20,
  height: '300vh',
  width: 600,
  margin: "20px auto",
  
}

const [open, setOpen] = React.useState(false);
const [offerOpen, setOfferOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleOfferClose = () => {
    setOfferOpen(false);
  };
  

  
  const applyForDeal = async() =>{
    if(currentMember){
        await getDoc(doc(db, "DealConnects", id, "Applications", currentMember.user.uid)).then(async(docs)=>{
            if(docs.exists()){
                toast.info("Your Application for this project has already been submitted",{
                  position: toast.POSITION.TOP_RIGHT
                })
            }else{

              setOfferOpen(true)
              
            }
        }).catch((error)=>{

          toast.error("Couldn't fetch Applications...Please try again Later",{
            position: toast.POSITION.TOP_RIGHT
          })
        })
    }else{
      history.push("/")
    }
  }    

  const [offer, setOffer] = React.useState("")
  const submitOffer = async() =>{
     if(!offer){
       toast.error("Please indicate an offer", {
         position: toast.POSITION.TOP_RIGHT
       })
     }else{
      setIsSubmitting(true)
      await setDoc(doc(db,"DealConnects", id, "Applications", currentMember.user.uid), {
        DealConnectId: id,
        ApplicatorID: currentMember.user.uid,
        ProjectOffer: offer,
        JoinedAt: serverTimestamp(),
        ApplicationStatus: "Pending"
      }).then(async (docs)=>{
          
           await addDoc(collection(db, "InterestedDeals"), {
            "DealId": id,
            "DealType": "Deal Connect",
            "InterestedMemberId": currentMember.user.uid,
            "InterestDate": serverTimestamp()
          }).then((_)=>{
            
            fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                  method: "post",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    name: "Devcluber",
                    email: currentMember.user.email,
                    subject: "Your Deal Connect Interest has been received",
                    message: "<p>The support team received your interest for the following post<br />"+dealTitle+"<br /></p><p>We will contact you for more details.</p><p>If you need an urgent response, kindly use your web form in the contact admin section or send a message from your InMessage.</p><p>Thank You<br />DEVCLUB360 TEAM.</p>",
                    adminEmail: "operations@devclub360.com",
                    adminSubject: "A Deal Connect Interest has been received("+dealTitle+")",
                    adminMessage: "<p>An Interest has been shown to a deal connect project:"+dealTitle+ "<br />Do well to login to your admin dashboard and commence the process</p>"
                  })
                }).then(res=>res.json())
                .then(data=>{
                  console.log(data)
                })
            setOffer("")
            setIsSubmitting(false)
            handleOfferClose()

            
            toast.success("Your Interest has been successfully submitted",{
              position: toast.POSITION.TOP_RIGHT
            })
          })
      }).catch((error)=>{
          toast.error("Couldn't submit Applications...Please try again Later",{
            position: toast.POSITION.TOP_RIGHT
          })
        })
     }
  }

  return (
    <React.Fragment>
      <Header />
      <Grid item xs={12} className={classes.wrapper}>
      <Typography variant="h4" >{dealTitle}</Typography>
          {
            done?  <Grid align="center">
            <ReactLoading type={"spin"} color={"green"} height={100} width={100} />
        </Grid>
            :
            isLoading ? <CircularProgress /> :
            <Grid>

              {/* Offer Dialog Box */}

              <Dialog open={offerOpen} onClose={handleClose}>
                  <DialogTitle>Your Project Offer</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    To subscribe to this deal, please enter what you have to offer for the project. We
                      will notify you as soon as the project is about to begin.
                    </DialogContentText>

                    <TextField
                      autoFocus
                      margin="dense"
                      id="offer"
                      label="Your Project offer"
                      fullWidth
                      value={offer}
                      onChange={(e)=>setOffer(e.target.value)}
                      variant="standard"
                    />
                    
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleOfferClose}>Cancel</Button>
                  {
                    isSubmitting ? <CircularProgress />:
                    <Button onClick={()=>submitOffer()}>Submit Interest</Button>
                  }
                  
                  </DialogActions>
                </Dialog>

                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Project Related Images</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Project Related Images
                    </DialogContentText>
                    <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={image1}
                        alt=""
                        height="100%"
                        width="100%"
                      />

                      <Carousel.Caption>
                      <h3>First Related Image</h3>
                      <p>First Image on the List.</p>
                    </Carousel.Caption>
                      
                    </Carousel.Item>

                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={image2}
                        alt=""
                        height="100%"
                        width="100%"
                      />

                  <Carousel.Caption>
                    <h3>Second Related Image</h3>
                    <p>Second Image on the List.</p>
                  </Carousel.Caption>
                      
                    </Carousel.Item>

                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={image3}
                        alt=""
                        height="100%"
                        width="100%"
                      />

                  <Carousel.Caption>
                    <h3>Third Related Image</h3>
                    <p>Last Image on the List.</p>
                  </Carousel.Caption>
                      
                    </Carousel.Item>
                    
                </Carousel>
                  </DialogContent>
                  <DialogActions>
                    
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog>
                <Paper elevation={10} style={paperStyle}> 
                  
                    <Container>
                        <Row align="center" style={{marginBottom: "24px"}}>
                            <Col>
                                <Avatar src={dealCreatorPics} alt ="profile" sx={{ width: 120, height: 120 }} />
                                   
                            </Col>
                           <Link style={{textDecoration: "none"}} to={"/members/profile/details/"+DealCreatedBy}> <Button variant="text"><Typography variant="inherit">Tap View Creator's Profile</Typography></Button></Link>
                        </Row>
                        <Typography variant="h6" align="left">Project Information</Typography>
                        <Divider />
                        <Grid align="center"> 
                          <Button variant="text" onClick={()=>handleClickOpen()}><Typography variant="inherit">Tap to view project related images</Typography></Button>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Deal Title</Typography></Col>
                                        <Col><Typography variant="inherit">{dealTitle}</Typography></Col>
                                      </Row>
                                      
                                    </TableCell>
                                   
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Name of Creator</Typography></Col>
                                        <Col><Typography variant="inherit">{DealCreatorName}</Typography></Col>
                                      </Row>
                                      
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Any Hot Money</Typography></Col>
                                        <Col><Typography variant="inherit">{AnyHotMoney}</Typography></Col>
                                      </Row>
                                      
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Personal Equity</Typography></Col>
                                        <Col><Typography variant="inherit">{AnyPersonalEquity}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Architectural Design Ready</Typography></Col>
                                        <Col><Typography variant="inherit">{ArchitecturalDesignReady}</Typography></Col>
                                      </Row>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Business Plan Ready</Typography></Col>
                                        <Col><Typography variant="inherit">{BusinessPlanReady}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Common Equity Investor</Typography></Col>
                                        <Col><Typography variant="inherit">{CommonEquityInvestor}</Typography></Col>
                                      </Row>
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Financial Model Ready</Typography></Col>
                                        <Col><Typography variant="inherit">{FinancialModelReady}</Typography></Col>
                                      </Row>

                                    
                                    </TableCell>
                                   
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">What this creator requires from prospective partners/investors?</Typography></Col>
                                        <Col> <Typography variant="inherit">{JointPartnersOffer}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                          <Col><Typography variant="inherit">The type(s) of Investor/Partners this creator is interested in?</Typography></Col>
                                          <Col><Typography variant="inherit">{TypeOfJointVenture}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                        <Row>
                                          <Col><Typography variant="inherit">Net Operating Cost</Typography></Col>
                                          <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(NetOperatingCost))}</Typography></Col>
                                        </Row>
                                    
                                    </TableCell>
                                  
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Net Operating Income</Typography></Col>
                                        <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(NetOperatingIncome))}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>
                                  
                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Number Of Investors required</Typography></Col>
                                        <Col><Typography variant="inherit">{NumberOfInvestors}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Over All ROI</Typography></Col>
                                        <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(OverAllROI))}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                  
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Preffered Equity Investor</Typography></Col>
                                        <Col><Typography variant="inherit">{PrefferedEquityInvestor}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Profit Sharing Ratio</Typography></Col>
                                        <Col><Typography variant="inherit">{ProfitSharingRatio}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>

                                  <TableRow>
                                    <TableCell>
                                      <Row>
                                        <Col><Typography variant="inherit">Project Completion TimeLine</Typography></Col>
                                        <Col><Typography variant="inherit">{ProjectCompletionTimeLine}</Typography></Col>
                                      </Row>
                                    
                                    </TableCell>
                                    
                                  </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                      <Row style={{marginTop: "19px"}} align="center">
                        <Col><Typography variant="h6" align="left">Project Offers</Typography></Col>
                      </Row>
                      <Divider />
                    </Container>
                    <TableContainer component="Paper" style={{marginTop: "19px"}}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Row>
                                <Col><Typography>Project Creator Offers</Typography></Col>
                                <Col>{
                                DealCreatorOffers.map(item=>{
                                  return <Typography>{item}</Typography>
                                })
                              }</Col>
                              </Row>
                              
                            </TableCell>
                           
                          </TableRow>
                          {
                            DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ? <Grid>
                              <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land From Reclamation?</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            </Grid>
                            
                            
                            : DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers") 
                            ? 
                            <TableBody>
                                <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land Free from Reclamation</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                            <TableCell><Typography>Amount of Investment</Typography></TableCell>
                              <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                            </TableRow>
                            </TableBody>
                            :  DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ? 
                            <TableBody>
                              <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land Free from Reclamation</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                              <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                              <TableCell><Typography>{expectedROI}</Typography></TableCell>

                            </TableRow>

                            </TableBody> 
                            : DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")
                            ?
                            <Grid>
                              <TableRow>
                                <TableCell><Typography>Land Size</Typography></TableCell>
                                <TableCell><Typography>{landSize}</Typography></TableCell>

                            </TableRow> 
                            <TableRow>
                              <TableCell><Typography>Land Location</Typography></TableCell>
                              <TableCell><Typography>{landLocation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Land Price Worth</Typography></TableCell>
                              <TableCell><Typography>{landPriceWorth}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Title of Document</Typography></TableCell>
                              <TableCell><Typography>{titleOfDocument}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Structure on Land</Typography></TableCell>
                              <TableCell><Typography>{structureOnLand}</Typography></TableCell>

                            </TableRow>

                            <TableRow>
                              <TableCell><Typography>Land Free from Reclamation</Typography></TableCell>
                              <TableCell><Typography>{landFreeFromReclamation}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Type Of Structure wanted on Land</Typography></TableCell>
                              <TableCell><Typography>{buildingPlan}</Typography></TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                              <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                            </TableRow>
                            </Grid>
                            : 
                            !DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            
                            ? <Grid>
                              <TableRow>
                              <TableCell><Typography>Amount of Investment</Typography></TableCell>
                                <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                              </TableRow>
                            </Grid>
                            :
                            !DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ? 
                            <Grid>
                                 <TableRow>
                                  <TableCell><Typography>Amount of Investment</Typography></TableCell>
                                  <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                              </TableRow>

                              <TableRow>
                              <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                              <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                              <TableCell><Typography>{expectedROI}</Typography></TableCell>

                            </TableRow>
                            </Grid> 
                            :
                            !DealCreatorOffers.toString().includes("Land") && DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")

                            ?
                            <Grid>
                               <TableRow>
                                  <TableCell><Typography>Amount of Investment</Typography></TableCell>
                                  <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestment))}</Typography></TableCell>
                              </TableRow>
                              <TableRow>
                              <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                              <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                            </TableRow>
                            </Grid>

                            :
                            !DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && !DealCreatorOffers.toString().includes("Off-Takers")
                            ?
                             <Grid>
                                <TableRow>
                                <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                                <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                                <TableCell><Typography>{expectedROI}</Typography></TableCell>

                              </TableRow>
                            </Grid> 
                            :
                            !DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")
                            ? 
                            <Grid>
                                <TableRow>
                                <TableCell><Typography variant="inherit">Amount of Investment by Investor</Typography></TableCell>
                                <TableCell><Typography>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amountOfInvestmentByInvestor))}</Typography></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell><Typography>Expected return On Investment</Typography></TableCell>
                                <TableCell><Typography>{expectedROI}</Typography></TableCell>

                              </TableRow>

                              <TableRow>
                              <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                              <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                            </TableRow>
                            </Grid>:
                            !DealCreatorOffers.toString().includes("Land") && !DealCreatorOffers.toString().includes("Funding") && !DealCreatorOffers.toString().includes("Investors") && DealCreatorOffers.toString().includes("Off-Takers")
                            
                            ?
                            <Grid>
                                <TableRow>
                                  <TableCell><Typography variant="inherit">Guranteed Off-Takers?</Typography></TableCell>
                                  <TableCell><Typography>{guranteedOffTakers}</Typography></TableCell>
                                </TableRow>
                            </Grid>: <Typography variant="h6">No Offer found</Typography>
                          }
                        </TableBody> 
                      </Table>
                    </TableContainer>
                    <Grid>
                      {
                        DealCreatedBy === currentMember.user.uid ? <Typography variant="inherit" align="center" style={{color: "red"}}><i>You cannot apply for the deal you created</i></Typography>
                        : 
                        <Button onClick={()=>applyForDeal()} variant="contained" color="primary" fullWidth>Am Interested in this deal!</Button>
                      }
                      
                    </Grid>

                    <ToastContainer />
                </Paper>
            </Grid>
          }
        </Grid>
    </React.Fragment>
  );
}
