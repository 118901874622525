import React, {useContext} from 'react';
import {Avatar, Grid, Paper, TextField, Button, Typography} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Link, useHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import {UserContext} from '.././App'
import firebaseApp from '../config/firebaseConfig'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {getFirestore, doc, getDoc } from "firebase/firestore";


const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp)

const Login = () => {
    const paperStyle = {
        padding: 20,
        height: '60vh',
        width: 300,
        margin: "20px auto"
    }

    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }

    const [done, setDone] = React.useState(undefined);
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")

    const {state, dispatch} = useContext(UserContext)

          const history = useHistory();

    const postDetails = ()=>{

        if(!email){
            toast.error("Email Address is required !", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)){
            toast.error("Invalid Email Address !", {
                position: toast.POSITION.TOP_RIGHT
              });

        }else if(!password){
            toast.error("Password is required !", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else{
            setDone(true)
                var firstName = ""
                signInWithEmailAndPassword(auth, email, password).then(async (userCredential)=>{
                    if(userCredential.user.emailVerified){
                        //since email is verified, check account status
                        
                        const docRef = doc(db, "Members", userCredential.user.uid);
                        await getDoc(docRef).then(async(docs)=>{
        
                            
                            if(docs.exists){
                                const accountStatus=  docs.get("AccountStatus");
                                firstName = docs.get("firstName")
        
                                if(!firstName){
                                    setDone(undefined)
                                         localStorage.setItem("current", JSON.stringify(userCredential))
                
                                        dispatch({type: "USER", payload: userCredential})
                                            history.push("/members/create-profile")
                                }else{
                                    if(accountStatus === "Pending"){
                                        //Account has not been apprved
                                        setDone(undefined)
                                            toast.info("Your Account is Awaiting Approval", {
                                                position: toast.POSITION.TOP_RIGHT,
                                                autoClose: 10000
                                            });
                                    }else if(accountStatus=== "Suspended" || accountStatus === "Terminated"){
                                        //Account has not been suspended or terminated
                                       
                                        setDone(undefined)
                                        toast.info("Your Account has currently been deactivated", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 10000
                                        });
                                    }else if(accountStatus=== "Active On Payment Confirmation"){
                                            //Account has not been suspended or terminated
                                       
                                        setDone(undefined)
                                        toast.info("Your Account awaiting Payment Confirmation", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 10000
                                        });
                                    }else{
                                        //Lets first check if its active on Trial
                                        var creationDate = docs.get("CreatedAt");
                                        var date = new Date(creationDate * 1000).getDate();
                                        var today = new Date();
                                        var diffInDays = (today.getDate()- date)
                                        
                                        if(diffInDays > 30 && accountStatus==="Active On Trial"){
                                            
                                            setDone(undefined)
                                            toast.info("Your Trial Period has expired", {
                                                position: toast.POSITION.TOP_RIGHT,
                                                autoClose: 10000
                                            });
                                            
                                            

                                            localStorage.setItem("user", JSON.stringify(userCredential))
                                            dispatch({type: "USER", payload: userCredential})

                                            //Redirect to trial period has expired page
                                            history.push("/members/trial-expired")
                                        }else{
                                            //put details in local storage and return all value needed in cloud fire store
                                            setDone(undefined)
                                            if(localStorage.getItem("verify")){
                                                localStorage.removeItem("verify")
                                            }
                                            localStorage.setItem("currentMember", JSON.stringify(userCredential))
                                            
                                            dispatch({type: "USER", payload: userCredential})
                                                history.push("/members/dashboard")
                                        }
                
                                    }
                                }
                                
                            
                            }else{
                                
                                
                                setDone(undefined)

                                toast.info("Please complete your Profile", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 10000
                                });
                                
                                localStorage.setItem("current", JSON.stringify(userCredential))
                                
                                dispatch({type: "USER", payload: userCredential})
                                    history.push("/members/create-profile")
                            }
                        }).catch(async(_)=>{
                            setDone(undefined)
                            toast.info("Could not fetch Profile... Please try again later", {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 10000
                            });
                        })
                        
                           
                        
        
                        
                    }else{
                        //Email has not been verified, hence notify the user and also resend email notification
                        
                        setDone(undefined)
                        toast.info("Your Email Address has not been verified", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        localStorage.setItem("verify", JSON.stringify(userCredential))
                        dispatch({type: "USER", payload: userCredential})

                        //Redirect to email has not been verified and resend email
                        history.push("/members/verify-email")
                    }
                }).catch((error)=>{
                    setDone(undefined)
                        toast.error(error.code, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                })
        }
    }

    
    return (
        <React.Fragment>
            <Grid item xs={12}>
            
              { done? <Grid align="center">
                    <ReactLoading type={"spin"} color={"green"} height={200} width={200} />
                </Grid>:
                
                <Grid>
                    <Paper elevation = {10} style= {paperStyle}>
                    <Grid align ="center">
                    <Avatar style= {avatarStyle}><LockOutlinedIcon /></Avatar>
                    <h2>Sign In</h2>
                    </Grid>
                    <TextField 
                        id="email" 
                        label="Email" type="email"
                        value={email}
                        onChange = {(e)=>setEmail(e.target.value)} 
                        required 
                        placeholder = "Enter valid email" 
                        variant="standard" 
                        fullWidth 
                    />
                    <TextField 
                        id="password" 
                        label="Password" 
                        required 
                        placeholder = "Enter Password" 
                        value={password}
                        onChange= {(e)=>setPassword(e.target.value)}
                        type="password" 
                        variant="standard" 
                        fullWidth 
                    />
                    <Button     
                        style={{paddingTop: "7px", marginTop:"10px"}} 
                        color= "primary" 
                        fullWidth 
                        variant="contained"
                        onClick={()=>postDetails()}
                        >
                            Sign In
                    </Button>
                    <Typography align="center" style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <Link to="/forgot-password"> 
                            Forgot Password?
                        </Link>
                    </Typography>
                    <Typography>
                        Don't have an account? 
                        <Link to="/members/signup"> 
                            Create account
                        </Link>
                    </Typography>
                </Paper>

                
                <ToastContainer />
                </Grid>

                }       
            </Grid>
           

        </React.Fragment>
    )
}

export default Login
