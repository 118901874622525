import {BrowserRouter as Router, Switch, Redirect, Route, useHistory} from 'react-router-dom';
import React, {useEffect,useReducer, createContext, useContext} from 'react'
import Login from './beforelogin-pages/Login';
import SignUp from './beforelogin-pages/SignUp';
import ForgotPassword from './beforelogin-pages/ForgotPassword';
import TermsAndConditions from './beforelogin-pages/TermsAndCondition';
import CompleteProfile from './registration/pages/CompleteProfile'
import LandingPage from './beforelogin-pages/LandingPage';
import Transactions from './dashboard/pages/Transactions';
import Feeds from './dashboard/pages/Feeds';
import Notification from './dashboard/pages/Notification';
import DealConnect from './dashboard/pages/DealConnect';
import Dealshare from './dashboard/pages/Dealshare';
import CreatedDeals from './dashboard/pages/CreatedDeals';
import InterestedDeals from './dashboard/pages/InterestedDeals';
import MembershipLicense from './dashboard/pages/MembershipLicense';
import MembershipCategory from './dashboard/pages/MembershipCategory';
import Messages from './dashboard/pages/Messages';
import QuickPosts from './dashboard/pages/QuickPosts';
import LogOut from './dashboard/pages/LogOut';
import AboutUs from './beforelogin-pages/AboutUs';
import Sucess from './registration/pages/Sucess';
import ResendVerification from './registration/pages/ResendVerification';
import {reducers, initialState} from './reducers/UserReducers'
import TrialPeriod from './registration/pages/TrialExpired';
import ProfileCompleted from './registration/pages/ProfileCompleted';
import DealConnectSuccess from './dashboard/components/dealconnect/component/Success';
import ViewDetails from './dashboard/components/dealconnect/component/ViewDealConnect'
import Profile from './dashboard/pages/Profile';
import MembersProfile from './dashboard/pages/MembersProfile';
import QuickPostSection from './dashboard/pages/QuickPostSection'
import CreateNewDeal from './dashboard/components/dealconnect/component/CreateNewDeal';

export const UserContext = createContext()
  
  

  

const Routing = ()=>{
  
  const {state, dispatch}  = useContext(UserContext)
  const history = useHistory()

  useEffect(()=>{
    const currentMember = JSON.parse(localStorage.getItem("currentMember"))
    const current = JSON.parse(localStorage.getItem("current"))
    
    if(currentMember){
          dispatch({type: "USER", payload: currentMember})
          history.push("/members/dashboard")
      
    }else if(current){
      dispatch({type: "USER", payload: current})
      history.push("/members/create-profile")
    }else{
      history.push("/")
    }

    
  }, [])
  return (
          <Switch>
            <Route path="/"  exact component ={LandingPage} />
            <Route path="/members/login" exact component ={Login} />
            <Route path="/members/signup" exact component ={SignUp} />
            <Route path="/members/verify-email" exact component ={ResendVerification} />
            <Route path="/members/trial-expired" exact component ={TrialPeriod} />
            <Route path="/members/signup/success" exact component ={Sucess} />
            <Route path="/terms-and-conditions" exact  component ={TermsAndConditions} />
            <Route path="/forgot-password" exact component ={ForgotPassword} />
            <Route path="/members/create-profile" exact component ={CompleteProfile} />
            <Route path="/members/dashboard" exact component ={Feeds} />
            <Route path="/members/feeds" exact component ={Feeds} />
            <Route path="/members/notifications" exact component ={Notification} />
            <Route path="/members/deal-connect" exact component ={DealConnect} />
            <Route path="/members/deal-share" exact component  = {Dealshare} />
            <Route path="/members/created-deals" exact component  = {CreatedDeals} />
            <Route path="/members/transactions" exact component  = {Transactions} />
            <Route path="/members/interested-deals" exact component  = {InterestedDeals} />
            <Route path="/members/license" exact component  = {MembershipLicense} />
            <Route path="/members/category" exact component  = {MembershipCategory} />
            <Route path="/members/messages" exact component  = {Messages} />
            <Route path="/members/quick-posts" exact component  = {QuickPosts} />
            <Route path="/members/account/logout" exact component  = {LogOut} />
            <Route path="/about" exact component  = {AboutUs} />
            <Route path="/members/deal-created" exact component  = {DealConnectSuccess} />
            <Route path="/members/profile-completed" exact component  = {ProfileCompleted} />
            <Route path="/members/deal-connect/deals/:id" exact component  = {ViewDetails} />
            <Route path="/members/profile/details/:id" exact component  = {MembersProfile} />
            <Route path="/members/profile" exact component  = {Profile} />
            <Route path="/members/quick-posts/:id" exact component  = {QuickPostSection} />
            <Route path="/members/deal-connects/create-new-deal" exact component  = {CreateNewDeal} />
            <Redirect to="/" />
          </Switch>

  )

}

function App() {

  const [state, dispatch] = useReducer(reducers, initialState)
  
  return (
    <React.Fragment>
      
      <UserContext.Provider value={{state, dispatch}}>
        <Router>
          <Routing />
            

        </Router>
      </UserContext.Provider>
      
      
    </React.Fragment>
      
   
  );
}

export default App;
