import React from 'react';
import ReactDOM from 'react-dom';
import {StepsProvider} from './registration/Context';
import App from './App';
import './css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';



ReactDOM.render(
  <React.Fragment>
    <StepsProvider>
      <App />
    </StepsProvider>
    
  </React.Fragment>,
  document.getElementById('root')
);

