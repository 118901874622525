import React from 'react'
import Land from './Land'
import Funding from './Funding'

export default function LanFunds() {
    return (
        <React.Fragment>
            <Land />
            <Funding />
        </React.Fragment>
    )
}
