import React, {useEffect} from 'react'
import Header from '../components/Header'
import { Typography, Grid, Paper } from '@mui/material'
import useStyles from '../components/HeaderStyles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from 'react-router';
import db from '../components/firebaseConfig';
import {doc, getDoc } from "firebase/firestore/lite";

function createData(category, transactions) {
    return { category, transactions };
  }
  
  const rows = [
    createData('Active Member', '$0 - $198,000'),
    createData('Premium Member', '$200,000 - $500,000'),
    createData('Diamond Member', '$502,000 - $100,000,000'),
    createData('Legend Member', '$1,200,000 - $6,000,000'),
    createData('Grand Legend Member', 'Above $6Million'),
  ];

export default function MembershipCategory() {
    const classes = useStyles()
    const history = useHistory()

    const currentMember = JSON.parse(localStorage.getItem("currentMember"))

    useEffect(async ()=>{
        var firstName = ""

  
            if(currentMember){
                const docRef = doc(db, "Members", currentMember.user.uid);
                await getDoc(docRef).then(async(docs)=>{

                    
                    if(docs.exists){
                        const accountStatus=  await docs.get("AccountStatus");
                        firstName = await docs.get("firstName")

                        if(!firstName){
                            
                            localStorage.removeItem("currentMember");
                            history.push("/")
                                    
                        }else{
                            if(accountStatus == "Pending"){
                                //Account has not been apprved
                                localStorage.removeItem("currentMember");
                                    history.push("/")
                            }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                                //Account has not been suspended or terminated
                                localStorage.removeItem("currentMember");
                                history.push("/")
                            }else if(accountStatus== "Active On Payment Confirmation"){
                                    //Account has not been suspended or terminated
                                    localStorage.removeItem("currentMember");
                                    history.push("/")
                            }else{
                                //Lets first check if its active on Trial
                                var creationDate = docs.get("CreatedAt");
                                var date = new Date(creationDate * 1000).getDate();
                                var today = new Date();
                                var diffInDays = (today.getDate()- date)
                                
                                if(diffInDays > 30 && accountStatus=="Active On Trial"){
                                    localStorage.removeItem("currentMember");
                                    history.push("/")
                                }else{
                                    //do nothing
                                }

                                }
                            }
                            
                        
                        }else{
                            localStorage.removeItem("currentMember");
                                    history.push("/")
                            }
                        }).catch(async(_)=>{
                            localStorage.removeItem("currentMember");
                                        history.push("/")
                        })

                                
        
            }else{
                history.push("/")
            }
        
      },[])

    return (
        <React.Fragment>
            <Header />
            <Grid item xs={12} className={classes.wrapper}>
                <Paper elevation={1}>
                    <Typography variant="h4" >Membership Category</Typography>
                    <TableContainer className={classes.table}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Membership Categories</TableCell>
                                <TableCell>Transactions Amount Expected</TableCell>
                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.category}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.category}
                                </TableCell>
                                <TableCell>{row.transactions}</TableCell>
                                
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>

                </Paper>
            </Grid>
            
        </React.Fragment>
    )
}
