import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Paper, Button, Typography} from '@mui/material';

export default function TrialPeriod() {

    const paperStyle = {
        padding: 20,
        height: '50vh',
        width: 400,
        margin: "20px auto"
    }

    const currentMember = JSON.parse(localStorage.getItem("user"))
          const history = useHistory();

          useEffect(()=>{
            if(!currentMember){
              history.push("/")
            }
          },[currentMember, history])

         
    return (
        <React.Fragment>
            <Grid item xs={12}>
                
                <Grid>
                <Paper elevation={10} style= {paperStyle}>
                    <Grid align ="center">
                        <Typography variant="h5">Your Trial Period of 30days has expired</Typography>
                        <Typography variant="h6">Get Your membership License ...</Typography>
                        <Link to="/members/license" style={{textDecoration: "none"}}>
                        <Button 
                            style={{marginTop:"15px", marginBottom: "10px"}} 
                            color= "success" 
                            fullWidth
                            variant="contained">
                          Get Membership License
                    </Button>
                        </Link>
                    <Link to="/members/login" style={{textDecoration: "none"}}>Back to Login</Link>
                    </Grid>
                    
                </Paper>
                
                </Grid>
                
            </Grid>
        </React.Fragment>
    )
}
