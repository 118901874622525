import React, {useEffect} from 'react'
import Header from '../components/Header'
import { Typography, Grid, Paper } from '@mui/material'
import useStyles from '../components/HeaderStyles'
import {Container, Row, Col} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function LogOut() {
    const classes = useStyles()
    const currentMember = JSON.parse(localStorage.getItem("currentMember"))
    const history = useHistory()

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        history.push("/members/dashboard")
    };

    const handleLogout = () =>{
        localStorage.removeItem("currentMember");
        history.push("/")
    }

    useEffect(() => {
       if(!currentMember){
            history.push("/")
       }else{
           handleClickOpen()
       }
    }, [])
    return (
        <React.Fragment>
            <Header />
            <Grid className= {classes.wrapper}>
                <Typography variant="h4" >Log Out</Typography>
                <Grid align="center">
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {"Are You sure you want to Log out?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Click on Continue to Proceed
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={()=>handleLogout()} autoFocus>
                           Continue
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

            </Grid>
            
        </React.Fragment>
    )
}
