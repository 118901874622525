import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'


export default function TermsAndCondition() {

  const paperStyle = {
    padding: 20,
    width: 450,
    height: "1700vh",
    margin: "0px auto",
    justifyContent: "justify"
}

  return (
    <React.Fragment>
        <Grid item xs={12}>
            <Paper elevation={10} style={paperStyle}>
                <Grid style={{marginBottom: "20px"}} align="center">
                       <b> <Typography variant="h4" style={{marginTop: "15px"}}>Terms and Conditions</Typography></b>
                  </Grid>

                <Grid>
                  <Typography variant="inherit" align="center">
                  <p><h5><b>Rentwey’s DEVCLUB360® Terms of Service</b></h5></p>

<Typography align="left"><p><b>Introduction</b></p></Typography>

<Typography align = "left">

<p>These Terms and Conditions ("Terms") contained herein on this page is a legal agreement between you, as a prospective customer and or customer of Rentwey’s services, DEVCLUB360 and MORTGOGENIE PROPERTY COMPANY Limited (MORTGOGENIE, "we", "our" or "us") and shall govern your access to and use of DEVCLUB360’s services which include all pages within the DEVCLUB360 website, mobile applications and other products and services (collectively referred to as the "Services").

These Terms apply in full force and effect to your use of the Services and by using any of the Services, you expressly accept all terms and conditions contained herein in full and without limitation or qualification, including our Privacy Policy. You must not use any of the Services, if you have any objection to any of these Terms.
</p></Typography>
<Typography align="left"><p><b>Who we are and our company information:</b></p></Typography>

We are Mortgogenie Property Company; a company registered under the laws the Federal Republic of Nigeria by the Corporate Affairs Commission CAC (RC. No 1723236) Rentwey and DEVCLUB360 is a signature product of Mortgogenie Property Company Limited.

<br /><br /><Typography align="center"><p><b>PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE AGREEING TO BE BOUND BY ITS TERMS.</b></p></Typography> <br />

<Typography align="left"><p><b>Governing Language</b></p></Typography>

The governing language of these terms and all communication between DEVCLUB360 and you will be English language.
<br /><br />
<Typography align="left"><p><b>Who May Use Our Services?</b></p></Typography>

You may use the Services only when you agree to form a binding contract with DEVCLUB360 and are not a person barred from receiving services under the laws of the applicable jurisdiction. If you are accepting these Terms and use the Services on behalf of a company, business, or organization, you represent and warrant that you are authorized to do so.
<br /> <br />
<Typography align="left"><p><b>DEVCLUB360 APP ACCOUNT</b></p></Typography>

<Typography align="left"><p>Opening a DEVCLUB360 Account</p></Typography>

To use DEVCLUB360, you are required to register and open a DEVCLUB360 Account with us by providing the necessary information needed to process you for the use of this service which you agree to.

<Typography align="left">We offer DevClub360 Accounts for three (3) types of purposes:</Typography>
<ol>
<li align="left">Personal membership accounts (You may only have one personal account)</li>

<li align="left">Business Linkage (To connect with other DEVCLUB360 users and the DEVCLUB360 for legitimate business and opportunities; and
</li>
<li align="left">Social connections.</li>
</ol>

<p align="left">To create a personal account, you must be at least 18 years old.</p>
<p align="left">Personal accounts let you do things like:</p>
<ul align="left">
<li>Post your real estate business or other relevant businesses</li>
<li>Contribute to other users’ businesses through partnerships, Joint Venture, profit sharing through the app</li>
<li>Socially connect and fraternize as club members</li></ul>

To register, open and use a DEVCLUB360 apps, you must sign up, create an account, pay a membership fee and provide necessary information including but not limited to your name, email address, mobile phone number, street address, date of birth etc. (hereinafter Personal Net-worth Information). You represent and warrant that all personal Information provided is true and accurate.

You hereby authorize DEVCLUB360, directly or through third parties, to make any inquiries we consider necessary to validate your identity and/or authenticate your identity and personal Information. This may include asking you for further information and/or documentation about your businesses, its registration or identity, requiring you to take steps to confirm ownership of your email address, mobile phone number or financial instruments, or verifying your information against third party databases or through other sources.

In connection with your use of the Service, you may not refuse to (a) cooperate in an investigation concerning activity that potentially violates the law and/or the Service Terms, (b) provide confirmation of your identity, or (c) provide confirmation of any information you provide us.

You agree that you are responsible for safeguarding your DEVCLUB360 Account, so, use a strong password and a unique code and limit it to use to your DEVCLUB360 Account. Do not allow anyone to have or use your password or transaction details. In the event that you share such details, we cannot and will not be liable for any loss or damage arising from your failure to comply with the above.
<br /><br />
<p align="left"><b>Notifications</b></p>

By providing us with your Account Information, you consent to receiving text (SMS), email and push notifications from us.
<br />< br />
<p align="left"><b>CONDUCTING BUSINESS DEALS ON THE DEVCLUB360</b></p>

<p align="left">All business transactions carried out on the DEVCLUB360 app and offline as DEVCLUB360 users and members must meet the following:
</p>
<ul align="left">
<li>Go through the DEVCLUB360 whether business was initiated through the app or outside the app, as long as members/users have met through the DEVCLUB360 app, meetings, hangouts, programmes and for as long as your membership is active or inactive.</li>
<li>The DEVCLUB360 is entitled to a management fee of 9.7% of all transactions carried out through the “Deal Connect, Quick Post, Feeds, Announcement, InMessage feature or any other facilitated medium. Excludes the Deal Share feature.</li>
<li>On the Deal Share feature; The DEVCLUB360 determines the profit sharing, commission, investment structure, implementation, management, planning, execution and completion terms of all business deals, transactions and deals facilitated through the “Deal Share feature.</li>
<li>It is your responsibility to know the commission, profit sharing rates to be used for every business initiated through the DEVCLUB360 app and companies.</li>

</ul>
<p align="left"><b>Closing a DEVCLUB360 Account</b></p>

<p>You may close your DEVCLUB360 Account and terminate your relationship with us without cost, but you will remain liable for all obligations related to your DEVCLUB360 Account and membership even after the DEVCLUB360 Account is closed. Any incomplete transactions or payments must be completed or cancelled, and you must transfer any relevant information, documents before closing it.</p>

<p align="center">In certain cases, you may not close your DEVCLUB360 Account, including:</p>
<ul align="left">
<li>To evade an investigation.</li>
<li>If you have a pending transaction or an open dispute or claim.</li>
<li>If you owe amounts to us.</li>
<li>If your DEVCLUB360 Account is subject to a hold, limitation or reserve.</li>
</ul>
<p align="left">We may suspend or terminate your account or cease providing you with all or part of the services at any time, including but not limited to, if we reasonably believe; (i) you do not comply with any of the provisions of this Agreement, (ii) you create risk or possible legal exposure to us, (iii) we are required by law to do so, and (iv) you have engaged in fraudulent or suspicious transactions.</p>


<p align="left"><b>User/members business reviews</b></p>

<p align="left">We review account and business transaction activity at various times, including when projects are initiated. This review checks for, among other things, suspicious or illegal activity, and whether your account activity and the activity of users with whom you've transacted comply with this agreement. In connection with our review process, you may be required to provide us with additional information and/or documentation to verify your claims. We may limit your account and your access to the project until verification is completed.

Reviews may result in:</p>
<ul align="left">

<li>delayed, blocked or cancelled transactions;</li>
<li>money or payments being held by us;</li>
<li>money or payments being applied to amounts you owe to us, to other members/users or used to offset loss incurred by us;</li>
<li>account limitation, suspension or termination;</li>
<li>money or payments being seized to comply with a court order, warrant or other legal process; and/or

Among other reasons, we may take the above actions if you knowingly or unknowingly were a participant in a transaction that was made from falsehood, illegality, operated to swindle other users or compromised DEVCLUB360 Account**.**
</li>
</ul>
<p align="left"><b>Publicity</b></p>

<p align="left">You grant DEVCLUB360 permission to use your name and image in our marketing material without any further consent required, including, but not limited to use on our website, in promotional materials and in press releases. Such publicity does not in any way imply an endorsement for your products and services.</p>

<p align="left"><b>RESTRICTED ACTIVITIES</b></p>

<p align="left">In connection with your use of our websites, mobile app; your DEVCLUB360 Account or in the course of your interactions with us, other customers, or third parties, you must not:</p>
<ul align="left">
<li>Breach the Terms between you and us;</li>
<li>Violate any law, statute, ordinance, or regulations;</li>
<li>Infringe our or any third party's copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy;</li>
<li>Create or control more than one personal account for yourself without our express authorization, through among other methods, using a name that is not yours, using a temporary email address or phone number, or providing any other falsified personal information;</li>
<li>Act in a manner that is defamatory, trade libelous, threatening or harassing;</li>
<li>Provide false, inaccurate or misleading information;</li>
<li>Send or receive what we reasonably believe to be potentially fraudulent business or payments for advertising, marketing, or otherwise on an unsolicited and unauthorized basis;</li>
<li>Refuse to cooperate in an investigation or provide confirmation of your identity or any information you provide to us;</li>
<li>Control an account that is linked to another DEVCLUB360 Account that has engaged in any of these restricted activities;</li>
<li>Use the DEVCLUB360 services in a manner that results in or may result in:</li>
<li>complaints;</li>
<li>disputes; claims, reversals,</li>
<li>fees, fines, penalties or other liability or losses to Mortgogenie, other customers, third parties or you;</li>
<li>Have any amounts owed to us;</li>
<li>Take any action that imposes an unreasonable or disproportionately large load on our websites, software, systems (including any networks and servers used to provide any of the services) operated by us or on our behalf;</li>
<li>Facilitate any viruses, trojan horses, malware, worms or other computer programming routines that attempts to or may damage, disrupt, corrupt, misuse, detrimentally interfere with, surreptitiously intercept or expropriate, or gain unauthorized access to any system, data or information;</li>
<li>Use an anonymizing proxy; use any robot, spider, other automatic device, or manual process to monitor or copy our websites without our prior written permission;</li>
<li>Interfere or disrupt or attempt to interfere with or disrupt our websites, software, systems (including any networks and servers used to provide any of the DEVCLUB360 services) operated by us or on our behalf;</li>
<li>Take any action that may cause us to lose any of the services from our Internet service providers, payment processors, or other suppliers or service providers;</li>
<li>Circumvent any of our policies or determinations about your DEVCLUB360 Account such as temporary or indefinite suspensions or other account holds, limitations or restrictions, including, but not limited to, engaging in the following actions: attempting to open new or additional DEVCLUB360 Account(s) when you have amounts owed to us or when your DEVCLUB360 Account has been restricted, suspended or otherwise limited; opening new or additional DEVCLUB360 Accounts using information that is not your own (e.g. name, address, email address, etc.); or using someone else's DevClub360 Account; or</li>
<li>Harass and/or threaten our employees, members, or other users.</li>

</ul>
<p align="left"><b>Actions We May Take if You Engage in Any Restricted Activities</b></p>

<p align="left">If we believe that you've engaged in any of these activities, we may take a number of actions to protect ourselves, our customers and others at any time in our sole discretion. The actions we make take include, but are not limited to, the following:</p>

<ul align="left">
<li>Terminate your agreement with us, limit your DevClub360 Account, and/or close or suspend your DevClub360 Account immediately and without penalty to us;</li>
<li>Refuse to provide the DEVCLUB360 services to you in the future;</li>
<li>Limit your access to our websites, software, systems (including any networks and servers used to provide any of the DEVCLUB360 services) operated by us or on our behalf, your DEVCLUB360 Account, including limiting your ability to use your DEVCLUB360 Account.</li>
<li>Take legal action against you.</li>
<li>Recoup any lost funds from activities carried out</li>
</ul>

<p align="left">If we close your DEVCLUB360 Account or terminate your use of the DEVCLUB360 services for any reason, we will provide you with notice.

You are responsible for all claims, fees, fines, penalties and other liability incurred by us, any customer, or a third party caused by or arising out of your breach of their Terms, and/or your use of the DEVCLUB360 services.
</p>

<p align="left"><b>Age Restriction</b></p>

<p align="left">Our website and services are directed to people from the ages of 18 and above. You are only permitted to use the Services if you are aged 18 or older and resident in the Federal Republic of Nigeria and the ECOWAS Area. We do not knowingly engage in people younger than the age of 18.
</p>

<p align="left"><b>Intellectual Property</b></p>

<p align="left">Unless otherwise stated, Mortgogenie and/or its subsidiaries, licensors own the intellectual property rights and materials on the website subject to the license below. All text, formatting (including without limitation the arrangement of materials on the DEVCLUB360 website and the graphics, animation, tools, commercials, music, video, articles, sound, copy, trade names, logos and other materials and information on the website are subject to the intellectual property rights of DEVCLUB360 and its affiliates and their licensors and licensees (collectively the "Content"). We do not grant you any right, license, title or interest to any of our intellectual Property rights which you may or may not have access to. These Content may not be copied, reverse engineered, decompiled, disassembles, modified or reposted to other websites. Nothing on the DEVCLUB360 app should be construed as granting by implication or otherwise, any license or right to use any Trademark displayed on the DEVCLUB360 website without the written permission of DEVCLUB360 or such third party that may own the Trademark. You agree to take such actions including any legal or official document or other documents that may be needed to further affirm our intellectual property rights.
</p>
<p align="left">You may not:</p>

<ol type="a" align="left">

<li>Modify or copy the materials;</li>
<li>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
<li>Remove any copyright or other proprietary notations from the materials; or </li>
<li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
<li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by DEVCLUB360 at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
</li>
</ol>
<p align="left">The services are protected by copyright, trademark, and other laws of both the Federal Republic of Nigeria and foreign countries. Nothing in this Term gives you a right to use the DEVCLUB360 name or any of DEVCLUB360’s trademarks, logos, domain names, and other distinctive brand features. All right, title and interest in and to the services are and will remain the exclusive property of DEVCLUB360 and its licensors.
If you do not comply with all the provisions, then you will be liable for all resulting damages suffered by you, DEVCLUB360 and all third parties. Unless otherwise provided by applicable law, you agree not to alter, re-design, reproduce, adapt, display, distribute, translate, disassemble, reverse engineer, or otherwise attempt to create any source code that is derived from the software.

Any feedback, comments, or suggestions you may provide to us and our services is entirely voluntary and we will be free to use such feedback, comments or suggestion as we see fit without any obligation to you.
</p>
<p align="left"><b>Termination</b></p>

<p align="left">You may terminate this Agreement by closing your DEVCLUB360 Account.

We may suspend or terminate your account or cease providing you with all or part of the services at any time, including but not limited to, if we reasonably believe; (i) you do not comply with any of the provisions of this Agreement, (ii) you create risk or possible legal exposure to us, (iii) we are required by law to do so, (iv) you have engaged in fraudulent or suspicious activities.
</p>

<p align="left"><b>Information Security and Warranty Disclaimer</b></p>

<p align="left">DEVCLUB360 WILL ALWAYS ENSURE THAT THE WEBSITE IS AVAILABLE AT ALL TIMES AND BUG FREE. HOWEVER, IT IS USED AT YOUR OWN RISK.
WE PROVIDE ALL MATERIALS "AS IS" WITH NO WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS, INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, SECURITY, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, DEVCLUB360 MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL REMAIN FREE FROM ANY INTERRUPTION, BUGS, INNACCURACIES, AND ERROR FREE.
YOUR USE OF OUR SERVICES ARE AT YOUR OWN RISK AND YOU ALONE WILL BE RESPONSIBLE FOR ANY DAMAGE THAT RESULTS IN LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN OBTAINED BY YOU FROM OUR WEBSITE OR OUR SERVICES WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED.

You are responsible for configuring your information technology, computer programmes and platform in order to access our Services. Please ensure you use your virus protection software or application as we cannot guarantee that our Services will be free from viruses or bugs.
You must not attempt to gain unauthorized access to our Services, computers or databases. You must not misuse our Services by introducing trojans, viruses or other materials which are malicious or technologically harmful.
</p>

<p align="left"><b>Limitation of Liability</b></p>

<p align="left">YOUR USE OF DEVCLUB360 SITE AND SERVICES IS AT YOUR OWN RISK. YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: DEVCLUB360 WILL IN NO WAY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL PUNITIVE, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES OR ANY DAMAGES INCLUDING DAMAGES RESULTING FROM REVENUE LOSS, PROFIT LOSS, USE, DATA, GOODWILL , BUSINESS INTERRUPTION OR ANY OTHER INTANGIBLE LOSSES (WHETHER DEVCLUB360 HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR NOT) ARISING OUT OF DEVCLUB360 WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION TO INABILITY TO USE, OR ARISING FROM THE RESULT OF USE OF DEVCLUB360’s WEBSITE OR SERVICES) WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR ANY OTHER LEGAL THEORY.
SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON THE SCOPE AND DURATION OF SUCH WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU IN THEIR ENTIRITIES, BUT WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
</p>
<p align="left"><b>Indemnification</b></p>

<p align="left">You hereby indemnify DEVCLUB360 and undertake to keep DEVCLUB360, its staff and affiliates indemnified against any losses, damages, costs, liabilities and expenses (including without limitation reasonable legal fees and expenses) arising out of any breach by you of any provision of these Terms, or arising out of any claim that you have breached any provision of these Terms. You will indemnify and hold DEVCLUB360 harmless from and against any claim, suit or proceedings brought against DEVCLUB360 arising from or in connection with violations of Intellectual Property or other rights of third parties in relation to your use of the Services.
</p>
<p align="left"><b>Breaches of these Terms</b></p>

<p align="left">Without prejudice to DEVCLUB360's other rights under these Terms, if you breach these Terms in any way, DEVCLUB360 may take such action as DEVCLUB360 deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.
</p>
<p align="left"><b>Cookies</b></p>

<p align="left">We use cookies to identify you as a user and to customize and improve our services. A cookie is a small data file that is transferred to your computer or mobile device. It enables us to remember your account log-in information, IP addresses, web traffic, number of times you visit, date and time of visits.
Some browsers may automatically accept cookies while some can be modified to decline cookies or alert you when a website wants to place a cookie on your computer. If you do choose to disable cookies, it may limit your ability to use our website.
</p>
<p align="left">Data Protection</p>

<p align="left">DEVCLUB360 recognizes the importance of protecting the privacy of those who visit and choose to use the DEVCLUB360 Services. DEVCLUB360 remains compliant with all Data Security Standard requirement to the extent applicable. Please note that such requirements may be amended from time to time. With respect to all the Personal Information belonging to, and/or processed in connection with DEVCLUB360 or this website, such Personal Information at all times are in compliance with all Data Protections Laws in the territory, in all respect and in particular the GDPR. DEVCLUB360 maintains a Privacy Policy which provides an overview of the Personal Information we collect about you or that you provide to us. By using the DEVCLUB360 Services, you consent to such processing and you commit to provide accurate information.
</p>
<p align="left"><b>Governing Law</b></p>

<p align="left">These Terms shall be interpreted and governed in accordance with the Laws of the Federal Republic of Nigeria and you submit to the non-exclusive jurisdiction of the Courts located in Nigeria for the resolution of any dispute.
</p>
<p align="left"><b>Changes to the DEVCLUB360 Terms of Use</b></p>

<p align="left">The materials appearing on DEVCLUB360’s mobile application and/or web site could include technical, typographical, or photographic errors. DEVCLUB360 does not warrant that any of the materials on its web site are accurate, complete, or current. DEVCLUB360 may make changes to the materials contained on its web site at any time without notice. DEVCLUB360 does not, however, make any commitment to update the materials.

DEVCLUB360 reserves the right to change, revise or modify these Terms from time to time by updating this page. The changes will not be retroactive, and the most current version of the Terms which will always be made available and will continue to govern our relationship with you. We will also try to notify you of any material changes which could be done via email associated with your account or service notification. By continuing to use our services after the changes become effective, you agree to be bound by the revised Terms.
</p>
<p align="left"><b>Our Fees</b></p>

<p align="left">You agree to pay the applicable fees, commission, dividends from all business transactions on the DEVCLUB360 and your membership. All Fees are charged at the time we process a transaction and are deducted first from the point of initiating such transactions or collected funds and thereafter based on agreed terms. You also grant us Recovery Authorizations; which we may utilize to recover any fees you owe us. Subject to the General Terms and these Payment Terms, we reserve the right to change the Fees upon reasonable advance notice.
</p>

<p align="left"><b>Applicable Fees</b></p>

<p align="left">Subject to these terms and conditions and to any other liabilities or charges that may arise in the course of your transactions, or depending on your location and the nature or currency of your transaction, or from our partners or third party provider, all applicable fees will be displayed on the app at the time of the transaction or through a suitable communication medium.
</p>
<p align="left"><b>Error Resolution Procedures</b></p>

<p align="left">In case of errors, questions or discrepancies about any transaction(s) email us at now.contact@devclub360.com as soon as possible. You must contact us within 60 days from the date of the transaction and you must provide us with the following information:</p>
<ul align="left">
<li> Your name and DevClub360 number.</li>
<li>A description of the error or the transaction you are unsure about and why you believe it is an error or why you need more information.
</li>
<li>The amount of the suspected error or situation.</li>
<p align="left">We will determine whether an error occurred within ten (10) business days after we hear from you and will promptly correct any error. If we need more time to investigate, however, we may take up to 45 days (90 days for international transactions or foreign-initiated transactions). If we ask you to put your complaint in writing and we do not receive it within ten (10) business days, we may not proceed. We will tell you the results within three (3) business days after completing our investigation. You may ask for copies of the documents that we used in our investigation.
</p>
</ul>

<p align="left"><b>General</b></p>

<p align="left">We may revise these Terms from time to time without notice. The changes will not be retroactive, and the most current version of the Terms will govern our relationship with you. We will also try to notify you of any material changes via the email associated with your account. By continuing to use our services after the changes become effective, you agree to be bound by the revised Terms.
These Terms supersedes and extinguishes all previous agreements between you and DEVCLUB360 whether written or oral, relating to its subject matter.
In the event that any of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full effect. DEVCLUB360’s failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.
</p>
<p align="left">
  <b>
Complaints </b></p>

<p align="left">If you have any complaints about us and our Services, you may contact us at: now.contact@devclub360.com 
</p>
<p align="left"><b>Contact Us: DEVCLUB360</b></p>





                  </Typography>
                </Grid>

            </Paper>
        </Grid>
    </React.Fragment>
  )
}
