import React from 'react'
import {Grid, Paper, Button, Typography} from '@mui/material';
import CarouselContainer from '../CarouselContainer';
import { CssBaseline } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import CreateIcon from '@mui/icons-material/Create';
import playstore from '../assets/images/playstore.png'
import {Link} from 'react-router-dom'
import {Container, Row, Col} from "react-bootstrap"

const LandingPage = () => {
    const paperStyle = {
        padding: 20,
        width: 400,
        height: 589,
        margin: "0px auto"
    }
    return (
        <React.Fragment>
            <CssBaseline />
            <Grid item xs>
                <Paper elevation = {10} style= {paperStyle}>
                    <Grid style={{marginBottom: "20px"}}>
                        <CarouselContainer />
                    </Grid>
                    <Link to="/members/login">
                    <Button style={{paddingTop: "7px", marginTop:"10px"}} color= "primary" startIcon={<LoginIcon />} fullWidth variant="contained">Sign In</Button>
                    </Link>
                    
                    <h4 align= "center" style={{marginTop:"10px", marginBottom: "10px"}}>OR</h4>
                    <Link to="/members/signup">
                    <Button color= "primary" fullWidth variant="contained" startIcon = {<CreateIcon />}>Create An Account</Button>
                    </Link>
                    <h6 align= "center" style={{marginTop:"10px", marginBottom: "10px"}}>Get the App</h6>
                    <Container style={{marginTop:"23px"}} >
                        <Row>
                            <Col><a href="https://play.google.com/store/apps/details?id=com.rentwey.devclub360"><img src={playstore} width="170px" height="45px" alt="play store" /></a></Col>
                            <Col><Typography variant="inherit" style={{fontSize:"15px"}}>IOS App Coming soon</Typography></Col>
                        </Row>
                        
                            
                    </Container>
                </Paper>
                
                <Grid style={{margin: "12px auto"}} align="center" >
                    <Link to="/about" style={{textDecoration: "none"}}>About Devclub360</Link>&nbsp;&nbsp;
                    <a href="https://rentwey.com/privacy-policy" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>Privacy</a>&nbsp;&nbsp;
                    <Link to="/terms-and-conditions" style={{textDecoration: "none"}}>Terms</Link>&nbsp;&nbsp;
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default LandingPage
