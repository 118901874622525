import React from 'react';
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import DealConnectForm from './StepForm';


export default function CreateNewDeal() {
    return (
        <React.Fragment>
            <CssBaseline />
                
                <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
                    <Grid item xs>
                        <DealConnectForm />
                    </Grid>
                   
                </Container>
        </React.Fragment>
    )
}
