import React, {useEffect} from 'react'
import {Grid, Typography, Box, Avatar, Divider, Button, CircularProgress} from '@mui/material'
import {Container, Row, Col} from 'react-bootstrap'
import { useHistory } from 'react-router';
import db from '../components/firebaseConfig';
import {doc, getDoc } from "firebase/firestore/lite";
import Header from '../components/Header'
import useStyles from '../components/HeaderStyles'
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import firebaseApp from '../../config/firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';


function createData(category, details) {
    return { category, details };
  }


export default function Profile() {


    const boxStyle= {
        margin: "24px auto"
    }
    
    const [isLoading, setIsLoading] = React.useState(true)
    const history = useHistory()
    const [done, setDone] = React.useState(false)

    const currentMember = JSON.parse(localStorage.getItem("currentMember"))
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("") 
    const [DealToCloseInNextQuarter, setDealToCloseInNextQuarter] = React.useState("")
    const [HomeAddress, setHomeAddress] = React.useState("")
    const [LinkedInProfile, setLinkedInProfile] = React.useState("");
    const [MembersAchievement, setMembersAchievement] = React.useState("")
    const [MemberCategory, setMemberCategory] = React.useState("")
    const [PhoneNumber, setPhoneNumber] = React.useState("")
    const [YearlyWorth, setYearlyWorth] = React.useState("")
    const [ProfilePicsUrl, setProfilePicsUrl] = React.useState("")
    const [lastTransactionWorth, setlastTransactionWorth] = React.useState("")

    //verification details
    const [CAC, setCAC] = React.useState("")
    const [DriversLicense, setDriversLicense] = React.useState("")
    const [NIN, setNIN] = React.useState("")
    const [TIN, setTIN] = React.useState("")
    const [VIN, setVIN] = React.useState("")

    //Company details

    const [CompanyName, setCompanyName] = React.useState("")
    const [CompanyWebsite, setCompanyWebsite] = React.useState("")
    const [MarketArea, setMarketArea] = React.useState([])
    const [PracticeArea, setPracticeArea] = React.useState([])
    const [position, setPosition] = React.useState("")
    const [representative, setRepresentative] = React.useState("")
    const [email, setEmail] = React.useState("")

    //membership detail
    const [status, setStatus] = React.useState("")
    const [memId, setMemId] = React.useState("")


    useEffect(async ()=>{
        var fName = ""

  
            if(currentMember){
                const docRef = doc(db, "Members", currentMember.user.uid);
                const comRef = doc(db, "MembersCompanyDetails", currentMember.user.uid);
                const memRef = doc(db, "MembershipDetails", currentMember.user.uid);
                await getDoc(docRef).then(async(docs)=>{

                    
                    if(docs.exists()){
                        const accountStatus=  await docs.get("AccountStatus");
                        fName = await docs.get("firstName")

                        if(!fName){
                            
                            localStorage.removeItem("currentMember");
                            history.push("/")
                                    
                        }else{
                            if(accountStatus == "Pending"){
                                //Account has not been apprved
                                localStorage.removeItem("currentMember");
                                    history.push("/")
                            }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                                //Account has not been suspended or terminated
                                localStorage.removeItem("currentMember");
                                history.push("/")
                            }else if(accountStatus== "Active On Payment Confirmation"){
                                    //Account has not been suspended or terminated
                                    localStorage.removeItem("currentMember");
                                    history.push("/")
                            }else{
                                //Lets first check if its active on Trial
                                var creationDate = docs.get("CreatedAt");
                                var date = new Date(creationDate * 1000).getDate();
                                var today = new Date();
                                var diffInDays = (today.getDate()- date)
                                
                                if(diffInDays > 30 && accountStatus=="Active On Trial"){
                                    localStorage.removeItem("currentMember");
                                    history.push("/")
                                }else{
                                    setDone(true)
                                    setFirstName(docs.get("firstName"))
                                    setLastName(await docs.get("lastName"))
                                    setDealToCloseInNextQuarter(await docs.get("DealToCloseInNextQuarter"))
                                    setHomeAddress(await docs.get("HomeAddress"))
                                    setLinkedInProfile(await docs.get("LinkedInProfile"))
                                    setMemberCategory(await docs.get("MemberCategory"))
                                    setMembersAchievement(await docs.get("MembersAchievement"))
                                    setPhoneNumber(await docs.get("PhoneNumber"))
                                    setProfilePicsUrl(await docs.get("ProfilePicsUrl"))
                                    setYearlyWorth(await docs.get("YearlyWorth"))
                                    setlastTransactionWorth(await docs.get("lastTransactionWorth"))
                                    setCAC(await docs.get("CAC"))
                                    setDriversLicense(await docs.get("DriversLicense"))
                                    setTIN(await docs.get("TIN"))
                                    setVIN(await docs.get("VIN"))
                                    setNIN(await docs.get("NIN"))

                                    await getDoc(comRef).then(async(company)=>{
                                        if(company.exists()){
                                            setCompanyName(await company.get("CompanyName"))
                                            setCompanyWebsite(await company.get("CompanyWebsite"))
                                            setMarketArea(await company.get("MarketArea"))
                                            setPracticeArea(await company.get("PracticeArea"))
                                            setPosition(await company.get("PositionHeld"))
                                            setRepresentative(await company.get("RepresentativeName"))
                                            setEmail(await company.get("CorporateEmail"))
                                            
                                        }
                                    })

                                    await getDoc(memRef).then(async(mem)=>{
                                        if(mem.exists()){
                                            setStatus(await mem.get("MembershipStatus"))
                                            setMemId(await mem.get("MemberShipId"))
                                        }
                                    })

                                    setDone(false)
                                    setIsLoading(false)
                                }

                                }
                            }
                            
                        
                        }else{
                            localStorage.removeItem("currentMember");
                                    history.push("/")
                            }
                        }).catch(async(_)=>{
                            localStorage.removeItem("currentMember");
                                        history.push("/")
                        })

                                
        
            }else{
                history.push("/")
            }
        
      },[])
      const classes = useStyles()

    const changePassword= ()=>{
        if(currentMember){
            const auth = getAuth();
            sendPasswordResetEmail(auth, currentMember.user.email)
            .then(() => {
                alert("Dear"+" "+firstName+"\nFollow the following steps to Change Password\n1.A Password Reset Link has been sent to your registered email.\n2. Use the Link to reset your password.\n3. Your Account will be logged out")
                localStorage.removeItem("currentMember");
                history.push("/")
            })
            .catch((error) => {
                toast.error("An error occurred while trying to carry out this process",{
                    position: toast.POSITION.TOP_RIGHT
                })
                console.log(error.message)
            });
        }else{
            history.push("/")
        }
    }
    return (
        <React.Fragment>
            <Header />
            <Grid item xs={12} className={classes.wrapper}>
                
                <Typography variant="h4" >Your Profile</Typography>
                <Grid className= {classes.dealconnect}>
                                <div style= {{textAlign: 'right'}}>
                                        <Button 
                                            variant= "contained" 
                                            color="primary"
                                            size="large"
                                            className= {classes.button}
                                            onClick={()=>changePassword()}
                                            >
                                                Change Password
                                        </Button>
                                        
                                    </div>
                                    
                    </Grid>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }} style={boxStyle}>
                    {
                        done ? <Grid align="center">
                        <ReactLoading type={"spin"} color={"green"} height={100} width={100} />
                    </Grid>
                        :
                        isLoading ? <CircularProgress /> :
                        <Grid>
                    <Typography variant="h6" align="left">Personal Information</Typography>
                    <Divider />
                    <Container>
                        <Row align="center" style={{marginBottom: "24px"}}>
                            <Col>
                                <Avatar src={ProfilePicsUrl} alt ="profile" sx={{ width: 80, height: 80 }} />
                                   
                            </Col>
                        </Row>
                        <Row>
                            <Col><Typography variant="inherit">First Name</Typography></Col>
                            <Col><Typography variant="inherit">{firstName}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Last Name</Typography></Col>
                            <Col><Typography variant="inherit">{lastName}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Email Address</Typography></Col>
                            <Col><Typography variant="inherit">{currentMember.user.email}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Home Address</Typography></Col>
                            <Col><Typography variant="inherit">{HomeAddress}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Phone Number</Typography></Col>
                            <Col><Typography variant="inherit">{PhoneNumber}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">LinkedIn Profile</Typography></Col>
                            <Col><Typography variant="inherit">{LinkedInProfile}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "15px"}}>
                            <Col><Typography variant="h6" align="left">Personal Achievements</Typography></Col>
                        </Row>
                        <Divider />
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Deal To close in next Qtrs</Typography></Col>
                            <Col><Typography variant="inherit">{"USD"+DealToCloseInNextQuarter}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Member's Category</Typography></Col>
                            <Col><Typography variant="inherit">{MemberCategory}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Achievements</Typography></Col>
                            <Col><Typography variant="inherit">{MembersAchievement}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Last Transaction Worth</Typography></Col>
                            <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(lastTransactionWorth))}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Yearly Transaction</Typography></Col>
                            <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(YearlyWorth))}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "15px"}}>
                            <Col><Typography variant="h6" align="left">Verification Profile</Typography></Col>
                        </Row>
                        <Divider />
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">CAC</Typography></Col>
                            <Col><Typography variant="inherit">{CAC == "" ? "Not Available": CAC}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Driver's License</Typography></Col>
                            <Col><Typography variant="inherit">{DriversLicense == "" ? "Not Available": DriversLicense}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">TIN</Typography></Col>
                            <Col><Typography variant="inherit">{TIN == "" ? "Not Available": TIN}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">NIN</Typography></Col>
                            <Col><Typography variant="inherit">{NIN == "" ? "Not Available": NIN}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">VIN</Typography></Col>
                            <Col><Typography variant="inherit">{VIN == "" ? "Not Available": VIN}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "15px"}}>
                            <Col><Typography variant="h6" align="left">Company's Profile</Typography></Col>
                        </Row>
                        <Divider />
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Company's Name</Typography></Col>
                            <Col><Typography variant="inherit">{CompanyName}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Company's Website</Typography></Col>
                            <Col><Typography variant="inherit">{CompanyWebsite==""?"Not Available": CompanyWebsite}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Market Area</Typography></Col>
                            <Col>{
                                MarketArea.map(item=>{
                                    return <Typography variant="inherit">{item}</Typography>
                                })
                            }</Col>
                        </Row>

                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Practice Area</Typography></Col>
                            <Col>{
                                PracticeArea.map(item=>{
                                    return <Typography variant="inherit">{item}</Typography>
                                })
                            }</Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Representative's Name</Typography></Col>
                            <Col><Typography variant="inherit">{representative}</Typography></Col>
                        </Row>

                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Corporate Email</Typography></Col>
                            <Col><Typography variant="inherit">{email}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "15px"}}>
                            <Col><Typography variant="h6" align="left">Membership Detail</Typography></Col>
                        </Row>
                        <Divider />
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">MemberShip Id</Typography></Col>
                            <Col><Typography variant="inherit">{
                                memId.length==1 ? "INVESTOR00"+memId: memId.length==2 ? "INVESTOR0"+memId: "INVESTOR"+memId
                            }</Typography></Col>
                        </Row>

                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">MemberShip Status</Typography></Col>
                            <Col><Typography variant="inherit">{status}</Typography></Col>
                        </Row>

                        
                    </Container>
                    </Grid>
                    }
                   <ToastContainer />

                </Box>
            </Grid>
        </React.Fragment>
    )
}
