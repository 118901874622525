import React, {useEffect, useState} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {doc, getDoc} from 'firebase/firestore/lite'
import db from '../components/firebaseConfig'
import {Grid, Typography, Box, Avatar, Divider, Button, CircularProgress} from '@mui/material'
import {Container, Row, Col} from 'react-bootstrap'
import Header from '../components/Header'
import useStyles from '../components/HeaderStyles'
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

export default function MembersProfile() {

    const [isLoading, setIsLoading] = React.useState(true)
    const currentMember = JSON.parse(localStorage.getItem("currentMember"))
    const history = useHistory()
    const {id} = useParams()

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("") 
    const [DealToCloseInNextQuarter, setDealToCloseInNextQuarter] = React.useState("")
    const [MembersAchievement, setMembersAchievement] = React.useState("")
    const [MemberCategory, setMemberCategory] = React.useState("")
    const [YearlyWorth, setYearlyWorth] = React.useState("")
    const [ProfilePicsUrl, setProfilePicsUrl] = React.useState("")
    const [lastTransactionWorth, setlastTransactionWorth] = React.useState("")

    //membership detail
    const [status, setStatus] = React.useState("")
    const [memId, setMemId] = React.useState("")

    const [done, setDone] = useState(false)

    useEffect(async ()=>{
            var firstName = ""
    if(currentMember){
        const docRef = doc(db, "Members", currentMember.user.uid);
      await getDoc(docRef).then(async(docs)=>{

          
          if(docs.exists()){
              const accountStatus=  await docs.get("AccountStatus");
              firstName = await docs.get("firstName")

              if(!firstName){
                  
                localStorage.removeItem("currentMember");
                history.push("/")
                          
              }else{
                  if(accountStatus == "Pending"){
                      //Account has not been apprved
                      localStorage.removeItem("currentMember");
                        history.push("/")
                  }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                      //Account has not been suspended or terminated
                      localStorage.removeItem("currentMember");
                      history.push("/")
                  }else if(accountStatus== "Active On Payment Confirmation"){
                          //Account has not been suspended or terminated
                          localStorage.removeItem("currentMember");
                          history.push("/")
                  }else{
                      //Lets first check if its active on Trial
                      var creationDate = docs.get("CreatedAt");
                      var date = new Date(creationDate * 1000).getDate();
                      var today = new Date();
                      var diffInDays = (today.getDate()- date)
                      
                      if(diffInDays > 30 && accountStatus=="Active On Trial"){
                        localStorage.removeItem("currentMember");
                        history.push("/")
                      }else{

                          setDone(true)
                        const documentRef = doc(db, "Members", id);
                        const memRef = doc(db, "MembershipDetails", id);

                        await getDoc(documentRef).then(async(members)=>{
                            if(members.exists()){
                                setFirstName(members.get("firstName"))
                                setLastName(await members.get("lastName"))
                                setDealToCloseInNextQuarter(await members.get("DealToCloseInNextQuarter"))
                                setMemberCategory(await members.get("MemberCategory"))
                                setMembersAchievement(await members.get("MembersAchievement"))
                                setProfilePicsUrl(await members.get("ProfilePicsUrl"))
                                setYearlyWorth(await members.get("YearlyWorth"))
                                setlastTransactionWorth(await members.get("lastTransactionWorth"))

                            }

                            await getDoc(memRef).then(async(mem)=>{
                                    if(mem.exists()){
                                        setStatus(await mem.get("MembershipStatus"))
                                        setMemId(await mem.get("MemberShipId"))
                                    }

                                    setDone(false)
                                    setIsLoading(false)
                            })
                        })
                      }

                      

                    }
                }
                
            
            }else{
                localStorage.removeItem("currentMember");
                        history.push("/")
                }
            }).catch(async(_)=>{
                localStorage.removeItem("currentMember");
                            history.push("/")
            })

                    
            
    }else{
        history.push("/")
    }
      
      
            
          },[])
          
          const classes = useStyles()
 
    return (
        <React.Fragment>
            <Header />
            <Grid item xs={12} className={classes.wrapper}>
                
                <Typography variant="h4" >Members' Profile</Typography>
                
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {
                        done ? <Grid align="center">
                        <ReactLoading type={"spin"} color={"green"} height={100} width={100} />
                    </Grid>
                        :
                        isLoading ? <CircularProgress /> :
                        <Grid>
                    <Typography variant="h6" align="left">Members' Information</Typography>
                    <Divider />
                    <Container>
                        <Row align="center" style={{marginBottom: "24px"}}>
                            <Col>
                                <Avatar src={ProfilePicsUrl} alt ="profile" sx={{ width: 80, height: 80 }} />
                                   
                            </Col>
                        </Row>
                        <Row>
                            <Col><Typography variant="inherit">First Name</Typography></Col>
                            <Col><Typography variant="inherit">{firstName}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Last Name</Typography></Col>
                            <Col><Typography variant="inherit">{lastName[0]+"***********"}</Typography></Col>
                        </Row>
                        
                        <Row style={{marginTop: "15px"}}>
                            <Col><Typography variant="h6" align="left">Personal Achievements</Typography></Col>
                        </Row>
                        <Divider />
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Deal To close in next Qtrs</Typography></Col>
                            <Col><Typography variant="inherit">{DealToCloseInNextQuarter+"USD"}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Member's Category</Typography></Col>
                            <Col><Typography variant="inherit">{MemberCategory}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Achievements</Typography></Col>
                            <Col><Typography variant="inherit">{MembersAchievement}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Last Transaction Worth</Typography></Col>
                            <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(lastTransactionWorth))}</Typography></Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">Yearly Transaction</Typography></Col>
                            <Col><Typography variant="inherit">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(YearlyWorth))}</Typography></Col>
                        </Row>
                       
                       
                        <Row style={{marginTop: "15px"}}>
                            <Col><Typography variant="h6" align="left">Membership Detail</Typography></Col>
                        </Row>
                        <Divider />
                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">MemberShip Id</Typography></Col>
                            <Col><Typography variant="inherit">{
                                memId.length==1 ? "INVESTOR00"+memId: memId.length==2 ? "INVESTOR0"+memId: "INVESTOR"+memId
                            }</Typography></Col>
                        </Row>

                        <Row style={{marginTop: "10px"}}>
                            <Col><Typography variant="inherit">MemberShip Status</Typography></Col>
                            <Col><Typography variant="inherit">{status}</Typography></Col>
                        </Row>

                        
                    </Container>
                    </Grid>
                    }
                   

                </Box>
            </Grid>
        </React.Fragment>
    )
}
