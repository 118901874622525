import React, { useCallback, useContext } from 'react'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography, CssBaseline} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { AppContext } from '../../../../registration/Context';

export default function SecondProjectInfo() {

    const paperStyle = {
        padding: 15,
        height: '70vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const {
        formValues,
        handleChange,
        handleBack,
        handleNext,
        variant,
        margin
      } = useContext(AppContext)
      const { typeOfPartners, partnersOffers, noOfPartners } = formValues

      // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
        Object.keys({ typeOfPartners, partnersOffers, noOfPartners  }).some(
            (name) =>
            (formValues[name].required && !formValues[name].value) ||
            formValues[name].error
        ),
        [formValues, typeOfPartners, partnersOffers, noOfPartners ]
    )
    return (
        <React.Fragment>
            <Grid item xs>
                <CssBaseline />
                <Paper elevation={10} style={paperStyle}>
                    <Grid align ="center">
                        <Avatar style= {avatarStyle}><CreateOutlinedIcon /></Avatar>
                        <h4><Typography>Project Information</Typography></h4>
                    </Grid>

                    <TextField
                        id="outlined-multiline-static"
                        label="What Type of Partners are you looking for?"
                        multiple
                        fullWidth
                        rows={4}
                        variant={variant}
                        margin={margin}
                        placeholder = "What type of partner(s) are you looking for?"
                        name="typeOfPartners"
                        value={typeOfPartners.value}
                        onChange={handleChange}
                        error={!!typeOfPartners.error}
                        helperText={typeOfPartners.error}
                        required={typeOfPartners.required}
                        
                    />

                    <TextField
                        id="outlined-multiline-static"
                        label="What must your partner(s) bring to this deal?"
                        multiple
                        fullWidth
                        rows={4}
                        variant={variant}
                        margin={margin}
                        placeholder = "What must your partner(s) bring to this deal?"
                        name="partnersOffers"
                        value={partnersOffers.value}
                        onChange={handleChange}
                        error={!!partnersOffers.error}
                        helperText={partnersOffers.error}
                        required={partnersOffers.required}
                        
                    />

                    <TextField
                        label="Numbers of Patner(s) required for this Project"
                        fullWidth
                        rows={4}
                        variant={variant}
                        margin={margin}
                        placeholder = "Numbers of Patner(s) required for this Project"
                        name="noOfPartners"
                        value={noOfPartners.value}
                        onChange={handleChange}
                        error={!!noOfPartners.error}
                        helperText={noOfPartners.error}
                        required={noOfPartners.required}
                        
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button onClick={handleBack} sx={{ mr: 1 }}>
                        Previous
                        </Button>
                        <Button
                        variant="contained"
                        disabled={isError()}
                        color="primary"
                        onClick={!isError() ? handleNext : () => null}
                        >
                        Next
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
