import { useContext } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import MembersProfile from './MembersProfile' //First step
import TransactionProfile from './TransactionProfile'; //second step
import VerificationProfile from './VerificationProfile'; //third step
import CompanyProfile from './CompanyProfile'
import Confirmation from './Confirm'
import AdditionalInfo from './AdditionalInfo'
// import Confirm from './Confirm'
import Success from './Success'
import { AppContext } from '../Context'

// Step titles
const labels = ['Personal', 'Achievement', 'Verification','Company', 'Confirmation','Submit']
const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <MembersProfile />
    case 1:
      return <TransactionProfile />
    case 2:
      return <VerificationProfile />
    case 3:
      return <CompanyProfile />
    case 4:
      return <Confirmation />
    case 5:
      return <AdditionalInfo />
    default:
      throw new Error('Unknown step')
      
  }
}

const StepForm = () => {
  const { activeStep } = useContext(AppContext)

  return (
    <>
      {activeStep === labels.length ? (
        <Success />
      ) : (
        <>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center">
              Complete Your Profile
            </Typography>
            <Typography variant="subtitle2" align="center" sx={{ mt: 2 }}>
              Your Profile completion is highly required
            </Typography>
          </Box>
          <Stepper activeStep={activeStep} sx={{ py: 3 }} alternativeLabel>
            {labels.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {handleSteps(activeStep)}
        </>
      )}
    </>
  )
}

export default StepForm
