import React from 'react'
import { Drawer, Box } from '@mui/material'
import SideNav from './SideNav';


export default function SideBar({mobileOpen, handleDrawerToggle}) {

    const drawerWidth = 250;
    
    return (
        <React.Fragment>
            <Box 
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="menu-item"
            >
                   
                <Drawer
                    
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, marginTop: "15px", marginLeft: "5px" },
                    }}
                    >
                    <SideNav />
                </Drawer>:

                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, marginTop: "73px", marginLeft: "10px" },
                    }}
                    open
                    
                    >
                    <SideNav />
                 </Drawer>
                
            </Box>
            
                
        </React.Fragment>
    )
}
