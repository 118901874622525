import React, {useEffect} from 'react'
import Header from '../components/Header'
import { Typography, Grid, Paper, IconButton, TextField, CircularProgress } from '@mui/material'
import {Container, Row, Col} from 'react-bootstrap'
import useStyles from '../components/HeaderStyles'
import SendIcon from '@mui/icons-material/Send';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import firebaseApp from '../../config/firebaseConfig'
import {getFirestore, getDoc, addDoc, collection, doc, serverTimestamp} from 'firebase/firestore'
import { useHistory } from 'react-router'

const db = getFirestore(firebaseApp);

export default function QuickPosts() {
    const classes = useStyles()
    var firstName = ""
    var lastName = ""
    var profilePics = ""

    const paperStyle = {
        padding: 20,
        width: "100%",
        height: "30vh",
        margin: "45px auto"
    }

    const [post, setPost] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false);

    const currentUser = JSON.parse(localStorage.getItem("currentMember"))
    const docRef = doc(db, "Members", currentUser.user.uid);
    const history = useHistory()

    useEffect(async ()=>{
        var firstName = ""

        if(currentUser){
            await getDoc(docRef).then(async(docs)=>{

      
                if(docs.exists){
                    const accountStatus=  await docs.get("AccountStatus");
                    firstName = await docs.get("firstName")
          
                    if(!firstName){
                        
                      localStorage.removeItem("currentMember");
                      history.push("/")
                                
                    }else{
                        if(accountStatus == "Pending"){
                            //Account has not been apprved
                            localStorage.removeItem("currentMember");
                              history.push("/")
                        }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                            //Account has not been suspended or terminated
                            localStorage.removeItem("currentMember");
                            history.push("/")
                        }else if(accountStatus== "Active On Payment Confirmation"){
                                //Account has not been suspended or terminated
                                localStorage.removeItem("currentMember");
                                history.push("/")
                        }else{
                            //Lets first check if its active on Trial
                            var creationDate = docs.get("CreatedAt");
                            var date = new Date(creationDate * 1000).getDate();
                            var today = new Date();
                            var diffInDays = (today.getDate()- date)
                            
                            if(diffInDays > 30 && accountStatus=="Active On Trial"){
                              localStorage.removeItem("currentMember");
                              history.push("/")
                            }else{
                              //do nothing
                            }
          
                          }
                      }
                      
                  
                  }else{
                      localStorage.removeItem("currentMember");
                              history.push("/")
                      }
                  }).catch(async(_)=>{
                      localStorage.removeItem("currentMember");
                                  history.push("/")
                  })
          
                          
                  
                  
        }else{
            history.push("/")
        }

  
    },[])

    const doQuickPosts = async () =>{
        if(!post){
            toast.error("Your Quick Post is required", {
                position: toast.POSITION.TOP_RIGHT
            })
        }else if(post.length > 1000){
            toast.error("Maximum length of 1000 exceeded", {
                position: toast.POSITION.TOP_RIGHT
            })
        }else{

            setIsLoading(true);
            
            
            await getDoc(docRef).then(async(docs)=>{
                if(docs.exists){
                    firstName = await docs.get("firstName");
                    lastName = await docs.get("lastName");
                    profilePics = await docs.get("ProfilePicsUrl");

                    await addDoc(collection(db, "QuickPosts"), {
                        "Posts": post,
                        "PostedByID": currentUser.user.uid,
                        "PostedByName": firstName + " "+lastName,
                        "PostedByProfilePics": profilePics,
                        "createdAt": serverTimestamp()
                      }).then(async (quickpost)=>{
                        await addDoc(collection(db, "Feeds"), {
                            "FeedStatus": "Open",
                            "FeedType": "Quick Post",
                            "FeedCreatorName": firstName + " "+lastName,
                            "FeedCreatorPics": profilePics,
                            "FeedTitle": "Added a quick post",
                            "QuickPostId": quickpost.id
                          }).then((_)=>{
                              setPost("")
                              setIsLoading(false);
                              toast.success("Your Post has been successfully submitted", {
                                  position: toast.POSITION.TOP_RIGHT
                              })
                          })
                      })

                }
            })
            
        }
    }

    return (
        <React.Fragment>
            <Header />
            <Grid className={classes.wrapper}>
                <Typography variant="h4" >Quick Posts</Typography>

                <Paper elevation={10} style={paperStyle}>
                    <Container>
                        <Row>
                            <Col xs = {8}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Quick Posts"
                                    multiline
                                    value={post}
                                    onChange = {(e)=>setPost(e.target.value)} 
                                    fullWidth
                                    rows={5}
                                    
                                />
                            </Col>
                            <Col xs={3} >
                                {
                                isLoading? <CircularProgress />
                                :<IconButton style={{marginTop: "auto"}}  onClick= {()=>doQuickPosts()}>
                                    <SendIcon fontSize="large" />
                                </IconButton>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Paper>

                <ToastContainer />
                
            </Grid>
            
        </React.Fragment>
    )
}
