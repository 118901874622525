import React, {useContext} from 'react'
import { Grid, TextField, Paper, Typography } from '@mui/material'
import { AppContext } from '../../../../registration/Context'

export default function Land() {

    const paperStyle = {
        padding: 15,
        height: '90vh',
        width: 500,
        margin: "25px auto"
    }

    const { formValues, handleChange, variant, margin } = useContext(
        AppContext
      )
      const { landSize, landLocation, landPriceWorth, titleOfDocument, structureOnLand, 
        landFreeFromReclamation, buildingPlan} = formValues

        

    return (
        <React.Fragment>
            <Grid>
                <Paper elevation={10} style= {paperStyle}>
                    <Grid align ="center">
                        
                        <h4><Typography>Land Options</Typography></h4>
                    </Grid>
                   
                    <TextField
                        variant="outlined"
                        margin={margin}
                        fullWidth
                        label="Your Land Size"
                        name="landSize"
                        placeholder="Your Land Size"
                        value={landSize.value}
                        onChange={handleChange}
                        
                        
                    />

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Your Land Location"
                        name="landLocation"
                        placeholder="Your Land Location"
                        value={landLocation.value}
                        onChange={handleChange}
                        error={!!landLocation.error}
                        helperText={landLocation.error}
                        required={landLocation.required}
                        
                    />

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Your Land Price Worth"
                        name="landPriceWorth"
                        placeholder="Amount in USD"
                        value={landPriceWorth.value}
                        onChange={handleChange}
                        error={!!landPriceWorth.error}
                        helperText={landPriceWorth.error}
                        required={landPriceWorth.required}
                        
                    />
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Your Land Title of Document"
                        name="titleOfDocument"
                        placeholder="Your Land Title of Document"
                        value={titleOfDocument.value}
                        onChange={handleChange}
                        error={!!titleOfDocument.error}
                        helperText={titleOfDocument.error}
                        required={titleOfDocument.required}
                        
                    />

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Any Structure on Land?"
                        name="structureOnLand"
                        value={structureOnLand.value}
                        onChange={handleChange}
                        error={!!structureOnLand.error}
                        helperText={structureOnLand.error}
                        required={structureOnLand.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Is This Land From Reclamation?"
                        name="landFreeFromReclamation"
                        value={landFreeFromReclamation.value}
                        onChange={handleChange}
                        error={!!landFreeFromReclamation.error}
                        helperText={landFreeFromReclamation.error}
                        required={landFreeFromReclamation.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        label="Type Of Structure you plan building on the land"
                        name="buildingPlan"
                        placeholder="Type Of Structure you plan building on the land"
                        value={buildingPlan.value}
                        onChange={handleChange}
                        error={!!buildingPlan.error}
                        helperText={buildingPlan.error}
                        required={buildingPlan.required}
                        
                    />

                    

                </Paper>
            </Grid>
        </React.Fragment>
    )
}
