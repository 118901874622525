import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import StepForm from '../component/StepForm';


const CompleteProfile = () => {

    const currentUser = JSON.parse(localStorage.getItem("current"))
          const history = useHistory();

          useEffect(()=>{
            if(!currentUser){
              history.push("/")
            }
          },[])
    return (
        <React.Fragment>
            
                <CssBaseline />
                
                <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
                <Grid item xs={12}>
                        <StepForm />
                    </Grid>
                   
                </Container>
            
        </React.Fragment>
    )
}

export default CompleteProfile
