import React, { useCallback, useContext } from 'react'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {AppContext} from '../Context.tsx';
import CssBaseline from '@mui/material/CssBaseline'


export default function MembersProfile(){
    const paperStyle = {
        padding: 15,
        height: '100vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const { formValues, handleChange, handleNext, variant, margin } = useContext(
        AppContext
      )
      const { firstName, lastName, phone, address, linkedIn } = formValues

      // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
        Object.keys({ firstName, lastName, phone, address, linkedIn }).some(
            (name) =>
            (formValues[name].required && !formValues[name].value) ||
            formValues[name].error
        ),
        [formValues, firstName, lastName, phone, address, linkedIn]
    )
    return (
        <Grid item xs={12}>
            <CssBaseline />
            <Paper elevation={10} style={paperStyle}>
                <Grid align ="center">
                    <Avatar style= {avatarStyle}><CreateOutlinedIcon /></Avatar>
                    <h4><Typography>Personal Information</Typography></h4>
                </Grid>
                <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="First Name"
                    name="firstName"
                    placeholder="Your first name"
                    value={firstName.value}
                    onChange={handleChange}
                    error={!!firstName.error}
                    helperText={firstName.error}
                    required={firstName.required}
                     
                />
                <TextField 
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    placeholder="Your last name"
                    value={lastName.value}
                    onChange={handleChange}
                    error={!!lastName.error}
                    helperText={lastName.error}
                    required={lastName.required}
                />
                <TextField 
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Phone"
                    name="phone"
                    placeholder="Your Phone Number"
                    value={phone.value}
                    onChange={handleChange}
                    error={!!phone.error}
                    helperText={phone.error}
                    required={phone.required}
                />
                <TextField 
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Address"
                    name="address"
                    placeholder="Your Home Address"
                    value={address.value}
                    onChange={handleChange}
                    error={!!address.error}
                    helperText={address.error}
                    required={address.required}
                />

                <TextField 
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="LinkedIn Profile"
                    name="linkedIn"
                    placeholder="Your LinkedIn Profile Url"
                    value={linkedIn.value}
                    onChange={handleChange}
                    error={!!linkedIn.error}
                    helperText={linkedIn.error}
                    required={linkedIn.required}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    disabled={isError()}
                    color="primary"
                    onClick={!isError() ? handleNext : () => null}
                    >
                        Next
                    </Button>
                </Box>
            </Paper>
        </Grid>
    )
}


