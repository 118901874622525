import React, {useEffect} from 'react'
import {useHistory, useParams, Link} from 'react-router-dom'
import { Typography, Grid, Box, IconButton, Button, CircularProgress, TextField, Avatar, Divider, List, ListItem,ListItemAvatar, ListItemText } from '@mui/material'
import {Container, Row, Col} from 'react-bootstrap'
import useStyles from '../components/HeaderStyles'
import SendIcon from '@mui/icons-material/Send';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import Header from '../components/Header'
import db from '../components/firebaseConfig'
import {getFirestore, getDoc, addDoc,setDoc, collection, doc, serverTimestamp} from 'firebase/firestore/lite'
import {getDocs,query, where,} from 'firebase/firestore/lite';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function QuickPostSection() {

    const [isLoading, setIsLoading] = React.useState(true)
    const currentMember = JSON.parse(localStorage.getItem("currentMember"))
    const history = useHistory()
    const {id} = useParams()

    const [PostedByID, setPostedByID] = React.useState("")
    const [PostedByName, setPostedByName] = React.useState("")
    const [PostedByProfilePics, setPostedByProfilePics] = React.useState("")
    const [Posts, setPosts] = React.useState("")
    const [done, setDone] = React.useState(false)
    const [comments, setComments] = React.useState([]);

    const fetchComments= async()=>{
        
    const response =collection(db, "QuickPosts", id, "Comments");
    const data= await getDocs(response);
    data.docs.forEach(item=>{
        setComments((prevState)=>{
            return [...prevState, item.data()]
        })
    })

    
    }

    const [fname, setFname] = React.useState("")
    const [lname, setLname] = React.useState("")
    
    const docRef = doc(db, "Members", currentMember.user.uid);
    useEffect(async ()=>{
        
if(currentMember){
    var firstName = "", lastName=""
    
  await getDoc(docRef).then(async(docs)=>{

      
      if(docs.exists()){
          const accountStatus=  await docs.get("AccountStatus");
          firstName = await docs.get("firstName")
          lastName = await docs.get("lastName")
          if(!firstName){
              
            localStorage.removeItem("currentMember");
            history.push("/")
                      
          }else{
              if(accountStatus == "Pending"){
                  //Account has not been apprved
                  localStorage.removeItem("currentMember");
                    history.push("/")
              }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                  //Account has not been suspended or terminated
                  localStorage.removeItem("currentMember");
                  history.push("/")
              }else if(accountStatus== "Active On Payment Confirmation"){
                      //Account has not been suspended or terminated
                      localStorage.removeItem("currentMember");
                      history.push("/")
              }else{
                  //Lets first check if its active on Trial
                  var creationDate = docs.get("CreatedAt");
                  var date = new Date(creationDate * 1000).getDate();
                  var today = new Date();
                  var diffInDays = (today.getDate()- date)
                  
                  if(diffInDays > 30 && accountStatus=="Active On Trial"){
                    localStorage.removeItem("currentMember");
                    history.push("/")
                  }else{
                      setDone(true)
                    const quickRef = doc(db, "QuickPosts", id);
                    await getDoc(quickRef).then(async(posts)=>{
                        if(posts.exists()){
                            setPostedByID(await posts.get("PostedByID"))
                            setPostedByName(await posts.get("PostedByName"))
                            setPostedByProfilePics(await posts.get("PostedByProfilePics"))
                            setPosts(await posts.get("Posts"))

                            fetchComments()

                            setDone(false)
                            setIsLoading(false)
                        }else{
                            setDone(false)
                            history.push("/")
                        }
                    })
                  }
                   
                }
            }
            
        
        }else{
            localStorage.removeItem("currentMember");
                    history.push("/")
            }
        }).catch(async(error)=>{
            
            localStorage.removeItem("currentMember");
                        history.push("/")
        })

                
        
}else{
    history.push("/")
}
  
  
},[])
const [openComment, setOpenComment] = React.useState(false)
const [comm, setComm] = React.useState("")

const [openInterest, setOpenInterest] = React.useState(false)
const [interest, setInterest] = React.useState("")

const closeOpenComment = () =>{
    setOpenComment(false)
}

const handleOpenComment = () =>{
    setOpenComment(true)
}

const closeOpenInterest = () =>{
    setOpenInterest(false)
}

const handleOpenInterest = async() =>{
    await getDoc(doc(db, "QuickPosts", id, "QuickPostInterests", currentMember.user.uid)).then((docs)=>{
        if(docs.exists()){
            toast.info("Your Interest has already been submitted for this post",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setOpenInterest(true)
        }
    })
    
}

const addComments = async() =>{
    if(!comm){
        toast.error("Your Comment cannot be empty",{
            position: toast.POSITION.TOP_RIGHT
        })
    }else{

        await getDoc(docRef).then(async(ref)=>{
            setFname(await ref.get("firstName"))
            setLname(await ref.get("lastName"))
        }).then(async(_)=>{
            await addDoc(collection(db,"QuickPosts", id, "Comments"), {
                "CommentorsID": currentMember.user.uid,
                "CommentorsName": fname +" "+lname,
                "Comments": comm,
                "QuickPostId": id,
                "CommentDate": serverTimestamp()
              }).then((_)=>{
                  setComm("")
                  closeOpenComment()
                  setComments([])
                  fetchComments()
                  toast.success("Comment successfully added",{
                      position: toast.POSITION.TOP_RIGHT
                  })
              }).catch((error)=>{
                setComm("")
                closeOpenComment()
                
                toast.error("Failed to post comment, please try later",{
                    position: toast.POSITION.TOP_RIGHT
                })
              })
        })
        
    }
}

const addInterests = async()=>{
    if(!interest){
        toast.error("Please indicate your offer to this post",{
            position: toast.POSITION.TOP_RIGHT
        })
    }else{
        await getDoc(doc(db, "QuickPosts", id, "QuickPostInterests", currentMember.user.uid)).then(async(docs)=>{
            if(docs.exists()){
                toast.info("Your Interest has already been submitted for this post",{
                    position: toast.POSITION.TOP_RIGHT
                })
                setInterest("")
                      closeOpenInterest()
            }else{
    
                await getDoc(docRef).then(async(docs)=>{
                    setFname(await docs.get("firstName"))
                    setLname(await docs.get("lastName"))
                }).then(async (_)=>{
                    await setDoc(doc(db,"QuickPosts", id, "QuickPostInterests", currentMember.user.uid), {
                        "MemberID": currentMember.user.uid,
                        "MembersName": fname +" "+lname,
                        "InterestOffer": interest,
                        "QuickPostId": id,
                        "CreatedDate": serverTimestamp()
        
                    }).then((_)=>{
                        setInterest("")
                      closeOpenInterest()
                        toast.success("Interest successfully added",{
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }).catch((error)=>{
                      setInterest("")
                      closeOpenInterest()
                      
                      toast.error("Failed to post Interest, please try later",{
                          position: toast.POSITION.TOP_RIGHT
                      })
                    })
                })
                
            }
        })
    }
}

const classes = useStyles()

const paperStyle = {
    padding: 20,
    width: "100%",
    height: "28vh",
    margin: "0px auto"
}
    return (
        <React.Fragment>
            <Header />
            <Grid className={classes.wrapper}>

                {
                    done ?<Grid align="center">
                    <ReactLoading type={"spin"} color={"green"} height={100} width={100} />
                </Grid>
                    :
                    isLoading ? <CircularProgress /> :
                    <Grid>

                <Dialog open={openComment} onClose={closeOpenComment}>
                  <DialogTitle>Your Comment</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    Fill in your comment here
                    </DialogContentText>

                    <TextField
                      autoFocus
                      margin="dense"
                      id="comment"
                      label="Your Comment"
                      fullWidth
                      value={comm}
                      onChange={(e)=>setComm(e.target.value)}
                      variant="standard"
                    />
                    
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={closeOpenComment}>Cancel</Button>
                  <Button endIcon={<SendIcon />} onClick={()=>addComments()}>Submit Comment</Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openInterest} onClose={closeOpenInterest}>
                  <DialogTitle>Your Offer to this Post</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    Fill in your Offer here
                    </DialogContentText>

                    <TextField
                      autoFocus
                      margin="dense"
                      id="offer"
                      label="Your Offer for this post"
                      fullWidth
                      value={interest}
                      onChange={(e)=>setInterest(e.target.value)}
                      variant="standard"
                    />
                    
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={closeOpenInterest}>Cancel</Button>
                  <Button endIcon={<SendIcon />} onClick={()=>addInterests()}>Submit Interest</Button>
                  </DialogActions>
                </Dialog>

                        <Box component="Paper">
                            <Container>
                                <Row>
                                    <Col xs={4} md={4} xl={4} lg={4} sm={4}>
                                      <Link to={"/members/profile/details/"+PostedByID} style={{textDecoration: "none"}}><Avatar src={PostedByProfilePics} alt ="profile" sx={{ width: 80, height: 80 }} /></Link>
                                    </Col>
                                    <Col  xs={8} md={8} xl={8} lg={8} sm={8}><Typography variant="inherit">{Posts}</Typography></Col>
                                </Row>
                                <Row align="center" style={{marginTop: "11px"}}> 
                                    <Col><Button variant="text" onClick={handleOpenComment}>Comment</Button></Col>
                                    <Col><Button variant="text" onClick={handleOpenInterest}>Show Interest</Button></Col>
                                </Row>
                                
                            </Container>
                        </Box>
                        <Divider />

                        <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', }} align="right">
                        {
                            comments.length !=0?
                        comments && comments.map((comment, index)=>(
                            <ListItem key={index} >
                                
                                    <ListItemText align="right" primary={comment.Comments} secondary={comment.CommentorsID==currentMember.user.uid?"You": comment.CommentorsName} />
                                <Divider />
                            </ListItem>
                        ))
                            
                        
                        :
                        <Grid align="center">
                                <Box>
                                    <Typography variant="inherit" align="right">No Comments available yet</Typography>
                                </Box>
                         </Grid>
                    }
                    </List>
                    
                    </Grid>
                }
                <ToastContainer />
            </Grid>
        </React.Fragment>
    )
}
