import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Paper, Button, Typography} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ProfileCompleted() {

    const paperStyle = {
        padding: 20,
        height: '50vh',
        width: 400,
        margin: "20px auto"
    }

    // const url ='https://docs.google.com/forms/d/e/1FAIpQLSeHgYgJvwxYjx381xnIA5xHbkQYOBRjTOIxDB0bcAmzhTow9Q/viewform?usp=sf_link';
    //     const message="This message is to confirm your membership application into the DEVCLUB360 Network.\n\nIf we approve your membership application, you will receive another notification email before you gain full access to the DEVCLUB360 mobile app and enjoy 30 days free trial accessing deals connection.\n\nNow that you have submitted your membership application, we need to have your expression of interest as a final stage required for your approval.\n\nClick on the link below to submit your expression of interest:\n\n"+url+"\n\nThank You!\n\n\n\nDEVCLUB360 Team.";
    //     const subject="Successful Devclub360 Membership Registration";
    //     const messageAdmin= "A new member has registered with "+currentUser.user.email+" on the platform and awaiting approval.";
    //     const subjectAdmin = "Successful Devclub360 Membership Registration"; 
         
    return (
        <React.Fragment>
            <Grid item xs={12}>
                
                <Grid>
                <Paper elevation={10} style= {paperStyle}>
                    <Grid align ="center">
                        <Typography variant="h5">Your Profile has now been completed</Typography>
                        <Typography variant="h6">Enjoy using Devclub360 for the next 30 days for free ...</Typography>
                        <Link to="/" style={{textDecoration: "none"}}>
                        <Button 
                            style={{marginTop:"15px", marginBottom: "10px"}} 
                            color= "success" 
                            fullWidth
                            variant="contained">
                          Back to Login
                    </Button>
                        </Link>
                    
                    </Grid>
                    
                </Paper>
                <ToastContainer />
                </Grid>
                
            </Grid>
        </React.Fragment>
    )
}
