import React from 'react'
import {Avatar, Grid, Paper, TextField, Button, Typography} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import firebaseApp from '../config/firebaseConfig'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';

const ForgotPassword = () => {
    const paperStyle = {
        padding: 20,
        height: '50vh',
        width: 300,
        margin: "20px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }

    const [done, setDone] = React.useState(false);
    const [email, setEmail] = React.useState("")
    const postEmail = ()=>{
        if(!email){
            toast.error("Your Registered email is required",{
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setDone(true)
            const auth = getAuth(firebaseApp);
            sendPasswordResetEmail(auth, email)
            .then(() => {

                setDone(false)
                setEmail("")
                toast.success("Password reset email has been sent to "+email,{
                    position: toast.POSITION.TOP_RIGHT
                })

            })
            .catch((error) => {
                setDone(false)
                toast.error(error.code,{
                    position: toast.POSITION.TOP_RIGHT
                })
            });
        }
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Paper elevation = {10} style= {paperStyle}>
                    {
                        done ? 
                        <Grid align="center">
                            <ReactLoading type={"spin"} color={"green"} height={100} width={100} />
                        </Grid>
                        :
                        <Grid>
                        <Grid align ="center">
                            <Avatar style= {avatarStyle}><LockOutlinedIcon /></Avatar>
                            <h2>Forgot Password</h2>
                        </Grid>
                        <TextField 
                            id="standard-basic" 
                            label="Email" 
                            type="email" 
                            required 
                            placeholder = "Enter valid email" 
                            variant="standard" 
                            fullWidth 
                            value={email}
                            onChange = {(e)=>setEmail(e.target.value)}
                        />
                        
                        
                        <Button onClick={()=>postEmail()} style={{paddingTop: "7px", marginTop:"10px"}} color= "primary" fullWidth variant="contained">Submit</Button>
                        
                        

                        <Typography style={{paddingTop: "10px"}} align="center">
                            Ensure you enter your registered email address
                            
                        </Typography>
                    </Grid>
                    }
                    <ToastContainer />
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default ForgotPassword
