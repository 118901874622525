import React, {useContext} from 'react'
import { Grid, TextField, Paper, Typography } from '@mui/material'
import { AppContext } from '../../../../registration/Context'

export default function Funding() {

    const paperStyle = {
        padding: 15,
        height: '30vh',
        width: 500,
        margin: "25px auto"
    }

    const { formValues, handleChange, variant, margin } = useContext(
        AppContext
      )
      const { amountOfInvestment } = formValues
    return (
        <React.Fragment>
            <Grid>
                <Paper elevation={10} style= {paperStyle}>
                        <Grid align ="center">
                            
                            <h4><Typography>Funding Options</Typography></h4>
                        </Grid>
                        <TextField
                            variant={variant}
                            margin={margin}
                            fullWidth
                            label="Amount You plan to invest in this deal"
                            name="amountOfInvestment"
                            placeholder="Amount in Dollars"
                            value={amountOfInvestment.value}
                            onChange={handleChange}
                            error={!!amountOfInvestment.error}
                            helperText={amountOfInvestment.error}
                            required={amountOfInvestment.required}
                            
                        />
                        
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
