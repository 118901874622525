import React, {useContext} from 'react'
import { Grid, TextField, Paper, Typography } from '@mui/material'
import { AppContext } from '../../../../registration/Context'

export default function OffTakers() {

    const paperStyle = {
        padding: 15,
        height: '30vh',
        width: 500,
        margin: "25px auto"
    }

    const { formValues, handleChange, variant, margin } = useContext(
        AppContext
      )
      const { offTaker } = formValues
    return (
        <React.Fragment>
            <Grid>
                <Paper elevation={10} style= {paperStyle}>
                        <Grid align ="center">
                            
                            <h4><Typography>Off-Takers Options</Typography></h4>
                        </Grid>
                        <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Are Your Off-Takers Guranteed?"
                        name="offTakers"
                        value={offTaker.value}
                        onChange={handleChange}
                        error={!!offTaker.error}
                        helperText={offTaker.error}
                        required={offTaker.required}
                    >
                        <option value=""> </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </TextField>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
