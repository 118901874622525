import React from 'react'
import Land from './Land'
import OffTakers from './OffTakers'

export default function LanOff() {
    return (
        <React.Fragment>
            <Land />
            <OffTakers />
        </React.Fragment>
    )
}
