import React from 'react'
import Investors from './Investors'
import OffTakers from './OffTakers'

export default function InvOff() {
    return (
        <React.Fragment>
            <Investors />
            <OffTakers />
        </React.Fragment>
    )
}
