import React from 'react'
import Funding from './Funding'
import OffTakers from './OffTakers'

export default function FunOff() {
    return (
        <React.Fragment>
            <Funding />
            <OffTakers />
        </React.Fragment>
    )
}
