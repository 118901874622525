import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Paper, Button, Typography} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import firebaseApp from '../../config/firebaseConfig';
import { getAuth, sendEmailVerification, onAuthStateChanged } from "firebase/auth";

const auth = getAuth(firebaseApp)

export default function ResendVerification() {

    const paperStyle = {
        padding: 20,
        height: '50vh',
        width: 400,
        margin: "20px auto"
    }

    const verify = JSON.parse(localStorage.getItem("verify"))
          const history = useHistory();

          useEffect(()=>{
            if(!verify){
              history.push("/")
            }
          },[])

          const [done, setDone] = React.useState(undefined);

          const reSend = () =>{
              setDone(true);

              onAuthStateChanged(auth, (user) => {
                if (user) {
                  sendEmailVerification(auth.currentUser).then((_)=>{
                   
                    setDone(undefined)
                    localStorage.removeItem("verify");

                    history.push("/")

                    
                  }).catch((error)=>{
                    setDone(undefined)
                    toast.error("An error has occured...Please contact our support team if this persists...", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                      
                  })
                  
                } else {
                    
                    setDone(undefined)
                  toast.error("An error has occured...Please contact our support team if this persists...", {
                      position: toast.POSITION.TOP_RIGHT
                  });
                }
              });
           
          }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                {
                    done? <Grid align="center">
                    <ReactLoading type={"bars"} color={"green"} height={200} width={200} />
                </Grid>:
                <Grid>
                <Paper elevation={10} style= {paperStyle}>
                    <Grid align ="center">
                        <Typography variant="h5">Your Email Address has not been verified</Typography>
                        <Typography variant="h6">Please verify your email ...</Typography>
                        <Button 
                            style={{marginTop:"15px", marginBottom: "10px"}} 
                            color= "success" 
                            fullWidth
                            onClick ={()=>reSend()} 
                            variant="contained">
                           Resend Verification Link
                    </Button>
                    <Link to="/" style={{textDecoration: "none"}}>Back to Login</Link>
                    </Grid>
                    <ToastContainer />
                </Paper>
                
                </Grid>
                }
            </Grid>
        </React.Fragment>
    )
}
