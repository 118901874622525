export const initialState = null

export const reducers = (state, action)=>{
    if(action.type === "USER"){
        return action.payload
    }else if(action.type === "SIGNUP"){
        return action.payload
    }else if(action.type === "DealConnect"){
        return action.payload
    }
    return state
}