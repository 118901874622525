import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Paper, Button, Typography} from '@mui/material';

export default function Sucess() {

    const paperStyle = {
        padding: 20,
        height: '50vh',
        width: 400,
        margin: "20px auto"
    }

    const currentUser = JSON.parse(localStorage.getItem("current"))
          const history = useHistory();

          useEffect(()=>{
            if(!currentUser){
              history.push("/")
            }
          },[])

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Paper elevation={10} style= {paperStyle}>
                    <Grid align ="center">
                        <Typography variant="h5">Your Account has been Provisioned Successfully</Typography>
                        <Typography variant="h6">A verification Email has been sent to you. Verifying your email is required to login... Kindly click the button below to complete your profile</Typography>
                        <Link to="/members/create-profile" style={{textDecoration:"none"}}><Button 
                            style={{marginTop:"10px"}} 
                            color= "success" 
                            fullWidth 
                            variant="contained">
                            Complete Your Profile
                    </Button></Link>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
