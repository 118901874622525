import React, {useEffect} from 'react'
import Header from '../components/Header'
import { Typography, Grid, Paper, Divider, Avatar, TextField, Button, Dialog, DialogContentText, DialogActions, DialogTitle, DialogContent, CircularProgress } from '@mui/material'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useStyles from '../components/HeaderStyles'
import PaymentsIcon from '@mui/icons-material/Payments';
import {Container, Col, Row} from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import firebaseApp from '../../config/firebaseConfig'
import {getFirestore, getDoc,  collection, setDoc, doc, updateDoc, serverTimestamp} from 'firebase/firestore'
import { useHistory, Link } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';

const db = getFirestore(firebaseApp);

export default function MembershipLicense() {
    const classes = useStyles()

    const history = useHistory()

    const [method, setMethod] = React.useState('');

    const [nairaPrice, setNairaPrice]= React.useState(0);
    const [dollarPrice, setDollarPrice] = React.useState(0);

    const [couponAmount, setCouponAmount]= React.useState(0);
    const [couponPercent, setCouponPercent] = React.useState(0);

    const [openTransfer, setOpenTransfer] = React.useState(false);
    const [openCard, setOpenCard] = React.useState(false)

    const [isLoading, setIsLoading] = React.useState(false)
    const [couponIsUsed, setCouponIsUsed] = React.useState(false)

    const [couponCode, setCouponCode] = React.useState("");

  const handleChange = (event) => {
    setMethod(event.target.value);
  };

  const proceed= ()=>{
    if(method == "Pay With Transfer"){
        setOpenTransfer(true)
        setOpenCard(false)
    }else if(method == "Pay With PayStack") {
        setOpenTransfer(false)
        setOpenCard(true)
    }else{
        toast.error("Please choose a payment method",{
            position: toast.POSITION.TOP_RIGHT
        })
        setOpenTransfer(false)
        setOpenCard(false)

    }
  }

    const paperStyle = {
        padding: 20,
        height: '75vh',
        width: 400,
        margin: "20px auto"
    } 

    const paperStyle2 = {
        padding: 20,
        height: '28vh',
        width: 400,
        margin: "45px auto"
    } 

    

    const handleTransferClose = ()=>{
        setOpenTransfer(false)
    }

    const handleCardClose = ()=>{
        setOpenCard(false)
    }

    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }

    const currentUser = JSON.parse(localStorage.getItem("currentMember"))
            const docRef = doc(db, "Prices", "DevClubMembershipFee");
            const [show, setShow] = React.useState(true);
            
            useEffect(async ()=>{
                var firstName = ""
                
                if(currentUser){

                    const checkPayment = doc(db, "MembershipPayments", currentUser.user.uid);
                            await getDoc(checkPayment).then((docs)=>{
                                if(docs.exists()){
                                    setShow(false)
                                }else{
                                    setShow(true)
                                }
                            })
                    const docReference = doc(db, "Members", currentUser.user.uid);
                    await getDoc(docReference).then(async(docs)=>{
                        
                        if(docs.exists()){
                            const accountStatus=  await docs.get("AccountStatus");
                            firstName = await docs.get("firstName")
              
                            if(!firstName){
                                
                              localStorage.removeItem("currentMember");
                              history.push("/")
                                        
                            }else{
                                if(accountStatus == "Pending"){
                                    //Account has not been apprved
                                    localStorage.removeItem("currentMember");
                                      history.push("/")
                                }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                                    //Account has not been suspended or terminated
                                    localStorage.removeItem("currentMember");
                                    history.push("/")
                                }else if(accountStatus== "Active On Payment Confirmation"){
                                        //Account has not been suspended or terminated
                                        localStorage.removeItem("currentMember");
                                        history.push("/")
                                }else{
                                    //Lets first check if its active on Trial
                                    var creationDate = docs.get("CreatedAt");
                                    var date = new Date(creationDate * 1000).getDate();
                                    var today = new Date();
                                    var diffInDays = (today.getDate()- date)
                                    
                                    if(diffInDays > 30 && accountStatus=="Active On Trial"){
                                      localStorage.removeItem("currentMember");
                                      history.push("/")
                                    }else{
                                      getDoc(docRef).then(async(docs)=>{
                                          if(docs.exists){
                                              const ngn = await docs.get("MembershipFeeInNaira")
                                              const usd = await docs.get("MembershipFeeInDollars")
                                              setNairaPrice(parseInt(ngn))
                                              setDollarPrice(parseInt(usd))
                                          }
                                       })
                                    }
              
                                  }
                              }
                              
                          
                          }else{
                              localStorage.removeItem("currentMember");
                                      history.push("/")
                              }
                          }).catch(async(_)=>{
                              localStorage.removeItem("currentMember");
                                          history.push("/")
                          })
              
                                  
                          
                          
                }else{
                    history.push("/")
                }
          
         
            },[])

            const [open, setOpen] = React.useState(false);

            const handleClickOpen = () => {
                setOpen(true);
              };
            
              const handleClose = () => {
                setOpen(false);
              };

    
    const TransferOption = async ()=>{
        handleClickOpen()
    }

    const handleProceedWithTransfer = async ()=>{
        setIsLoading(true);
        

        await setDoc(doc(db, "MembershipPayments", currentUser.user.uid), {
            "uid": currentUser.user.uid,
            "AmountPaid": nairaPrice.toString(),
            "PaidStatus": "Awaiting Confirmation",
            "Transaction_date": serverTimestamp(),
            "Transaction_Reference": "PlatformWeb"+(new Date()).getTime().toString(),
            "CouponIsUsed": couponIsUsed,
            "CouponCode": couponCode
        }).then(async (_)=>{

            await updateDoc(doc(db, "Members", currentUser.user.uid), {
                AccountStatus: "Active On Payment Confirmation"
              }).then((_)=>{
                
                setIsLoading(false)
                handleClose();
    
                setTimeout(() => {
                    toast.success("Your Process has been successfully carried out, we will confirm your payment and get back to you",{
                        position: toast.POSITION.TOP_RIGHT
                    })
                }, 5000);

                history.push("/members/dashboard")
                
              });
            
        })

        
    }

    const config = {
        reference: (new Date()).getTime().toString(),
        email: currentUser.user.email,
        amount: parseInt(nairaPrice * 100),
        publicKey: 'pk_live_28ef2a49b82485d5040a5434f36c264faac08964',
    };

    
  const onSuccess = async(reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    await setDoc(doc(db, "MembershipPayments", currentUser.user.uid), {
        "uid": currentUser.user.uid,
        "AmountPaid": nairaPrice.toString(),
        "PaidStatus": "Completed",
        "Transaction_date": serverTimestamp(),
        "Transaction_Reference": "PlatformWeb"+(new Date()).getTime().toString(),
        "CouponIsUsed": couponIsUsed,
        "CouponCode": couponCode
    }).then(async (_)=>{

        await updateDoc(doc(db, "Members", currentUser.user.uid), {
            AccountStatus: "Active"
          }).then((_)=>{
            setIsLoading(false)
            handleClose();

            fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                    method: "post",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                      name: "Devcluber",
                      email: currentUser.user.email,
                      subject: "Devclub Membership License Payment Successful",
                      message: "<p>Welcome to the DEVCLUB360.</p><p> First and foremost, we want to thank you for your investment. Without your loyalty and support, we would not be able to continuously provide our network with valuable benefits and actively develop the world of real estate.</p><p> Put simply, you make what we do possible.</p><p>We want to see you benefit from your membership. If not, let us fix that right away!</p><p> Call or email the DEVCLUB360 to see how we may better meet your needs.</p><p>Beyond the sweet deals available on the DEVCLUB360, you will have your own personal board of directors, meet new high net worth friends through our several events like the Quarterly Board Room Meetings, Boat Cruises, and yearly Portfolio Defense Meetings.</p><p>If, like most of our members, you are satisfied with your membership, consider introducing your highly placed friends to the DEVCLUB360.</p><p>Kind regards<br />ONAIFO UWA<br />Founder/CEO</p>",
                      adminEmail: "payments@devclub360.com",
                      adminSubject: "New Successful Member Registration",
                      adminMessage: "<p>This is to notify you that a member with email "+currentUser.user.email+" has obtained a Membership License.</p>"
                    })
                  }).then(res=>res.json())
                  .then(data=>{
                    console.log(data)
                  })

            history.push("/members/dashboard")
            
          });
        
    })
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

    const processCoupon = async ()=>{
        if(!couponCode){
            toast.error("Coupon Code is required", {
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            setIsLoading(true)
            var code = ""
            const docRef = doc(db, "Coupons", couponCode);

            await getDoc(docRef).then(async (docs)=>{
                if(docs.exists){
                    code = docs.get("Coupons")

                    if(!code){
                        setIsLoading(false)
                        toast.info("Coupon code does not exist",{
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }else{
                        var couAmount = await docs.get("AmountInNaira")
                    var couDollarAmount = await docs.get("AmountInDollars")
                    var percent = await docs.get("Percent");
                    var noOfUsed = await docs.get("NumberOfTimesUsed")
                    var expirationDate = docs.get("Expiration");
                    var date = new Date(expirationDate * 1000).getDate();
                    var today = new Date();
                    var diffInDays = (date - today.getDate())
                    console.log(diffInDays.toString())
                    if(diffInDays <= 0){
                        setIsLoading(false)
                        toast.info("Coupon Code has expired for use", {
                            position: toast.POSITION.TOP_RIGHT
                        })
                       
                    }else{
                        setCouponAmount(couAmount)
                        setCouponPercent(percent)
                        setCouponIsUsed(true);

                        setNairaPrice(parseInt(nairaPrice)-parseInt(couAmount))
                        setDollarPrice(parseInt(dollarPrice) - parseInt(couDollarAmount))

                        setIsLoading(false)

                        const updateRef = doc(db, "Coupons", couponCode);

                        await updateDoc(updateRef, {
                            NumberOfTimesUsed: noOfUsed + 1
                          }).then((_)=>{
                            toast.success("Coupon code has been applied successfully",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                          })

                        
                    }
                    }
                    

                    
                }else{
                    toast.info("Coupon code does not exist",{
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            }).catch((error)=>{
                toast.error(error.message,{
                    position: toast.POSITION.TOP_RIGHT
                })
            })
        }
    }

    const componentProps = {
        ...config,
        text: 'Pay With PayStack',
        onSuccess: (reference) => onSuccess(reference),
        onClose: onClose(),
    };
    
    return (
        <React.Fragment>

            <Grid>
                {
                  !show ? 
                  <Grid align="center">
                      <Paper elevation={10} style={paperStyle2}>
                        <Grid align ="center">
                            <Typography variant="h3">Thank You!</Typography>
                            <Typography variant="h6">A membership fee payment has been discovered </Typography>
                            <Link to="/members/dashboard" style={{textDecoration: "none"}}>
                            <Button 
                                style={{marginTop:"15px", marginBottom: "10px"}} 
                                color= "success" 
                                fullWidth
                                variant="contained">
                            Go to Your Dashboard
                        </Button>
                            </Link>
                        
                        </Grid>
                      </Paper>
                  </Grid> 
                  :  
                <Grid>
               <Grid style={{margin: "20px 35px"}}>
                <Typography variant="h5" >Membership License</Typography>
               </Grid>
                <Divider />

                <Paper elevation={10} style={paperStyle}>
                    <Grid align="center">
                        <Avatar style={avatarStyle}>
                            <PaymentsIcon />
                        </Avatar>
                        <Typography variant="h6" >Payment Portal</Typography>
                        <Divider />
                        
                    </Grid>
                    <Container>
                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Membership License in Dollars</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{"USD"+dollarPrice}</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Membership License in Naira</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{"NGN"+nairaPrice}</Typography></Col>
                            </Row>

                            <Row>
                                <Grid align="center" style={{marginBottom: "18px"}}>
                                    {
                                        couponIsUsed ? <Typography variant="inherit" ><b>coupon applied...Proceed to payment</b></Typography>
                                        : <Typography variant="inherit" >Have a Coupon code?</Typography>
                                    }
                                    
                                </Grid>
                            </Row>

                            <Row>
                                {
                                    couponIsUsed ? null :
                                    <Col>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Coupon code" 
                                        variant="outlined" 
                                        fullWidth
                                        value={couponCode}
                                        onChange= {(e)=>setCouponCode(e.target.value)}
                                        
                                    />
                                </Col>
                                }
                                <Col>
                                    {
                                       couponIsUsed ? null :  isLoading ? <CircularProgress size={25} />: 
                                       <Button variant="contained" color="primary" fullWidth onClick={()=>processCoupon()}>
                                           Apply
                                       </Button>
                                    }
                                </Col>
                            </Row>
                    <Row>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Payment Methods</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={method}
                                label="Payment Methods"
                                onChange={handleChange}
                                style={{marginTop: "15px"}}
                                >
                                <MenuItem value="Pay With Transfer">Pay With Transfer</MenuItem>
                                <MenuItem value="Pay With PayStack">Pay With Card</MenuItem>
                                
                                </Select>
                            </FormControl>
                        </Box>
                    </Row>
                        <Row>
                        <Col>
                                    <Button onClick={()=>proceed()} style={{marginTop:"15px"}} variant="contained" color="primary" fullWidth>
                                        Procced
                                    </Button>
                                </Col>
                        </Row>
                    </Container>

                    <Dialog open={openTransfer} onClose={handleTransferClose}>
                    <DialogTitle>Pay With Transfer Option</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Suitable for members in other countries.
                    </DialogContentText>
                    <Container>
                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Subtotal</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(nairaPrice)+"(USD"+new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dollarPrice)+")"}</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Coupon Reduction Amount</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(couponAmount)+"("+couponPercent+"%)"}</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Total</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(nairaPrice)+"(USD"+new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dollarPrice)+")"}</Typography></Col>
                            </Row>

                            <Grid align="center" style={{marginTop: "12px", marginBottom: "15px"}}>
                                <Typography variant="inherit">Devclub360 Account Details</Typography>
                            </Grid>

                            <Row>
                                <Col><Typography align="left" variant="inherit">Account Name</Typography></Col>
                                <Col><Typography align="right" variant="inherit">MORTGOGENIE PROPERTY COMPANY</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" variant="inherit">Account Number</Typography></Col>
                                <Col><Typography align="right" variant="inherit">0037514124</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" variant="inherit">Bank Name</Typography></Col>
                                <Col><Typography align="right" variant="inherit">Stanbic Bank</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" variant="inherit">Transfer into this account and click on Proceed to Continue</Typography></Col>
                                
                            </Row>

                        </Container>
                        
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" fullwidth variant="contained" onClick={()=>TransferOption()}>Proceed after Transfer</Button>
                    
                    </DialogActions>
                </Dialog>


                <Dialog open={openCard} onClose={handleCardClose}>
                    <DialogTitle>Pay With Card Option</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Suitable for members in Nigeria, Ghana and South Africa.
                    </DialogContentText>
                    <Container>
                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Subtotal</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(nairaPrice)+"(USD"+new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dollarPrice)+")"}</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Coupon Reduction Amount</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(couponAmount)+"("+couponPercent+"%)"}</Typography></Col>
                            </Row>

                            <Row>
                                <Col><Typography align="left" style={{margin: "10px 12px"}} variant="inherit" >Total</Typography></Col>
                                <Col><Typography align="right" style={{margin: "10px 12px"}} variant="inherit" >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(nairaPrice)+"(USD"+new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dollarPrice)+")"}</Typography></Col>
                            </Row>

                        </Container>
                        
                    </DialogContent>
                    <DialogActions>
                    <PaystackButton {...componentProps} />
                    
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Confirm That you have transferred"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By Clicking on proceed, we assume that you have made a transfer of {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dollarPrice)} to the account provisioned.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {
                            isLoading ? null : <Button onClick={handleClose}>Cancel</Button>
                        }
                    
                    {
                        isLoading ? <CircularProgress /> :
                        <Button onClick={()=>handleProceedWithTransfer()} autoFocus>
                            Proceed
                        </Button>
                    }
                    </DialogActions>
                </Dialog>
                </Paper>

                <ToastContainer />
                </Grid>
                }
            </Grid>
            
            
        </React.Fragment>
    )
}
