import React, {useContext} from 'react'
import { Grid, TextField, Paper, Typography } from '@mui/material'
import { AppContext } from '../../../../registration/Context'

export default function Investors() {

    const paperStyle = {
        padding: 15,
        height: '40vh',
        width: 500,
        margin: "25px auto"
    }

    const { formValues, handleChange, variant, margin } = useContext(
        AppContext
      )
      const { amountOfInvestmentByInvestor, expectedROI } = formValues
    return (
        <React.Fragment>
            <Grid>
                <Paper elevation={10} style= {paperStyle}>
                        <Grid align ="center">
                            
                            <h4><Typography>Investors Options</Typography></h4>
                        </Grid>
                        <TextField
                            variant={variant}
                            margin={margin}
                            fullWidth
                            label="Amount of Investment By Investor in Dollars"
                            name="amountOfInvestmentByInvestor"
                            placeholder="Amount of Investment By Investor in Dollars"
                            value={amountOfInvestmentByInvestor.value}
                            onChange={handleChange}
                            error={!!amountOfInvestmentByInvestor.error}
                            helperText={amountOfInvestmentByInvestor.error}
                            required={amountOfInvestmentByInvestor.required}
                            
                        />

                        <TextField
                            variant={variant}
                            margin={margin}
                            nultiple
                            row={4}
                            fullWidth
                            label="Expected Return On Investment"
                            name="expectedROI"
                            placeholder="Amount of Investment By Investor in Dollars"
                            value={expectedROI.value}
                            onChange={handleChange}
                            error={!!expectedROI.error}
                            helperText={expectedROI.error}
                            required={expectedROI.required}
                            
                        />
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
