import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Paper, Button, Typography} from '@mui/material';

export default function DealConnectSuccess() {

    const paperStyle = {
        padding: 20,
        height: '50vh',
        width: 400,
        margin: "20px auto"
    }

    const currentUser = JSON.parse(localStorage.getItem("currentMember"))
          const history = useHistory();

          useEffect(()=>{
            if(!currentUser){
              history.push("/")
            }
          },[])

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Paper elevation={10} style= {paperStyle}>
                    <Grid align ="center">
                        <Typography variant="h5">Your Deal has been Created Successfully</Typography>
                        <Typography variant="h6">Your deal will now undergo further analysis for approval. You will be notified when analysis has been completed</Typography>
                        <Link to="/members/dashboard" style={{textDecoration:"none"}}><Button 
                            style={{marginTop:"10px"}} 
                            color= "success" 
                            fullWidth 
                            variant="contained">
                            Back to Dashboard
                    </Button></Link>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
