import React, { useCallback, useContext } from 'react'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {AppContext} from '../Context.tsx';
import CssBaseline from '@mui/material/CssBaseline'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

export default function VerificationProfile(){
    const paperStyle = {
        padding: 15,
        height: '100vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const {
        formValues,
        handleChange,
        handleBack,
        handleNext,
        variant,
        margin
      } = useContext(AppContext)
      const { NIN, driverLicense, VIN, CAC, TIN } = formValues

      // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
        Object.keys({ NIN, driverLicense, VIN, CAC, TIN }).some(
            (name) =>
            (formValues[name].required && !formValues[name].value) ||
            formValues[name].error
        ),
        [formValues, NIN, driverLicense, VIN, CAC, TIN]
    )
    return (
        <React.Fragment>
            <Grid item xs={12}>
            <CssBaseline />
            <Paper elevation={10} style={paperStyle}>
                <Grid align ="center">
                    <Avatar style= {avatarStyle}><CreateOutlinedIcon /></Avatar>
                    <h4><NotificationsActiveOutlinedIcon /><Typography>Want a Verified Account?</Typography></h4>
                    
                    <h6><Typography>All fields are completely optional.</Typography></h6>

                </Grid>
                <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="National Identification Number"
                    name="NIN"
                    placeholder="NIN"
                    value={NIN.value}
                    onChange={handleChange}
                    error={!!NIN.error}
                    helperText={NIN.error}
                    required={NIN.required}
                     
                />
                <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Driver's License Identfication Number"
                    name="driverLicense"
                    placeholder="Driver License"
                    value={driverLicense.value}
                    onChange={handleChange}
                    error={!!driverLicense.error}
                    helperText={driverLicense.error}
                    required={driverLicense.required}
                     
                />
                <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Voter's Identfication Number"
                    name="VIN"
                    placeholder="VIN"
                    value={VIN.value}
                    onChange={handleChange}
                    error={!!VIN.error}
                    helperText={VIN.error}
                    required={VIN.required}
                     
                />

                <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="CAC Identfication Number"
                    name="CAC"
                    placeholder="CAC ID"
                    value={CAC.value}
                    onChange={handleChange}
                    error={!!CAC.error}
                    helperText={CAC.error}
                    required={CAC.required}
                     
                />
                <TextField
                    variant={variant}
                    margin={margin}
                    fullWidth
                    label="Tax Identfication Number"
                    name="TIN"
                    placeholder="TIN"
                    value={TIN.value}
                    onChange={handleChange}
                    error={!!TIN.error}
                    helperText={TIN.error}
                    required={TIN.required}
                     
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button onClick={handleBack} sx={{ mr: 1 }}>
                        Previous
                        </Button>
                        <Button
                        variant="contained"
                        disabled={isError()}
                        color="primary"
                        onClick={!isError() ? handleNext : () => null}
                        >
                        Next
                        </Button>
                </Box>
            </Paper>
            </Grid>
        </React.Fragment>
    )
}


