import { useContext } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import ProjectInfo from './ProjectInfo'
import SecondProjectInfo from './SecondProjectInfo'
import ProjectOffer from './ProjectOffer'
import ProjectConfirmation from './ProjectConfirmation'
import Success from './Success'
import { AppContext } from '../../../../registration/Context'
import {Container, Row, Col} from 'react-bootstrap'


const labels = ['Project Info', 'Complete Project Information', 'Confirmation', 'Project Offers and Images']
const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <ProjectInfo />
    case 1:
      return <SecondProjectInfo />
    case 2:
        return <ProjectConfirmation />
    case 3:
      
      return <ProjectOffer />
    default:
      throw new Error('Unknown step')
      
  }
}

const DealConnectForm = ()=>{
    const { activeStep } = useContext(AppContext)

    return (
      <>
        {activeStep === labels.length ? (
          <Success />
        ) : (
          <>
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
            <Box sx={{ my: 5 }}>
              <Typography variant="h4" align="center">
                Create New Deal
              </Typography>
              <Typography variant="subtitle2" align="center" sx={{ mt: 2 }}>
                Deals created are subjected to approval before being relased for applications
              </Typography>
            </Box>
            
            <Stepper activeStep={activeStep} sx={{ py: 3 }} alternativeLabel>
              {labels.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
  
            {handleSteps(activeStep)}
            </Col>
            </Row>
            </Container>
          </>
        )}
      </>
    )
}

export default DealConnectForm