import React, {useContext, useEffect} from 'react'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography, CssBaseline, CircularProgress, Dialog, DialogContentText, DialogActions, DialogTitle, DialogContent} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AppContext } from '../../../../registration/Context';
import { ToastContainer, toast } from 'react-toastify';
import Land from '../offers/Land'
import Funding from '../offers/Funding'
import Investors from '../offers/Investors';
import OffTakers from '../offers/OffTakers';
import FunInv from '../offers/FunInv';
import FunOff from '../offers/FunOff';
import InvOff from '../offers/InvOff';
import LanFunds from '../offers/LanFunds';
import LanInv from '../offers/LanInv';
import LanOff from '../offers/LanOff';
import {Container, Row, Col} from 'react-bootstrap'
import {getFirestore, doc, addDoc, updateDoc, collection, getDoc, serverTimestamp} from "firebase/firestore";
import firebaseApp from '../../../../config/firebaseConfig';
import {useHistory} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';

export default function ProjectOffer() {

    const db = getFirestore(firebaseApp)
    const history = useHistory()

    const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    const offers = [
        "Land", 
        "Funding", 
        "Investors", 
        "Off-Takers"
    ]
    const [projectOffers, setProjectOffers] = React.useState([]);

    const {
        formValues,
        handleBack,
      } = useContext(AppContext)

      const { dealTitle, netOperatingCost, netOperatingIncome, overallROI, prefferedEquity,
        commonEquity, profitSharing, architecturalDesign, businessPlan, financialModel, projectCompletion,
        personalEquity, hotMoney, typeOfPartners, partnersOffers, noOfPartners, 
        amountOfInvestment,
        amountOfInvestmentByInvestor, expectedROI,
        landSize, landLocation, landPriceWorth, titleOfDocument, structureOnLand, 
        landFreeFromReclamation, buildingPlan,
        offTaker 
    
    } = formValues

    const handleOfferChange = (event) => {
        const {
          target: { value },
        } = event;
        setProjectOffers(
          // On autofill we get a the stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const paperStyle1 = {
        padding: 15,
        height: '45vh',
        width: 500,
        margin: "25px auto"
    }

    const paperStyle2 = {
        padding: 15,
        height: '34vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const [image1, setImage1] = React.useState();
    const [image2, setImage2] = React.useState();
    const [image3, setImage3] = React.useState();

    const [landOpen, setLandOpen] = React.useState(false);
    const [lanFunOpen, setLanFunOpen] = React.useState(false);
    const [lanInvOpen, setLanInvOpen] = React.useState(false);
    const [lanOffOpen, setLanOffOpen] = React.useState(false);
    const [funInvOpen, setFunInvOpen] = React.useState(false);
    const [funOffOpen, setFunOffOpen] = React.useState(false);
    const [fundingOpen, setFundingOpen] = React.useState(false);
    const [investor, setInvestorOpen] = React.useState(false);
    const [invOff, setInvOffOpen] = React.useState(false);
    const [offTakers, setOffTakersOpen] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);
    
    //For Land Only

    const handleLandClose = () => {
        setLandOpen(false);
    };

    //For Land and Funding
    const handleLanFunClose = () => {
        setLanFunOpen(false);
    };

    //For Land and Investor
    const handleLanInvClose = () => {
        setLanInvOpen(false);
    };

    //For Land and Off Takers
    const handleLanOffClose = () => {
        setLanOffOpen(false);
    };

    //Funding and Investors

    const handleFunInvClose = () => {
        setFunInvOpen(false);
    };

    //Funding and OffTakers

    const handleFunOffClose = () => {
        setFunOffOpen(false);
    };

    //Funding Only

    const handleFundingClose = () => {
        setFundingOpen(false);
    };

    //Investor Only

    const handleInvestorClose = () => {
        setInvestorOpen(false);
    };


    //Investor  and off takers


    const handleInvOffClose = () => {
        setInvOffOpen(false);
    };
    //off-Takers Only


    const handleOffTakersClose = () => {
        setOffTakersOpen(false);
    };

    const handleProceed = ()=>{
        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if(!image1){
          toast.error("Please choose the First Project related image", {
              position: toast.POSITION.TOP_RIGHT
            });
        }else if(!image2){
            toast.error("Please choose the Second Project related image", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(!image3){
            toast.error("Please choose the Third Project related image", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(!allowedExtensions.exec(image1.name)){
          toast.error("Please choose a valid First related image of .jpg, .jpeg, .png", {
              position: toast.POSITION.TOP_RIGHT
            });
        }else if(!allowedExtensions.exec(image2.name)){
            toast.error("Please choose a valid Second related image of .jpg, .jpeg, .png", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(!allowedExtensions.exec(image3.name)){
            toast.error("Please choose a valid Third related image of .jpg, .jpeg, .png", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(projectOffers.length === 0){
            toast.error("Please Select at least one offer you have for this project", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else if(projectOffers.length > 2){
            toast.error("You can Only Select two offers", {
                position: toast.POSITION.TOP_RIGHT
              });
        }else{
            if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
                setLandOpen(true);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(false)
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(true);
                setLanInvOpen(false);
                setLanOffOpen(false)
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(true);
                setLanOffOpen(false)
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(true);
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(false);
                setFundingOpen(true);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(false);
                setFundingOpen(false);
                setFunInvOpen(true);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(false);
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(true);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(false);
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(true);
                setOffTakersOpen(false)
            }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(false);
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(true);
                setInvestorOpen(false);
                setOffTakersOpen(false)
            }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
                setLandOpen(false);
                setLanFunOpen(false);
                setLanInvOpen(false);
                setLanOffOpen(false);
                setFundingOpen(false);
                setFunInvOpen(false);
                setFunOffOpen(false);
                setInvOffOpen(false);
                setInvestorOpen(false);
                setOffTakersOpen(true)
            }
            
        }
    }

    const currentUser = JSON.parse(localStorage.getItem("currentMember"));
        const userId = currentUser.user.uid;

        const docRef = doc(db, "Members", userId);

    const sendNotification = (title)=>{
        fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                  method: "post",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    name: "Devcluber",
                    email: currentUser.user.email,
                    subject: "Creation of '"+title+"' Currently Undergoing Review",
                    message: "<p>The support team received your interest in the latest post.</p><p>We will contact you for more details.</p><p>If you need an urgent response, kindly use your web form in the contact admin section or send a message from your InMessage.</p><p>Thank You<br />DEVCLUB360 TEAM.</p>",
                    adminEmail: "operations@devclub360.com",
                    adminSubject: "A Deal Connect has been received",
                    adminMessage: "<p>A Deal with the title '"+title+"' has been created and currently available to undergo review.</p>"
                  })
                }).then(res=>res.json())
                .then(data=>{
                  console.log(data)
                })
    }

    const [imageUrl1, setImageUrl1] = React.useState("");
    const [imageUrl2, setImageUrl2] = React.useState("");
    const [imageUrl3, setImageUrl3] = React.useState("");

    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [picsUrl, setPicsUrl] = React.useState("")
    

   
    const uploadRelatedImage = async () =>{
        const data = new FormData()
                    data.append("file", image1)
                    data.append("upload_preset", "devclub360")
                    data.append("cloud_name", "dnnbqnnnl")

                  await  fetch("https://api.cloudinary.com/v1_1/dnnbqnnnl/image/upload",{
                    method: "post",
                    body: data
                    }).then(res=>res.json())
                    .then(data=>{
                        setImageUrl1(data.secure_url);
                    })
                    .catch(err=>{
                        
                        setIsLoading(false)
                            toast.error(err, {
                            position: toast.POSITION.TOP_RIGHT
                            });
                            console.log(err);
                    });

                    //upload second related image

                    const data1 = new FormData()
                    data1.append("file", image2)
                    data1.append("upload_preset", "devclub360")
                    data1.append("cloud_name", "dnnbqnnnl")

                  await  fetch("https://api.cloudinary.com/v1_1/dnnbqnnnl/image/upload",{
                    method: "post",
                    body: data1
                    }).then(res1=>res1.json())
                    .then(data1=>{
                        setImageUrl2(data1.secure_url);
                    })
                    .catch(err=>{
                        
                        setIsLoading(false)
                            toast.error(err, {
                            position: toast.POSITION.TOP_RIGHT
                            });
                            console.log(err);
                    })

                    //upload the third related image

                    const data2 = new FormData()
                    data2.append("file", image3)
                    data2.append("upload_preset", "devclub360")
                    data2.append("cloud_name", "dnnbqnnnl")

                  await  fetch("https://api.cloudinary.com/v1_1/dnnbqnnnl/image/upload",{
                    method: "post",
                    body: data2
                    }).then(res2=>res2.json())
                    .then(data2=>{
                        setImageUrl3(data2.secure_url);
                    })
                    .catch(err=>{
                        setIsLoading(false)
                            toast.error(err, {
                            position: toast.POSITION.TOP_RIGHT
                            });
                            console.log(err);
                    })

                    if(imageUrl1 !== "" && imageUrl2 !=="" && imageUrl3 !== ""){

                        return true
                    }else {
                        return false;
                    }
    }

    useEffect(async () => {
        if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
             await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "LandSize": landSize.value,
                                                      "LandLocation": landLocation.value,
                                                      "LandPriceWorth": landPriceWorth.value,
                                                      "TitleOfDocument": titleOfDocument.value,
                                                      "StructureOnLand": structureOnLand.value,
                                                      "TypeOfStructurePlan": buildingPlan.value,
                                                      "FreeFromReclamation": landFreeFromReclamation.value,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })

        }else if(projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //Land and funding

            await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "LandSize": landSize.value,
                                                      "LandLocation": landLocation.value,
                                                      "LandPriceWorth": landPriceWorth.value,
                                                      "TitleOfDocument": titleOfDocument.value,
                                                      "StructureOnLand": structureOnLand.value,
                                                      "TypeOfStructurePlan": buildingPlan.value,
                                                      "FreeFromReclamation": landFreeFromReclamation.value,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AmountOfInvestment": amountOfInvestment.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })



        }else if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
           //Land and investors

           await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "LandSize": landSize.value,
                                                      "LandLocation": landLocation.value,
                                                      "LandPriceWorth": landPriceWorth.value,
                                                      "TitleOfDocument": titleOfDocument.value,
                                                      "StructureOnLand": structureOnLand.value,
                                                      "TypeOfStructurePlan": buildingPlan.value,
                                                      "FreeFromReclamation": landFreeFromReclamation.value,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "AmountOfInvestmentByInvestor": amountOfInvestmentByInvestor.value,
                                                      "InvestmentExpectedROI": expectedROI.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })
        }else if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
            //Land and Off-Takers
            await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "LandSize": landSize.value,
                                                      "LandLocation": landLocation.value,
                                                      "LandPriceWorth": landPriceWorth.value,
                                                      "TitleOfDocument": titleOfDocument.value,
                                                      "StructureOnLand": structureOnLand.value,
                                                      "TypeOfStructurePlan": buildingPlan.value,
                                                      "FreeFromReclamation": landFreeFromReclamation.value,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "GuaranteedOffTakers": offTaker.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })
        }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //Funding only

            await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AmountOfInvestment": amountOfInvestment.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })



        }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
           //Funding and Investors

           await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AmountOfInvestment": amountOfInvestment.value,
                                                      "AmountOfInvestmentByInvestor": amountOfInvestmentByInvestor.value,
                                                      "InvestmentExpectedROI": expectedROI.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })

        }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
            //Funding and off-Takers

            await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AmountOfInvestment": amountOfInvestment.value,
                                                      "GuaranteedOffTakers": offTaker.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })


        }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //Investor only
            await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AmountOfInvestmentByInvestor": amountOfInvestmentByInvestor.value,
                                                      "InvestmentExpectedROI": expectedROI.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })

        }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
            //Investors andd Off-Takers

            await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "AmountOfInvestmentByInvestor": amountOfInvestmentByInvestor.value,
                                                      "InvestmentExpectedROI": expectedROI.value,
                                                      "GuaranteedOffTakers": offTaker.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })

        }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
            //Off-Takers only

            await addDoc(collection(db, "DealConnects"), {
                                                      "DealTitle": dealTitle.value,
                                                      "DealCreatorOffers": projectOffers,
                                                      "TypeOfJointVenture": typeOfPartners.value,
                                                      "JointPartnersOffer": partnersOffers.value,
                                                      "NumberOfInvestors": noOfPartners.value,
                                                      "DealCreatedBy": userId,
                                                      "DealCreatorName": firstName+ " "+lastName,
                                                      "ProjectRelatedImages1": imageUrl1,
                                                      "ProjectRelatedImages2": imageUrl2,
                                                      "ProjectRelatedImages3": imageUrl3,
                                                      "DealCreatorPics": picsUrl,
                                                      "DealStatus": "Pending",
                                                      "NetOperatingCost": netOperatingCost.value,
                                                      "NetOperatingIncome": netOperatingIncome.value,
                                                      "OverAllROI": overallROI.value,
                                                      "PrefferedEquityInvestor": prefferedEquity.value,
                                                      "CommonEquityInvestor": commonEquity.value,
                                                      "ProfitSharingRatio": profitSharing.value,
                                                      "ArchitecturalDesignReady": architecturalDesign.value,
                                                      "BusinessPlanReady": businessPlan.value,
                                                      "FinancialModelReady": financialModel.value,
                                                      "ProjectCompletionTimeLine": projectCompletion.value,
                                                      "AnyPersonalEquity": personalEquity.value,
                                                      "GuaranteedOffTakers": offTaker.value,
                                                      "AnyHotMoney": hotMoney.value,
                                                      "createdAt": serverTimestamp()

                                }).then((update)=>{
                                    
                                    updateDoc(doc(db, "DealConnects", update.id),{
                                        DealConnectId: update.id
                                    }).then((_)=>{
                                        sendNotification(dealTitle.value)
                                        setIsLoading(false);
                                        toast.success("Submitted successfully",{
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        history.push("/members/deal-created")
                                    })
                                        
                                }).catch((error)=>{
                                    console.log(error)
                                })
        }
    }, [imageUrl3])
    

    const createNewDeal = async ()=>{
        
        setIsLoading(true);

        if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //Land only
            if(!landSize.value || !landLocation.value || !landPriceWorth.value  || !titleOfDocument.value ||
            !structureOnLand.value || !landFreeFromReclamation.value || !buildingPlan.value){
                    toast.error("Your Land Options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
            
        }else if(projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //Land and funding

            if(!landSize.value || !landLocation.value || !landPriceWorth.value  || !titleOfDocument.value ||
                !structureOnLand.value || !landFreeFromReclamation.value || !buildingPlan.value ||  !amountOfInvestment.value){
                    toast.error("Your Land Options and Funding options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //Land and investors

            if(!landSize.value || !landLocation.value || !landPriceWorth.value  || !titleOfDocument.value ||
                !structureOnLand.value || !landFreeFromReclamation.value || !buildingPlan.value || !amountOfInvestmentByInvestor.value || !expectedROI.value){
                    toast.error("Your Land Options and Investor options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
            //Land and offtakers

            if(!landSize.value || !landLocation.value || !landPriceWorth.value  || !titleOfDocument.value ||
                !structureOnLand.value || !landFreeFromReclamation.value || !buildingPlan.value || !offTaker.value){
                    toast.error("Your Land Options and Off-Taker options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //only funding

            if(!amountOfInvestment.value){
                    toast.error("Your Funding Option is required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
           //funding and investors

            if(!amountOfInvestment.value || !amountOfInvestmentByInvestor.value || !expectedROI.value){
                    toast.error("Your Funding and investor Options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(!projectOffers.toString().includes("Land") && projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
            //funding and off takers
            if(!amountOfInvestment.value || !offTaker.value){
                    toast.error("Your Funding and Off-Taker Options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && !projectOffers.toString().includes("Off-Takers")){
            //Only investors

            if(!amountOfInvestmentByInvestor.value || !expectedROI.value){
                    toast.error("Your Investor Options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
            //Investors and offtakers

            if(!amountOfInvestmentByInvestor.value|| !expectedROI.value || !offTaker.value){
                    toast.error("Your Investor and Off-Takers Options are required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }else if(!projectOffers.toString().includes("Land") && !projectOffers.toString().includes("Funding") && !projectOffers.toString().includes("Investors") && projectOffers.toString().includes("Off-Takers")){
           //Only offtakers

           if(!offTaker.value){
                    toast.error("Your Off-Takers Option is required for this project", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
            }else{
                if(uploadRelatedImage()){
                    setIsLoading(true);
                    await getDoc(docRef).then( async(docs)=>{
                        if(docs.exists){
                            setFirstName(docs.get("firstName"));
                            setLastName(docs.get("lastName"));
                           setPicsUrl(docs.get("ProfilePicsUrl"))
                            
                            //Create new deal in the databse
                        }else{
                            toast.error("We couldn't find your profile",{
                                position: toast.POSITION.TOP_RIGHT
                            })
                            setIsLoading(false);
                        }
                    })
                        
                }else{
                    toast.error("Your Related Images could not be uploaded", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setIsLoading(false);
                }
            }
        }
            

    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Container>
                <Row>
                <Col sm={12} md={12} lg={12} xs={12} xl={12}>
            <Grid>
                <Paper elevation= {10} style={paperStyle1}>
                <Grid align ="center">
                        <Avatar style= {avatarStyle}><FileUploadOutlinedIcon /></Avatar>
                        <h4><Typography>Upload Three Project Related Images</Typography></h4>
                    </Grid>
                    <TextField
                        fullWidth
                        name="image1"
                        type="file"
                        onChange={(e)=>setImage1(e.target.files[0])}
                        required
                    />

                    <TextField
                        fullWidth
                        name="image2"
                        type="file"
                        onChange={(e)=>setImage2(e.target.files[0])}
                        required
                    />

                    <TextField
                        fullWidth
                        name="image3"
                        type="file"
                        onChange={(e)=>setImage3(e.target.files[0])}
                        required
                    />
                </Paper>

                <Paper elevation = {10} style={paperStyle2}>
                    <Grid>
                        <FormControl sx={{ m: 1, width: 450 }}>
                            <InputLabel id="-multiple-offer-label">Your Project Offers</InputLabel>
                            <Select
                            labelId="multiple-offer-label"
                            id="multiple-offer"
                            multiple
                            value={projectOffers}
                            onChange={handleOfferChange}
                            input={<OutlinedInput label="Your Project Offers" />}
                            MenuProps={MenuProps}
                            >
                            {offers.map((offer) => (
                                <MenuItem
                                key={offer}
                                value={offer}
                                
                                >
                                {offer}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button sx={{ mr: 1 }} onClick={handleBack}>
                        Previous
                        </Button>
                        <Button variant="contained" color="success" onClick={handleProceed}>
                        Proceed
                        </Button>
                    </Box>
                </Paper>

                <Dialog open={landOpen} onClose={handleLandClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        
                            <Land />
                            <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                {/*Land and Funding */}

                <Dialog open={lanFunOpen} onClose={handleLanFunClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <LanFunds />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                {/*Land and Investors */}

                <Dialog open={lanInvOpen} onClose={handleLanInvClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <LanInv />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                {/*Land and OffTakers */}
                <Grid item xs>
                <Dialog open={lanOffOpen} onClose={handleLanOffClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <LanOff />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                {/* For funding only */}
                <Dialog open={fundingOpen} onClose={handleFundingClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <Funding />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>
                
                {/* For funding and Investors */}
                <Dialog open={funInvOpen} onClose={handleFunInvClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <FunInv />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                {/* For funding and Off-Takers */}
                <Dialog open={funOffOpen} onClose={handleFunOffClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <FunOff />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                {/* For Investors only */}
                <Dialog open={investor} onClose={handleInvestorClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <Investors />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                 {/* For Investors and Off-Takers */}
                 <Dialog open={invOff} onClose={handleInvOffClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <InvOff />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>

                 {/* For off-Takers only */}
                 <Dialog open={offTakers} onClose={handleOffTakersClose}>
                    <DialogTitle>Your Project Offer(s)</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Please fill in the following fields for Your Offers.
                    </DialogContentText>
                        <OffTakers />
                        <ToastContainer />
                    </DialogContent>
                    <DialogActions>
                    <Button color="success" disabled={isLoading} onClick={()=>createNewDeal()} fullwidth variant="contained">{isLoading ? <CircularProgress />: <Typography variant="inherit">Submit Deal Connect Project</Typography>}</Button>
                    
                    </DialogActions>
                </Dialog>


                <ToastContainer />
            </Grid>
            </Grid>
            </Col>
            </Row>
            </Container>
        </React.Fragment>
    )
}
