import React, {useEffect} from 'react'
import Header from '../components/Header'
import { Typography, Grid, Box, List, ListItem, ListItemButton, ListItemText, Paper, CircularProgress } from '@mui/material'
import useStyles from '../components/HeaderStyles'
import { useHistory } from 'react-router-dom'
import db from '../components/firebaseConfig'
import { collection, getDocs,query, where,} from 'firebase/firestore/lite';
import {doc, getDoc } from "firebase/firestore/lite";
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
export default function Notification() {
    const classes = useStyles()

    const [isLoading, setIsLoading] = React.useState(true)
    const currentMember = JSON.parse(localStorage.getItem("currentMember"))
    const [notification, setNotification] = React.useState([]);
    const [done, setDone] = React.useState(false)
    const history = useHistory()

    const paperStyle = {
        padding: 20,
        height: '15vh',
        width: 300,
        margin: "20px auto"
    } 
    const paperStyle2 = {
        padding: 20,
        height: '45vh',
        width: 700,
        margin: "19px auto"
    } 

    const fetchAnnouncement= async()=>{
        setDone(true)
        const response =collection(db, 'Announcements');
        const data= await getDocs(response);
        data.docs.forEach(item=>{
        setNotification((prevState)=>{
            return [...prevState, item.data()]
        })
        })

        setDone(false)
        setIsLoading(false)
    }

useEffect(async ()=>{
 var firstName = ""
if(currentMember){
    const docRef = doc(db, "Members", currentMember.user.uid);
  await getDoc(docRef).then(async(docs)=>{

      
      if(docs.exists){
          const accountStatus=  await docs.get("AccountStatus");
          firstName = await docs.get("firstName")

          if(!firstName){
              
            localStorage.removeItem("currentMember");
            history.push("/")
                      
          }else{
              if(accountStatus == "Pending"){
                  //Account has not been apprved
                  localStorage.removeItem("currentMember");
                    history.push("/")
              }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                  //Account has not been suspended or terminated
                  localStorage.removeItem("currentMember");
                  history.push("/")
              }else if(accountStatus== "Active On Payment Confirmation"){
                      //Account has not been suspended or terminated
                      localStorage.removeItem("currentMember");
                      history.push("/")
              }else{
                  //Lets first check if its active on Trial
                  var creationDate = docs.get("CreatedAt");
                  var date = new Date(creationDate * 1000).getDate();
                  var today = new Date();
                  var diffInDays = (today.getDate()- date)
                  
                  if(diffInDays > 30 && accountStatus=="Active On Trial"){
                    localStorage.removeItem("currentMember");
                    history.push("/")
                  }else{
                    fetchAnnouncement();
                  }

                }
            }
            
        
        }else{
            localStorage.removeItem("currentMember");
                    history.push("/")
            }
        }).catch(async(_)=>{
            localStorage.removeItem("currentMember");
                        history.push("/")
        })

                    
            
    }else{
        history.push("/")
    }
  
  
        
      },[])


    return (
        <React.Fragment>
            <Header />
            <Grid className= {classes.wrapper}>
                <Typography variant="h4" >Notifications</Typography>
                <Grid align="center">
                <Grid>
                    {
                        done ? 
                        <Grid align="center">
                            <ReactLoading type={"spin"} color={"green"} height={200} width={200} />
                        </Grid>
                        : 
                        <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', }} style={{marginTop:"15px"}}>
                        {
                            notification.length !=0?
                        notification && notification.map((announcements, index)=>(
                            <ListItem key={index} >
                                
                                <ListItemText style={{marginTop: "12px", marginBottom: "10px"}} align="left" primary={announcements.Announcement} secondary={new Date(announcements.AnnouncementDate * 1000).getDate()+
          "/"+(new Date(announcements.AnnouncementDate * 1000).getMonth()+1)+
          " "+new Date(announcements.AnnouncementDate * 1000).getHours()+
          ":"+new Date(announcements.AnnouncementDate * 1000).getMinutes()+
          ":"+new Date(announcements.AnnouncementDate * 1000).getSeconds()} />
                                
                            </ListItem>
                        ))
                            
                        
                        :
                        <Grid align="center">
                                <Paper elevation= {10} style={paperStyle}>
                                    <Typography variant="h6">{isLoading ? <i>Loading...<CircularProgress /></i>: <b>No Announcement available yet</b>}</Typography>
                                </Paper>
                         </Grid>
                    }
                    </List>
                    
                    }
                   
                </Grid>
                </Grid>
            </Grid>
            
        </React.Fragment>
    )
}
