import { ValidationSchema } from './Context'

export const initialValues: ValidationSchema = {
  firstName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message'
  },
  lastName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20
  },
  achievementHope: {
    value: '',
    error: '',
    required: true,
    
  },

  achievements: {
    value: '',
    error: '',
    required: true,
  },
  lastTransaction: {
    value: '',
    error: '',
    required: true,
    validate: 'number',
    
  },
  yearlyTurnOver: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },
  companyName: {
    value: '',
    error: '',
    required: true,
  },
  representativeName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 100
  },
  corporateEmail: {
    value: '',
    error: '',
    validate: 'email',
    required: true
  },
  website: {
    value: '',
    error: '',
    required: false,
  },
  linkedIn: {
    value: '',
    error: '',
    required: true,
    minLength: 2,
    
  },
  positionHeld: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
  },
  
  marketArea: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },
  practiceArea: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },
  dealToCloseInNextQuarter: {
    value: '',
    error: '',
    validate: 'select'
  },
  membersCategory: {
    value: '',
    error: '',
    validate: 'select'
  },
  
  address: {
    value: '',
    error: '',
    required: true
  },
  agreement: {
    value: false,
    error: '',
    required: true,
    validate: 'checkbox',
    helperText: 'Please accept our terms and conditions'
  },
  phone: {
    value: '',
    error: '',
    validate: 'number',
    minLength: 11,
    maxLength: 15,
    required: true
  },
  NIN: {
    value: '',
    error: '',
    validate: 'number',
    required: false
  },
  driverLicense: {
    value: '',
    error: '',
    required: false,
  },
  VIN: {
    value: '',
    error: '',
    required: false,
  },
  CAC: {
    value: '',
    error: '',
    required: false,
  },

  TIN: {
    value: '',
    error: '',
    required: false,
  },

  //Deal Connect fields
  dealTitle: {
    value: '',
    error: '',
    required: true,
  },
  netOperatingCost: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },
  netOperatingIncome: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },
  overallROI: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },

  prefferedEquity: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },

  commonEquity: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },
  profitSharing: {
    value: '',
    error: '',
    required: true,
  },
  architecturalDesign: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },

  businessPlan: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },

  financialModel: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },

  projectCompletion: {
    value: '',
    error: '',
    required: true,
  },

  personalEquity: {
    value: '',
    error: '',
    required: true,
  },
  hotMoney: {
    value: '',
    error: '',
    validate: 'select',
    required: true
  },

  //Deal connect continue
  typeOfPartners: {
    value: '',
    error: '',
    required: true,
  },

  partnersOffers: {
    value: '',
    error: '',
    required: true,
  },

  noOfPartners: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },

  //offers for land
  landSize: {
    value: '',
    error: '',
    required: true,
    
  },
  landLocation: {
    value: '',
    error: '',
    required: true,
  },
  landPriceWorth: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },
  titleOfDocument: {
    value: '',
    error: '',
    required: true,
  },
  structureOnLand: {
    value: '',
    error: '',
    required: true,
    validate: 'select'
  },
  landFreeFromReclamation: {
    value: '',
    error: '',
    required: true,
    validate: 'select'
  },
  buildingPlan: {
    value: '',
    error: '',
    required: true,
    
  },
  landFreeFromEucumbrance: {
    value: '',
    error: '',
    required: true,
    validate: 'select'
  },
  //Funding offers
  amountOfInvestment: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },

  //Investors offers

  amountOfInvestmentByInvestor: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },
  expectedROI: {
    value: '',
    error: '',
    required: true,
    validate: 'number'
  },

  //offtakers offer
  offTaker: {
    value: '',
    error: '',
    required: true,
    validate: 'select'
  },

}
