import React from 'react'
import Land from './Land'
import Investors from './Investors'

export default function LanInv() {
    return (
        <React.Fragment>
            <Land />
            <Investors />
        </React.Fragment>
    )
}
