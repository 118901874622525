import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import {Avatar, Grid, Paper, Button, Typography} from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CssBaseline from '@mui/material/CssBaseline'
import { AppContext } from '../Context'

export default function Confirmation(){

    const { formValues, handleBack, handleNext } = useContext(AppContext)
    const { firstName, lastName, phone, address, linkedIn,
        membersCategory, dealToCloseInNextQuarter, achievementHope, lastTransaction, yearlyTurnOver, achievements,
        NIN, driverLicense, VIN, CAC, TIN,
        companyName, website, positionHeld,  representativeName, corporateEmail

    } = formValues

    const handleSubmit = () => {
        // Remove unwanted properties from formValue object
        let form = {}
    
        Object.keys(formValues).map((name) => {
          form = {
            ...form,
            [name]: formValues[name].value
          }
          return form
        })
        // Do whatever with the values
        
        // Show last component or success message
        handleNext()
      }

      const paperStyle = {
        padding: 10,
        height: '280vh',
        width: 500,
        margin: "14px auto"
        }
        const avatarStyle = {
            backgroundColor: "#1bbd7e"
        }
    return (
        <React.Fragment>
            <Grid item xs={12}>
            <CssBaseline />

                <Paper elevation={10} style={paperStyle}>
                    <Grid align ="center">
                        <Avatar style= {avatarStyle}><CheckCircleOutlinedIcon /></Avatar>
                        <h4><Typography>Confirm Your Information we have so far</Typography></h4>
                    </Grid>
                    <List disablePadding>
                        <ListItem>
                        
                        <ListItemText
                            primary="Your First Name"
                            secondary={firstName.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Last Name"
                            secondary={lastName.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Phone Number"
                            secondary={phone.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Home Address"
                            secondary={address.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your LinkedIn Profile URL"
                            secondary={linkedIn.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Category Of User"
                            secondary={membersCategory.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="The Deal You Expect To Close Within The Next Quarter"
                            secondary={dealToCloseInNextQuarter.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="What You Hope To Achieve on Devclub360"
                            secondary={achievementHope.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Last Transaction Worth"
                            secondary={lastTransaction.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Yearly Turn Over"
                            secondary={yearlyTurnOver.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Achievements"
                            secondary={achievements.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your National Identification Number"
                            secondary={NIN.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Driver's License Identification Number"
                            secondary={driverLicense.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Voter's Identification Number"
                            secondary={VIN.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your CAC Identification Number"
                            secondary={CAC.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                        <ListItem>
                        <ListItemText
                            primary="Your Tax Identification Number"
                            secondary={TIN.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                        <ListItem>
                        <ListItemText
                            primary="Your Company Name"
                            secondary={companyName.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                        <ListItem>
                        <ListItemText
                            primary="Your Company Website"
                            secondary={website.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                        <ListItem>
                        <ListItemText
                            primary="Your Position"
                            secondary={positionHeld.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Secretary/Representative"
                            secondary={representativeName.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                        <ListItem>
                        <ListItemText
                            primary="Your Office Email"
                            secondary={corporateEmail.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                    </List>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button sx={{ mr: 1 }} onClick={handleBack}>
                        Previous
                        </Button>
                        <Button variant="contained" color="success" onClick={handleSubmit}>
                        Confirm & Continue
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

