import React from 'react'
import Funding from './Funding'
import Investors from './Investors'

export default function FunInv() {
    return (
        <React.Fragment>
            <Funding />
            <Investors />
        </React.Fragment>
    )
}
