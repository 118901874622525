import React, { useCallback, useContext } from 'react'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {AppContext} from '../Context.tsx';
import CssBaseline from '@mui/material/CssBaseline'

export default function TransactionProfile(){
    const paperStyle = {
        padding: 20,
        height: '120vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const {
        formValues,
        handleChange,
        handleBack,
        handleNext,
        variant,
        margin
      } = useContext(AppContext)
      const { membersCategory, dealToCloseInNextQuarter, achievementHope, lastTransaction, yearlyTurnOver, achievements } = formValues

      // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
        Object.keys({ membersCategory, dealToCloseInNextQuarter, achievementHope, lastTransaction, yearlyTurnOver, achievements }).some(
            (name) =>
            (formValues[name].required && !formValues[name].value) ||
            formValues[name].error
        ),
        [formValues, membersCategory, dealToCloseInNextQuarter, achievementHope, lastTransaction, yearlyTurnOver, achievements]
    )
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <CssBaseline />

                <Paper elevation={10} style={paperStyle}>
                    <Grid align ="center">
                        <Avatar style= {avatarStyle}><CreateOutlinedIcon /></Avatar>
                        <h4><Typography>Transaction and Achievements</Typography></h4>
                    </Grid>
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true,
                        }}
                        label="Member's Category"
                        name="membersCategory"
                        value={membersCategory.value}
                        onChange={handleChange}
                        error={!!membersCategory.error}
                        helperText={membersCategory.error}
                        required={membersCategory.required}
                    >
                        <option value=""> </option>
                        <option value="Investor">Investor</option>
                        <option value="Developer">Real Estate Developer</option>
                    </TextField>

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        select
                        SelectProps={{
                        native: true
                        }}
                        label="How much do you want to close in next quarter?"
                        name="dealToCloseInNextQuarter"
                        value={dealToCloseInNextQuarter.value}
                        onChange={handleChange}
                        error={!!dealToCloseInNextQuarter.error}
                        helperText={dealToCloseInNextQuarter.error}
                        required={dealToCloseInNextQuarter.required}
                    >
                        <option value=""> </option>
                        <option value="350,000-650,000">USD350K-USD650K</option>
                        <option value="700,000-1,000,000">USD700K-USD1M</option>
                        <option value="1,500,000-2,000,000">USD1.5M-USD2M</option>
                        <option value="Above 2,000,000">Above USD2M</option>
                    </TextField>

                    <TextField
                        id="outlined-multiline-static"
                        label="What do you Hope to achieve on Devclub360?"
                        multiline
                        fullWidth
                        rows={4}
                        variant={variant}
                        margin={margin}
                        placeholder = "What do you hope to achieve on Devclub360"
                        name="achievementHope"
                        value={achievementHope.value}
                        onChange={handleChange}
                        error={!!achievementHope.error}
                        helperText={achievementHope.error}
                        required={achievementHope.required}
                        
                    />

                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        placeholder = "Amount in USD"
                        label="Your Yearly Turn Over?"
                        name="yearlyTurnOver"
                        value={yearlyTurnOver.value}
                        onChange={handleChange}
                        error={!!yearlyTurnOver.error}
                        helperText={yearlyTurnOver.error}
                        required={yearlyTurnOver.required}
                    />
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        placeholder = "Amount in USD"
                        label="Your Last Transaction Worth?"
                        name="lastTransaction"
                        value={lastTransaction.value}
                        onChange={handleChange}
                        error={!!lastTransaction.error}
                        helperText={lastTransaction.error}
                        required={lastTransaction.required}
                    />
                    
                    <TextField
                        id="outlined-multiline-static"
                        label="What are Your Achievements"
                        multiline
                        fullWidth
                        rows={4}
                        variant={variant}
                        margin={margin}
                        placeholder = "Your achievements"
                        name="achievements"
                        value={achievements.value}
                        onChange={handleChange}
                        error={!!achievements.error}
                        helperText={achievements.error}
                        required={achievements.required}
                        
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button onClick={handleBack} sx={{ mr: 1 }}>
                        Previous
                        </Button>
                        <Button
                        variant="contained"
                        disabled={isError()}
                        color="primary"
                        onClick={!isError() ? handleNext : () => null}
                        >
                        Next
                        </Button>
                    </Box>
                    
                </Paper>
            </Grid>
            
        </React.Fragment>
    )
}


