import React, {useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert'
import useMediaQuery from '@mui/material/useMediaQuery';
import {ThemeProvider, useTheme } from '@mui/material/styles';
import {useHistory} from 'react-router-dom'
import {collection, getDocs, query, where} from 'firebase/firestore/lite'
import db from './firebaseConfig'

 
  

export default function TopBar({handleDrawerToggle}) {
  const [inbox, setInbox] = React.useState([])
  const [notification, setNotification] = React.useState([])

  const currentMember = JSON.parse(localStorage.getItem("currentMember"));
  const history = useHistory();

    const fetchInbox= async()=>{
              
      const response = query(collection(db, "MembersInbox"), where("MessageReceiver", "==", currentMember.user.uid));
      const data= await getDocs(response);
      data.docs.forEach(item=>{
      setInbox((prevState)=>{
        return [...prevState, item.data()]
    })
      })
  }

  const fetchAnnouncement= async()=>{
      const response =collection(db, 'Announcements');
      const data= await getDocs(response);
      data.docs.forEach(item=>{
      setNotification((prevState)=>{
        return [...prevState, item.data()]
    })
      })
  }
  
      useEffect(() => {
          if(currentMember){
            fetchInbox();
            fetchAnnouncement();
          }else{
            history.push("/")
          }
      }, [])


      const [anchorEl, setAnchorEl] = React.useState(null);
      const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    
      const isMenuOpen = Boolean(anchorEl);
      const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    
      const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
      };
    
      const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
      };
    
      const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
      };
    
      const menuId = 'primary-search-account-menu';
      const renderMenu = (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={()=>{history.push("/members/profile")}}>Profile</MenuItem>
          <MenuItem onClick={()=>{history.push("/members/account/logout")}}>Log Out</MenuItem>
        </Menu>
      );

      
    
      const mobileMenuId = 'primary-search-account-menu-mobile';
      const renderMobileMenu = (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={()=>{history.push("/members/messages")}}>
              <Badge badgeContent={inbox.length} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <p>Messages</p>
          </MenuItem>
          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={()=>{history.push("/members/notifications")}}
            >
              <Badge badgeContent={notification.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <p>Notifications</p>
          </MenuItem>
          <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={()=>{history.push("/members/profile")}}
            >
              <AccountCircle />
            </IconButton>
            <p>Profile</p>
          </MenuItem>
        </Menu>
      );

      const avatarStyle = {
        backgroundColor: "white",
    }
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
   
    
    return (
        <React.Fragment>
             <ThemeProvider theme={theme}>
             <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                   { !matches ?
                   <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton> : null
                    }
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        DEVCLUB360
                    </Typography>
                   
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={()=>{history.push("/members/messages")}}>
                        <Badge badgeContent={inbox.length} color="error">
                            <MailIcon />
                        </Badge>
                        </IconButton>
                        <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                        onClick={()=>{history.push("/members/notifications")}}
                        >
                        <Badge badgeContent={notification.length} color="error">
                            <NotificationsIcon />
                        </Badge>
                        </IconButton>
                        <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                        >
                        <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="inherit"
                        >
                        <MoreIcon />
                        </IconButton>
                    </Box>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                </Box>
             </ThemeProvider>
        </React.Fragment>
    )
}
