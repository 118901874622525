import React from 'react'
import {Carousel} from 'react-bootstrap'
import slide1 from './assets/images/1.jpg';
import slide2 from './assets/images/2.jpg';
import slide3 from './assets/images/3.jpg';
import slide4 from './assets/images/4.jpg';
import slide5 from './assets/images/5.jpg';
import slide6 from './assets/images/6.jpg';
import slide7 from './assets/images/7.jpg';
import slide8 from './assets/images/8.jpg';
import slide9 from './assets/images/9.jpg';
import slide10 from './assets/images/10.jpg';
import slide11 from './assets/images/11.jpg';
import slide12 from './assets/images/12.jpg';

export default function CarouselContainer() {
    return (
        <div>
 <Carousel fade indicators= {false} interval="4000">
  <Carousel.Item>
    <img
        className="d-block w-100"
        src={slide8}
        alt=""
      />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide2}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide3}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide4}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide5}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide6}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide7}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
        className="d-block w-100"
        src={slide1}
        alt=""
      />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide9}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide10}
      alt="r"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide11}
      alt=""
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide12}
      alt=""
    />
  </Carousel.Item>
</Carousel>
        </div>
    )
}
