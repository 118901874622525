import React, {useEffect, useState} from 'react'
import Header from '../components/Header'
import { Typography, Grid, Paper, Card, CircularProgress} from '@mui/material'
import useStyles from '../components/HeaderStyles'
import {Button, IconButton} from '@material-ui/core';
import MaterialTable from 'material-table';
import db from '../components/firebaseConfig'
import { collection, query, where, getDocs} from "firebase/firestore/lite";
import {doc, getDoc } from "firebase/firestore/lite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { Container, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
  

export default function DealShare() {

    const [isLoading, setIsLoading] = React.useState(true)
    const [deals, setDeals] = useState([])
    const [done, setDone] = React.useState(undefined)
    const fetchDeals= async()=>{
        setDone(true)
        const response = query(collection(db, 'DealShares'), where("DealStatus", "==", "Open"));
        const data= await getDocs(response);

        data.forEach(item=>{
            setDeals((prevState)=>{
                return [...prevState, item.data()]
            })
           })

           
            setDone(false)
            setIsLoading(false)
      }

      const currentMember = JSON.parse(localStorage.getItem("currentMember"))
      const history = useHistory()

    useEffect(async ()=>{
        var firstName = ""

        if(currentMember){
            const docRef = doc(db, "Members", currentMember.user.uid);
            await getDoc(docRef).then(async(docs)=>{
          
                
                if(docs.exists){
                    const accountStatus=  await docs.get("AccountStatus");
                    firstName = await docs.get("firstName")
          
                    if(!firstName){
                        
                      localStorage.removeItem("currentMember");
                      history.push("/")
                                
                    }else{
                        if(accountStatus == "Pending"){
                            //Account has not been apprved
                            localStorage.removeItem("currentMember");
                              history.push("/")
                        }else if(accountStatus== "Suspended" || accountStatus == "Terminated"){
                            //Account has not been suspended or terminated
                            localStorage.removeItem("currentMember");
                            history.push("/")
                        }else if(accountStatus== "Active On Payment Confirmation"){
                                //Account has not been suspended or terminated
                                localStorage.removeItem("currentMember");
                                history.push("/")
                        }else{
                            //Lets first check if its active on Trial
                            var creationDate = docs.get("CreatedAt");
                            var date = new Date(creationDate * 1000).getDate();
                            var today = new Date();
                            var diffInDays = (today.getDate()- date)
                            
                            if(diffInDays > 30 && accountStatus=="Active On Trial"){
                              localStorage.removeItem("currentMember");
                              history.push("/")
                            }else{
                              fetchDeals();
                            }
          
                          }
                      }
                      
                  
                  }else{
                      localStorage.removeItem("currentMember");
                              history.push("/")
                      }
                  }).catch(async(_)=>{
                      localStorage.removeItem("currentMember");
                                  history.push("/")
                  })
          
                          
                  
        }else{
            history.push("/")
        }
 
        
      },[])
      

      const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }

 

    const paperStyle = {
        padding: 20,
        height: '15vh',
        width: 300,
        margin: "20px auto"
    }  

    

    const classes = useStyles()
    return (
        <React.Fragment>
            <Header />
            <Grid item xs={12} className={classes.wrapper}>
               
                <Grid>
                        {
                            done? 
                                <Grid align="center">
                                    <ReactLoading type={"spin"} color={"green"} height={200} width={200} />
                                </Grid>
                            
                            : 
                            <Paper className={classes.dealconnectPaper}>
                            <Typography variant="h4" >Deal Share</Typography>
                            

                            <Card>
                                {

                                    deals.length>0 ?
                                
                                <MaterialTable
                                    title= "Devclub360 Deals"
                                    columns= {[
                                        
                                        {
                                            title: 'Deal Title',
                                            field: 'DealTitle'
                                        },
                                        
                                        {
                                            title: 'Numbers of Partners Required',
                                            field: 'NumberOfInvestors'
                                        },
                                        {
                                            title: 'Project Timeline',
                                            field: 'ProjectCompletionTimeLine'
                                        },
                                        {
                                            title: 'Action',
                                            field: 'view',
                                            render: (rowData)  => rowData && 
                                            <>
                                                <Button variant="contained" color="primary" onClick={()=>alert("Curently Unavailable at the moment")}>
                                                    view details
                                                </Button>
                                              </>
                                        }
                                        
                                        
                                    ]}
                                    data= {deals}
                                    action={[
                                        {
                                            tooltip: "",
                                            icon: ''
                                        }
                                    ]}
                                    options={{
                                        pageSize: 20,
                                        sorting: true,
                                        exportButton: true,
                                        actionsColumnIndex: -1
                                      }}
                                   

                                
                                />
                                : 
                                <Grid align="center">
                                    <Paper elevation= {10} style={paperStyle}>
                                    <Typography variant="h6">{isLoading ? <i>Loading...<CircularProgress /></i>: <b>No Deals available yet</b>}</Typography>
                                </Paper>
                                </Grid>
                                
                            }
                            </Card>
                        </Paper>
                        }
                        
                </Grid>
                
            </Grid>
            
        </React.Fragment>
    )
}
