import React, { useContext, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {Avatar, Grid, Paper, Box, TextField, Button, Typography} from '@mui/material';
import {AppContext} from '../Context.tsx';
import CssBaseline from '@mui/material/CssBaseline'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import firebaseApp from '../../config/firebaseConfig';
import { getFirestore, doc, setDoc, collection, getDocs, serverTimestamp} from "firebase/firestore";

const db = getFirestore(firebaseApp)

const membersRef = collection(db, "Members");
const companyRef = collection(db, "MembersCompanyDetails");
const membershipRef = collection(db, "MembershipDetails");

export default function AdditionalInfo() {
    const paperStyle = {
        padding: 20,
        height: '70vh',
        width: 500,
        margin: "25px auto"
    }
    const avatarStyle = {
        backgroundColor: "#1bbd7e"
    }
    const {
        formValues,
        handleBack,
        variant,
        margin
      } = useContext(AppContext)

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
        };

        const [done, setDone] = useState(undefined);
        const parArea = [
            "Investment",
            "Tenant Representation",
            "Brokerage Management",
            "Land Sales and Acquisition",
            "Building Construction",
            "Architecture",
            "Chartered Surveyor",
            "Distribution and Logistics",
            "Eqipment and Leasing",
            "Consulting",
            "General Contractor"
          ];

        const marArea = [
            "Algeria",
            "Angola",
            "Benin",
            "Burundi",
            "Cameroun",
            "Cape Verde",
            "Egypt",
            "Europe",
            "Ghama",
            "Ivory Coast",
            "Kenya",
            "Liberia",
            "Mali",
            "Morroco",
            "Nigeria",
            "Senegal",
            "Sierra Leone",
            "South Africa",
            "UAE",
            "United Kingdom",
            "United States",
            "Zambia",
            "Zimbabwe"
        ];

        
          const [practiceArea, setPracticeArea] = React.useState([]);

          const handlePracticeChange = (event) => {
            const {
              target: { value },
            } = event;
            setPracticeArea(
              // On autofill we get a the stringified value.
              typeof value === 'string' ? value.split(',') : value,
            );
          };
          const [marketArea, setMarketArea] = React.useState([]);
          const [pic, setPics] = React.useState()
          const [url, setUrl] = React.useState("")

          const handleMarketChange = (event) => {
            const {
              target: { value },
            } = event;
            setMarketArea(
              // On autofill we get a the stringified value.
              typeof value === 'string' ? value.split(',') : value,
            );
          };
          const { firstName, lastName, phone, address, linkedIn,
              membersCategory, dealToCloseInNextQuarter, achievementHope, lastTransaction, yearlyTurnOver, achievements,
              NIN, driverLicense, VIN, CAC, TIN,
              companyName, website, positionHeld,  representativeName, corporateEmail
      
          } = formValues
          const currentUser =JSON.parse(localStorage.getItem("current"))
          const history = useHistory();


          useEffect(async()=>{
            if(url){
              if(currentUser){

                const querySnapshot = await getDocs(collection(db, "MembershipDetails"));
    const length = querySnapshot.size;
    const newLength = length + 1;
    await setDoc(doc(membersRef, currentUser.user.uid), {
        AccountStatus: "Pending", 
        CAC: CAC.value, 
        CreatedAt:  serverTimestamp(), 
        DealToCloseInNextQuarter: dealToCloseInNextQuarter.value,
        DriversLicense: driverLicense.value, EmailAddress: currentUser.user.email, HomeAddress: address.value, LinkedInProfile: linkedIn.value,
        MemberCategory: membersCategory.value, MembersAchievement: achievements.value, NIN: NIN.value, PhoneNumber: phone.value,
        ProfilePicsUrl: url, TIN: TIN.value, VIN: VIN.value, YearlyWorth: yearlyTurnOver.value, achievementHopeOnDevClub: achievementHope.value,
        firstName: firstName.value, lastName: lastName.value, lastTransactionWorth: lastTransaction.value, uid: currentUser.user.uid

    }).then(async (_)=>{
        await setDoc(doc(companyRef, currentUser.user.uid), {
            CompanyName: companyName.value, CompanyWebsite: website.value, CorporateEmail: corporateEmail.value,
            CreatedAt: serverTimestamp(),
            MarketArea: marketArea, PositionHeld: positionHeld.value, PracticeArea: practiceArea,
            RepresentativeName: representativeName.value, uid: currentUser.user.uid
        }).then( async(_)=>{
            await setDoc(doc(membershipRef, currentUser.user.uid), {
                MemberShipDateAndTime: serverTimestamp(),
                MemberShipId: newLength.toString(),
                MembershipStatus: "Active",
                uid: currentUser.user.uid
            }).then(async(_)=>{
                //Send Email to users
                const url ='https://docs.google.com/forms/d/e/1FAIpQLSeHgYgJvwxYjx381xnIA5xHbkQYOBRjTOIxDB0bcAmzhTow9Q/viewform?usp=sf_link';
                fetch("https://devclub360-email-client.herokuapp.com/api/email/send-email",{
                  method: "post",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    name: firstName.value,
                    email: currentUser.user.email,
                    subject: "Successful Devclub360 Membership Registration",
                    message: "<p>This message is to confirm your membership application into the DEVCLUB360 Network.</p><p>If we approve your membership application, you will receive another notification email before you gain full access to the DEVCLUB360 app and enjoy 30 days free trial accessing deals connection.</p><p>Now that you have submitted your membership application, we need to have your expression of interest as a final stage required for your approval.</p><p>Click on the link below to submit your expression of interest:</p><p>"+url+"</p><p>Thank You!<br />DEVCLUB360 Team.</p>",
                    adminEmail: "operations@devclub360.com",
                    adminSubject: "New Successful Member Registration",
                    adminMessage: "<p>A new member has registered with "+currentUser.user.email+" on the platform and awaiting approval.</p>"
                  })
                }).then(res=>res.json())
                .then(data=>{
                  console.log(data)
                })
                setDone(undefined)
                localStorage.removeItem("current")
                history.push("/members/profile-completed")
                
            }).catch((error)=>{
              setDone(undefined)
              toast.error("An error was encountered while saving your data, please refresh the page and try again", {
                  position: toast.POSITION.TOP_RIGHT
              });

        })
        }).catch((error)=>{
          setDone(undefined)
          toast.error("An error was encountered while saving your data, please refresh the page and try again", {
              position: toast.POSITION.TOP_RIGHT
          });
        })
    }).catch((error)=>{
      setDone(undefined)
      toast.error("An error was encountered while saving your data, please refresh the page and try again", {
          position: toast.POSITION.TOP_RIGHT
      });
    })
               
              }else{
                history.push("/")
              }
            }
          }, [url])
          const handleSubmit = () => {
              
              var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
              if(!pic){
                toast.error("Please choose a profile picture", {
                    position: toast.POSITION.TOP_RIGHT
                  });
              }else if(!allowedExtensions.exec(pic.name)){
                toast.error("Please choose a valid profile picture of .jpg, .jpeg, .png", {
                    position: toast.POSITION.TOP_RIGHT
                  });
              }else if(practiceArea.length == 0){
                toast.error("Please choose your Practice area", {
                    position: toast.POSITION.TOP_RIGHT
                  });
              }else if(practiceArea.length >4 ){
                toast.error("You can only choose maximum of four(4) Practice Area", {
                    position: toast.POSITION.TOP_RIGHT
                  });
              }else if(marketArea.length == 0){
                toast.error("Please choose your Market area", {
                    position: toast.POSITION.TOP_RIGHT
                  });
              }else if(marketArea.length >4 ){
                toast.error("You can only choose maximum of four(4) Market Area", {
                    position: toast.POSITION.TOP_RIGHT
                  });
              }
              else{
                setDone(true)

                const data = new FormData()
                data.append("file", pic)
                data.append("upload_preset", "devclub360")
                data.append("cloud_name", "dnnbqnnnl")

                fetch("https://api.cloudinary.com/v1_1/dnnbqnnnl/image/upload",{
                  method: "post",
                  body: data
                }).then(res=>res.json())
                  .then(data=>{
                    setUrl(data.secure_url);
                  })
                  .catch(err=>{
                    setDone(undefined)
                        toast.error("An error was encountered while saving your data, please refresh the page and try again", {
                          position: toast.POSITION.TOP_RIGHT
                        });
                  })

                
                }
            }
              


    return (
        <React.Fragment>
            <Grid item xs={12}>
              { 
                done?
                
                <Grid align= "center">
                    <ReactLoading type={"bars"} color={"green"} height={200} width={200} />
                </Grid>

                : <Grid>
                    <Paper elevation={10} style={paperStyle}>
                    <Grid align ="center">
                        <Avatar style= {avatarStyle}><FileUploadOutlinedIcon /></Avatar>
                        <h4><Typography>Upload Profile Pics and Niche</Typography></h4>
                    </Grid>
                    <TextField
                        variant={variant}
                        margin={margin}
                        fullWidth
                        name="profile"
                        type="file"
                        onChange={(e)=>setPics(e.target.files[0])}
                        required
                    />

                    <Grid>
                    <FormControl sx={{ m: 1, width: 450 }}>
                        <InputLabel id="-multiple-practice-label">Your Practice Area</InputLabel>
                        <Select
                        labelId="multiple-practice-label"
                        id="multiple-practice"
                        multiple
                        value={practiceArea}
                        onChange={handlePracticeChange}
                        input={<OutlinedInput label="Practice Area" />}
                        MenuProps={MenuProps}
                        >
                        {parArea.map((area) => (
                            <MenuItem
                            key={area}
                            value={area}
                            
                            >
                            {area}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid>
                    <FormControl sx={{ m: 1, width: 450 }}>
                        <InputLabel id="-multiple-market-label">Your Market Area</InputLabel>
                        <Select
                        labelId="multiple-market-label"
                        id="multiple-market"
                        multiple
                        value={marketArea}
                        onChange={handleMarketChange}
                        input={<OutlinedInput label="Market Area" />}
                        MenuProps={MenuProps}
                        >
                        {marArea.map((area) => (
                            <MenuItem
                            key={area}
                            value={area}
                            
                            >
                            {area}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button sx={{ mr: 1 }} onClick={handleBack}>
                        Previous
                        </Button>
                        <Button variant="contained" color="success" onClick={handleSubmit}>
                        Submit Profile
                        </Button>
                    </Box>

                    {/* <form ref={form} onSubmit={sendEmail}>
                      
                      <input type="hidden" name="to_name" value={firstName}  />
                      
                      <input type="hidden" name="to_email" value={currentUser.user.email} />
                      
                      <input type="hidden" name="message" value={message} />
                      <input type="hidden" name="message_subject" value={subject} />
                    </form>

                    <form ref={form} onSubmit={sendEmail}>
                      
                      <input type="hidden" name="to_name" value="Devclub360"  />
                      
                      <input type="hidden" name="to_email" value="operations@devclub360" />
                      
                      <input type="hidden" name="message" value={messageAdmin} />
                      <input type="hidden" name="message_subject" value={subjectAdmin} />
                    </form> */}
                </Paper>
                <ToastContainer />

                <CssBaseline />
                </Grid>
               }
            </Grid>
            
        </React.Fragment>
    )
}
