import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import {Avatar, Grid, Paper, Button, Typography} from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CssBaseline from '@mui/material/CssBaseline'
import { AppContext } from '../../../../registration/Context';

export default function ProjectConfirmation() {

    const { formValues, handleBack, handleNext } = useContext(AppContext)
    const { dealTitle, netOperatingCost, netOperatingIncome, overallROI, prefferedEquity,
        commonEquity, profitSharing, architecturalDesign, businessPlan, financialModel, projectCompletion,
        personalEquity, hotMoney, typeOfPartners, partnersOffers, noOfPartners } = formValues

        const handleSubmit = () => {
            // Remove unwanted properties from formValue object
            let form = {}
        
            Object.keys(formValues).map((name) => {
              form = {
                ...form,
                [name]: formValues[name].value
              }
              return form
            })
            // Do whatever with the values
            
            // Show last component or success message
            handleNext()
          }
    
          const paperStyle = {
            padding: 10,
            height: '200vh',
            width: 600,
            margin: "14px auto"
            }
            const avatarStyle = {
                backgroundColor: "#1bbd7e"
            }

    return (
        <React.Fragment>
                <Grid>
            <CssBaseline />

                <Paper elevation={10} style={paperStyle}>
                    <Grid align ="center">
                        <Avatar style= {avatarStyle}><CheckCircleOutlinedIcon /></Avatar>
                        <h4><Typography>Confirm Your Information we have so far</Typography></h4>
                    </Grid>
                    <List disablePadding>
                        <ListItem>
                        
                        <ListItemText
                            primary="Your Deal Title"
                            secondary={dealTitle.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Project Net Operating Cost"
                            secondary={'$'+netOperatingCost.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Your Project Net Operating Income"
                            secondary={'$'+netOperatingIncome.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Project Overall Return On Investment"
                            secondary={'$'+overallROI.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Preffered Equity"
                            secondary={prefferedEquity.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Common Equity"
                            secondary={commonEquity.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Project Profit Sharing Ratio"
                            secondary={profitSharing.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Is Architectural Design Ready?"
                            secondary={architecturalDesign.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Is Business Plan Ready?"
                            secondary={businessPlan.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Is Financial Model Ready?"
                            secondary={financialModel.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Project Completion Timeline"
                            secondary={projectCompletion.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Any Personal Equity"
                            secondary={personalEquity.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Any Hot Money?"
                            secondary={hotMoney.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="Type of Partners you are looking for"
                            secondary={typeOfPartners.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />
                        <ListItem>
                        <ListItemText
                            primary="What Partners should offer to this deal"
                            secondary={partnersOffers.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                        <ListItem>
                        <ListItemText
                            primary="Numbers of Partners needed for this project"
                            secondary={noOfPartners.value || 'Not Provided'}
                        />
                        </ListItem>

                        <Divider />

                    </List>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button sx={{ mr: 1 }} onClick={handleBack}>
                        Previous
                        </Button>
                        <Button variant="contained" color="success" onClick={handleSubmit}>
                        Confirm & Continue
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}
